import { useEffect, useState } from "react";
import Table from "../Table";
import DeleteIcon from "../../Assets/delete.svg";
import EditIcon from "../../Assets/edit.svg";
import AddIcon from "../../Assets/addIcon.svg";
import ExerciseImage from "../../Assets/exercise.svg";
import axios from "axios";
import { toast } from "react-toastify";
import ExerciseDrawer from "../ExerciseDrawer";
import { SmallLoader } from "../Loader";

const ExerciseTable = ({ currentOpen, coachId }) => {
	const [currentItem, setCurrentItem] = useState(null);
	const [tableData, setTableData] = useState([]);
	const [loading, setLoading] = useState(false);

	const fetchExerciseData = async () => {
		setLoading(true);
		await axios
			.post(
				process.env.REACT_APP_BACKEND_URL +
					"/api/exercise-database/getList/",
				{
					coachId: coachId,
				}
			)
			.then((response) => {
				setTableData(response.data);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	useEffect(() => {
		if (currentOpen === "exercise_database" && !tableData?.length) {
			fetchExerciseData();
		}
		// eslint-disable-next-line
	}, [currentOpen]);

	const deleteEntry = async (id) => {
		setLoading(true);
		if (window.confirm(`Are you sure to delete`)) {
			axios
				.post(
					process.env.REACT_APP_BACKEND_URL +
						`/api/exercise-database/delete/${id}`
				)
				.then((res) => {
					let _data = [...tableData];
					_data = _data.filter((item) => item.id !== id);
					setTableData(_data);
					toast("Record deleted.", {
						type: "success",
					});
					setLoading(false);
				})
				.catch((err) => {
					let _message = err.response.data.error.message;
					if (_message === "noBelonging") {
						toast("Record does not belong to you.", {
							type: "error",
						});
					} else {
						toast("Something went wrong while updating record.", {
							type: "error",
						});
					}
					setLoading(false);
				});
		}
	};
	return (
		<div className="relative bg-[#1e1e1e] rounded-xl py-8 w-full my-12 shadow-xl mb-8 xl:mb-0">
			<SmallLoader loading={loading} />
			<div className="w-full px-8 relative pt-12">
				<div className="flex items-center flex-col absolute top-0 -mt-12">
					<div className="h-12 w-12 rounded bg-[#d6b369] shadow flex items-center justify-center">
						<img
							alt="Log Book"
							src={ExerciseImage}
							className="w-8 h-8"
						/>
					</div>
					<p className="text-white text-lg font-bold mt-1">
						Exercises
					</p>
				</div>
				<button
					onClick={() => {
						setCurrentItem(true);
					}}
					className="float-right"
					type="button"
				>
					<img alt="Add" src={AddIcon} className="w-8" />
				</button>
				<Table
					headerColor="#1e1e1e"
					pageSize={5}
					columns={[
						{
							id: "Name",
							header: () => "Name",
							accessorFn: (row) => `${row.name}`,
							width: 90,
							cell: (info) => {
								return (
									<span className="whitespace-nowrap py-4 text-sm text-white text-right">
										{info.row.original.name}
									</span>
								);
							},
						},
						{
							id: "video",
							header: () => "Video",
							accessorFn: (row) => `${row.name}`,
							width: 90,
							cell: (info) => {
								return (
									<>
										{info.row.original.youtube_url ? (
											<a
												target="_blank"
												rel="noreferrer"
												href={
													info.row.original
														.youtube_url
												}
												className="whitespace-nowrap py-4 text-sm text-blue-500 text-right"
											>
												View
											</a>
										) : (
											<span className="whitespace-nowrap py-4 text-sm text-white text-right">
												N/A
											</span>
										)}
									</>
								);
							},
						},
						{
							id: "tags",
							header: () => "Tags",
							accessorFn: (row) => row.name,
							cell: (info) => {
								let tags = info.row.original.tags?.length
									? info.row.original.tags?.split(",")
									: [];
								return (
									<div className="flex">
										{tags.map((tag, tagIndex) => {
											return (
												<div
													key={tagIndex}
													className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300"
												>
													{tag}{" "}
												</div>
											);
										})}
									</div>
								);
							},
						},
						{
							id: "actions",
							header: () => "Action",
							cell: (info) => {
								return (
									<div className="w-[200px]">
										<div className="flex">
											<div className="ml-4 cursor-pointer">
												<img
													src={EditIcon}
													alt="Edit Icon"
													onClick={() => {
														setCurrentItem({
															...info.row
																.original,
														});
													}}
												/>
											</div>
											<div
												className="ml-4 cursor-pointer"
												onClick={() => {
													deleteEntry(
														info.row.original.id
													);
												}}
											>
												<img
													src={DeleteIcon}
													alt="Delete Icon"
												/>
											</div>
										</div>
									</div>
								);
							},
						},
					]}
					data={tableData}
				/>
				{currentItem && (
					<ExerciseDrawer
						tableData={tableData}
						currentItem={currentItem}
						setCurrentItem={setCurrentItem}
						setTableData={setTableData}
						coachId={coachId}
					/>
				)}
			</div>
		</div>
	);
};

export default ExerciseTable;
