import Close from "../../Assets/close.svg";
import { useEffect, useState, useRef } from "react";

const ReviewDrawer = ({ open, setOpen }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const wrapperRef = useRef(null);
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpenDrawer(true);
      }, 200);
    }
  }, [open]);

  const closeDrawer = () => {
    setOpenDrawer(false);
    setTimeout(() => {
      setOpen(false);
    }, 200);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        openDrawer
      ) {
        closeDrawer();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, openDrawer]);

  return (
    <div className={`fixed inset-0 z-20 bg-black bg-opacity-50`}>
      <div
        ref={wrapperRef}
        className={`w-full md:w-[400px] bg-[#1e1e1e] h-screen overflow-y-scroll right-0 absolute shadow px-4 py-12 ease-in-out transition ${
          openDrawer ? "" : "translate-x-full"
        } `}
      >
        <button
          onClick={() => {
            closeDrawer();
          }}
        >
          <img
            src={Close}
            alt="Close"
            className="w-8 absolute right-[10px] top-[20px]"
          />
        </button>
        <h1 className="text-center text-white font-Medium text-2xl">
          Review {open.name}
        </h1>
        <p className="text-center text-white mt-4 px-4 text-xs">
          {open.session_data.review}
        </p>
        <div className="container mx-auto mt-8 w-11/12 sm:w-full">
          <div className="w-11/12 p-[2px] mt-8 rounded bg-[#1e1e1e] mx-auto">
            {open?.session_data?.items.map((item, index) => {
              return (
                <div
                  className={`my-4 flex justify-center flex-col pb-4 ${
                    index !== open.session_data.items.length - 1 &&
                    "border-red-500 border-b border-b-2"
                  }`}
                  key={index}
                >
                  {item.exercises.length > 1 ? (
                    <div>
                      <h1 className="text-white text-2xl font-bold mb-4 text-center border-b-2 w-max mx-auto font-Kenyan tracking-wider">
                        {item.name}
                      </h1>
                      <div>
                        {item.exercises.map((exer, exIndex) => {
                          return (
                            <div className={exIndex !== 0 && "mt-3"}>
                              <p
                                className="text-white text-lg font-medium font-Kenyan tracking-wider"
                                key={exIndex}
                              >
                                {exer?.exercise?.name || exer?.exercise}
                              </p>
                              <div className="pt-2">
                                <pre className="text-white text-xs text-wrap">
                                  {exer.training_note}
                                </pre>
                              </div>
                              {exer.result && (
                                <div className="p-4 border mt-4 border-dashed">
                                  <h1 className="text-white text-lg font-semibold">
                                    Result
                                  </h1>
                                  <p className="text-white text-xs pt-1">
                                    {exer?.result}
                                  </p>
                                </div>
                              )}
                              {exIndex !== item.exercises?.length - 1 && (
                                <div className="h-[1px] bg-white w-[50%] mt-3" />
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <h1 className="text-white text-lg font-medium font-Kenyan tracking-wider">
                        {item?.exercises[0]?.exercise?.name ||
                          item?.exercises[0]?.exercise}
                      </h1>
                      <div className="pt-4">
                        <pre className="text-white text-xs pt-1 text-wrap">
                          {item.exercises[0].training_note}
                        </pre>
                      </div>
                      <div className="p-4 border mt-4 border-dashed">
                        <h1 className="text-white text-sm font-semibold">
                          Result
                        </h1>
                        <p className="text-white text-xs pt-1">
                          {item.exercises[0].result}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReviewDrawer;
