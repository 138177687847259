import EditIcon from "../../Assets/edit.svg";
import CountUp from "react-countup";
import Graph from "../Graph";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { Link } from "react-router-dom";
import DateRangerSelector from "../DateRangerSelector";
import { FaWeight } from "react-icons/fa";
import { IoFootsteps } from "react-icons/io5";
import { FaArrowsRotate } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa";
const OverViewDesktop = ({
	userData,
	calculation,
	setCalendar,
	calendar,
	displayHabit,
	activeGraph,
	setActiveGraph,
	role,
	params,
	openWeightModal,
	openStepsModal,
	openHabitModal,
}) => {
	return (
		<div className="hidden md:block bg-[#1e1e1e] rounded-xl py-8 xl:w-9/12 w-11/12 mx-auto shadow-xl">
			<div className="flex justify-between flex-col xl:flex-row px-4 xl:px-8">
				<div className="flex items-center">
					<h1 className="text-white text-base lg:text-2xl font-semibold mb-2 xl:mb-0">
						{userData.first_name.trim() + "'s"} Overview
					</h1>
					{role === "Coach" && (
						<Link to={`/member/edit/${params.id}`}>
							<div className="cursor-pointer ml-4">
								<img src={EditIcon} alt="Edit Icon" />
							</div>
						</Link>
					)}
				</div>
				<div className="rangePicker">
					<DateRangePicker onChange={setCalendar} value={calendar} />
				</div>
			</div>
			<div className="px-8 mt-8">
				<DateRangerSelector
					setCalendar={setCalendar}
					calendar={calendar}
					color={
						activeGraph === "habit"
							? "#587a57"
							: activeGraph === "steps"
							? "#8a7549"
							: "#9370db"
					}
				/>
			</div>
			<div className=" mt-4 xl:mt-12 h-[200px] flex justify-center items-center w-full xl:px-8 pr-8">
				<Graph calculation={calculation} activeGraph={activeGraph} />
			</div>
			{userData?.weight_tracking ||
			userData?.steps_tracking ||
			userData?.current_habit ? (
				<div className="flex justify-center">
					<button
						onClick={() => {
							if (activeGraph === "habit") {
								openHabitModal(true);
							} else if (activeGraph === "weight") {
								openWeightModal(true);
							} else {
								openStepsModal(true);
							}
						}}
						className={`text-white font-medium mx-auto rounded-full text-sm flex items-center py-1 px-2 uppercase ${
							activeGraph === "habit"
								? "bg-[#587a57]"
								: activeGraph === "steps"
								? "bg-[#8a7549]"
								: "bg-[#9370db]"
						}`}
					>
						<FaPlus className="mr-1" />{" "}
						{activeGraph === "habit"
							? displayHabit
							: activeGraph === "weight"
							? "Weight"
							: "Steps"}
					</button>
				</div>
			) : (
				<div />
			)}
			<div
				className={` ${
					userData.weight_tracking &&
					userData.steps_tracking &&
					userData.current_habit
						? " justify-between"
						: "justify-center gap-x-5"
				} flex items-start mt-4 px-8 mx-auto flex-wrap`}
			>
				{userData.weight_tracking && (
					<div className="w-[32%] bg-[#1e1e1e] rounded-xl flex items-center cursor-pointer">
						<div
							onClick={() => {
								setActiveGraph("weight");
							}}
							className={`flex justify-center md:block w-full w-[93%] border border-gray-500 ${
								activeGraph === "weight"
									? "bg-[#111111] shadow border-2 rounded !border-[#9370db]"
									: ""
							}`}
						>
							<div className="flex flex-col items-center p-2">
								<FaWeight className="text-white text-4xl 2xl:text-5xl mb-1" />
								{calculation.weight.average ? (
									<p className="text-xl text-white font-medium w-full text-center mt-4">
										<CountUp
											end={calculation.weight.average}
											decimals={1}
										/>
										<br />
										<span className=" text-gray-500 font-medium text-base">
											&nbsp;
											{calculation.weight.days} days
										</span>
									</p>
								) : (
									<>
										<p className="text-xl text-white font-medium w-full text-center mt-4">
											N/A
										</p>

										<span className="opacity-0 text-gray-500 font-medium mt-2 text-base">
											&nbsp; days
										</span>
									</>
								)}
							</div>
						</div>
					</div>
				)}
				{userData.steps_tracking && (
					<div className="w-[32%] bg-[#1e1e1e] rounded-xl flex items-center cursor-pointer justify-center">
						<div
							onClick={() => {
								setActiveGraph("steps");
							}}
							className={`flex justify-center md:block w-full w-[93%] border border-gray-500 ${
								activeGraph === "steps"
									? "bg-[#111111] shadow border-2 rounded !border-[#8a7549]"
									: ""
							}`}
						>
							<div className="flex flex-col items-center p-2">
								<IoFootsteps className="text-white text-4xl 2xl:text-5xl mb-1" />
								{calculation.steps.average ? (
									<p className="text-xl text-white font-medium w-full text-center  mt-4">
										<CountUp
											end={calculation.steps.average}
										/>
										<br />
										<span className="text-gray-500 font-medium text-base">
											&nbsp;
											{calculation.steps.days} days
										</span>
									</p>
								) : (
									<>
										<p className="text-xl text-white font-medium w-full text-center mt-4">
											N/A
										</p>

										<span className="text-base opacity-0 text-gray-500 font-medium mt-2">
											&nbsp; days
										</span>
									</>
								)}
							</div>
						</div>
					</div>
				)}
				{userData?.current_habit && (
					<div className="w-[32%] bg-[#1e1e1e] rounded-xl flex items-center cursor-pointer flex justify-end">
						<div
							onClick={() => {
								setActiveGraph("habit");
							}}
							className={`flex justify-center md:block w-full w-[93%] border border-gray-500 ${
								activeGraph === "habit"
									? "bg-[#111111] shadow border-2 rounded !border-[#587a57]"
									: ""
							}`}
						>
							<div className="flex flex-col items-center p-2">
								<FaArrowsRotate className="text-white text-4xl 2xl:text-5xl mb-1" />
								{calculation.habit.average ? (
									<p className="text-xl text-white font-medium w-full text-center  mt-4">
										<CountUp
											end={calculation.habit.average}
										/>
										%
										<br />
										<span className="text-gray-500 font-medium text-base">
											&nbsp;
											{calculation.habit.days} days
										</span>
									</p>
								) : (
									<>
										<p className="text-xl text-white font-medium w-full text-center mt-4">
											N/A
										</p>

										<span className="text-base opacity-0 text-gray-500 font-medium mt-2">
											&nbsp; days
										</span>
									</>
								)}
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
export default OverViewDesktop;
