import React, { useEffect, useState } from "react";
import ProtectedRoute from "../../utils/ProtectedRoute";
import Header from "../../Components/Header";
import CommentImage from "../../Assets/comment.svg";
import Camera from "../../Assets/media.svg";
import Dumbell from "../../Assets/dumbell.svg";
import NoTrack from "../../Assets/no-track.svg";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Loader from "../../Components/Loader";
import { updateCoachNofitications } from "../../redux/slices/coachNotifications";
import moment from "moment";
const CoachNotificationsComponent = ({ user, role }) => {
	const dispatch = useDispatch();
	const [loader, setLoader] = useState(false);
	const navigate = useNavigate();
	const coachNotifications = useSelector((state) => state.coachNotifications);
	const undreadNotifications = coachNotifications.filter(
		(item) => !item.read
	);
	const [notifications, setNotifications] = useState([]);

	useEffect(() => {
		let _data = [...coachNotifications];
		const sortedData = [
			..._data
				.filter((item) => !item.read)
				.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
			..._data
				.filter((item) => item.read)
				.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
		];
		setNotifications(sortedData);
	}, [coachNotifications]);

	const redirect = (notification) => {
		if (notification.type === "photo") {
			navigate(`/member/${notification.client.uuid}/media`);
		} else if (notification.type === "session") {
			navigate(
				`/coach/${notification.client.uuid}/sessions?name=${notification.client.first_name}`
			);
		} else {
			navigate(`/member/${notification.client.uuid}/`);
		}
	};

	const updateNotification = (notification, value, redirectToPage) => {
		setLoader(true);
		axios
			.post(
				process.env.REACT_APP_BACKEND_URL +
					`/api/coach-notifications/notification/update/${notification.id}`,
				{
					read: value === "read" ? new Date() : null,
				}
			)
			.then((response) => {
				let _data = [...coachNotifications];
				_data = _data.filter((item) => item.id !== notification.id);
				dispatch(updateCoachNofitications(_data));
				setLoader(false);
				if (redirectToPage) {
					redirect(notification);
				}
			})
			.catch((err) => {
				setLoader(false);
				if (redirectToPage) {
					redirect(notification);
				}
			});
	};
	const getNotificationDays = (notification) => {
		let date1 = moment(new Date());
		let date2 = moment(notification.createdAt);
		var diffInDays = date1.diff(date2, "days");

		if (diffInDays && diffInDays >= 1) {
			return diffInDays + " " + "day(s) ago";
		} else {
			const duration = moment.duration(
				moment().diff(notification.createdAt)
			);
			const hoursAgo = Math.floor(duration.asHours());
			const minutesAgo = Math.floor(duration.asMinutes());
			if (hoursAgo < 1) {
				return `${minutesAgo} minute(s) ago`;
			}
			return `${hoursAgo} hour(s) ago`;
		}
	};

	return (
		<div>
			<div className="min-h-screen bg-no-repeat bg-cover bg-[#111111]">
				<Header user={user} role={role} />
				<div className="container mx-auto">
					<div className="w-[90%] lg:w-full mx-auto pb-12 relative py-8">
						<h1 className="text-white text-3xl my-12">
							Notifications
						</h1>
						<div className="flex justify-between flex-col-reverse lg:flex-row">
							<div className="mt-12 max-h-[800px] overflow-y-auto w-full lg:w-7/12">
								{notifications?.length ? (
									notifications.map((notification, Index) => {
										return (
											<div
												key={Index}
												className={` ${
													!notification.read &&
													"border border-[#6f4bdb]"
												} mb-4  bg-[#1e1e1e] p-4 rounded shadow-xl border-opacity-50 cursor-pointer flex items-start`}
											>
												{notification.type ===
												"comment" ? (
													<img
														src={CommentImage}
														className="w-8"
														alt="Comment"
													/>
												) : notification.type ===
												  "photo" ? (
													<img
														src={Camera}
														className="w-8"
														alt="Camera"
													/>
												) : notification.type ===
												  "session" ? (
													<img
														src={Dumbell}
														className="w-8"
														alt="Camera"
													/>
												) : (
													<img
														src={NoTrack}
														className="w-8"
														alt="Track"
													/>
												)}

												<div className="w-full">
													<p className="text-xs ml-2 sm:text-base sm:ml-8 text-lg text-white italic">
														{notification.Content}
													</p>
													<div className="flex justify-end mt-2 md:mt-4 items-center">
														<p className="text-white text-xs mr-2">
															{getNotificationDays(
																notification
															)}
														</p>
														<button
															onClick={() => {
																updateNotification(
																	notification,
																	"read",
																	true
																);
															}}
															className={`mr-2 text-blue-800 bg-blue-300  text-[10px] font-semibold px-3 py-1 rounded-full w-max`}
														>
															View
														</button>
														<button
															onClick={() => {
																updateNotification(
																	notification,
																	notification.read
																		? "unread"
																		: "read",
																	false
																);
															}}
															className={`${
																!notification.read
																	? "text-green-800 bg-green-300"
																	: "text-red-800 bg-red-300"
															} text-[10px] font-semibold px-3 py-1 rounded-full w-max`}
														>
															{!notification.read
																? " Mark as read"
																: " Mark as unread"}
														</button>
													</div>
												</div>
											</div>
										);
									})
								) : (
									<div>
										{" "}
										<h1 className="text-white text-3xl my-12">
											No recent Notifications
										</h1>
									</div>
								)}
							</div>
							<div className="w-full lg:w-4/12 mx-auto">
								<p className="text-white font-semibold text-3xl text-center lg:text-left">
									UNREAD
								</p>

								<p className="text-white text-8xl mt-2 text-center lg:text-left">
									{undreadNotifications?.length}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Loader loading={loader} />
		</div>
	);
};

const CoachNotifications = () => {
	return (
		<ProtectedRoute>
			<CoachNotificationsComponent />
		</ProtectedRoute>
	);
};
export default CoachNotifications;
