import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import moment from "moment";
import { formattedDate, sortWithKey } from "../../utils";
import { toast } from "react-toastify";
import axios from "axios";
import { useParams } from "react-router-dom";
import { LoaderButton } from "../Loader";
const HabitsModal = ({
	currentItem,
	setCurrentItem,
	current_habit,
	tableData,
	setTableData,
}) => {
	const [habit, setHabit] = useState(currentItem?.habit || "");
	const [date, setDate] = useState(
		currentItem.date
			? formattedDate(currentItem.date)
			: moment().format("YYYY-MM-DD")
	);
	const [loader, setLoader] = useState(false);
	const params = useParams();
	const createOrUpdate = async () => {
		if (!habit) {
			toast("Select rank.", {
				type: "info",
			});
			return;
		}
		setLoader(true);
		if (currentItem?.id) {
			axios
				.put(
					process.env.REACT_APP_BACKEND_URL +
						`/api/habits/${currentItem.id}`,
					{
						clientId: params.id,
						current_habit: current_habit,
						habit,
						date,
					}
				)
				.then((res) => {
					let _data = [...tableData];
					_data[_data.findIndex((item) => item.id === res.data.id)] =
						res.data;
					setTableData(_data);
					setCurrentItem(null);
					setLoader(false);
					toast("Record updated.", {
						type: "success",
					});
				})
				.catch((err) => {
					let _message = err.response.data.error.message;
					if (_message === "noBelonging") {
						toast("Record does not belong to you.", {
							type: "error",
						});
					} else if (_message === "recordExist") {
						toast("Entry for this date already exist.", {
							type: "info",
						});
					} else {
						toast("Something went wrong while updating record.", {
							type: "error",
						});
					}
					setLoader(false);
				});
		} else {
			axios
				.post(process.env.REACT_APP_BACKEND_URL + `/api/habits/`, {
					current_habit: current_habit,
					habit,
					date,
					clientId: params.id,
				})
				.then((res) => {
					let _data = [...tableData];
					_data.unshift(res.data);
					setTableData(sortWithKey(_data, "date"));
					setLoader(false);
					setCurrentItem(null);
					toast("Record added successfully.", {
						type: "success",
					});
				})
				.catch((err) => {
					let _message = err.response.data.error.message;
					if (_message === "recordExist") {
						toast("Entry for this date already exist.", {
							type: "info",
						});
					} else {
						toast("Something went wrong while updating record.", {
							type: "error",
						});
					}
					setLoader(false);
				});
		}
	};

	const closeIcon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className="icon icon-tabler icon-tabler-x"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			strokeWidth="1.5"
			stroke="white"
			fill="none"
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<path stroke="none" d="M0 0h24v24H0z" fill="none" />
			<line x1="18" y1="6" x2="6" y2="18" />
			<line x1="6" y1="6" x2="18" y2="18" />
		</svg>
	);

	return (
		<div>
			<Modal
				closeIcon={closeIcon}
				open={true}
				onClose={() => {
					setCurrentItem(null);
				}}
				center
			>
				<h2 className="w-full text-center text-2xl text-gray-400">
					{currentItem?.id ? "Edit" : "Add"} Rank
				</h2>
				<form
					className="flex flex-col items-center"
					onSubmit={(e) => {
						e.preventDefault();
						createOrUpdate();
					}}
				>
					<div className="flex flex-col w-11/12 md:w-[68%]  mx-auto">
						<label
							htmlFor="habitDate"
							className="text-white text-xl"
						>
							Date
						</label>
						<input
							required
							id="habitDate"
							name="habitDate"
							className="mt-1 focus:outline-none resize-none bg-gray-800 rounded p-4 text-white shadow"
							type="date"
							onChange={(e) => {
								setDate(formattedDate(e.target.value));
							}}
							value={date}
						/>
					</div>
					<div className="flex flex-col w-11/12 md:w-[68%] mt-8 mx-auto">
						<label
							htmlFor="habitScale"
							className="text-white text-xl"
						>
							Rank (1-5)
						</label>
						<div className="flex items-center w-full justify-center md:justify-start mt-4">
							{Array.from({ length: 5 }).map((item, index) => {
								let active = habit >= index + 1;
								let _habit = Number(habit);
								let colors = !_habit
									? ""
									: _habit === 1
									? "bg-[#ff0000] border-[#ff0000]"
									: _habit === 2
									? "bg-[#ffa500] border-[#ffa500]"
									: _habit === 3
									? "bg-[#ab9900] border-[#ab9900]"
									: _habit === 4
									? "bg-[#568d00] border-[#568d00]"
									: "bg-[#008000] border-[#008000]";
								return (
									<div
										className="flex items-center"
										key={index}
									>
										<div
											role="button"
											onClick={() => {
												setHabit(index + 1);
											}}
											className={`${
												active && colors
											} text-white h-8 w-8 rounded-full border  flex items-center justify-center`}
										>
											{index + 1}
										</div>
										{index !== 4 && (
											<div
												className={`w-5 md:w-12 xl:w-20 h-px ${
													habit > index + 1
														? colors
														: "bg-white"
												}`}
											/>
										)}
									</div>
								);
							})}
						</div>
					</div>

					<div className="flex justify-center">
						<button
							type="submit"
							className={`${
								loader ? "px-0 py-2" : "px-7 py-3"
							} mx-auto mt-8 bg-button text-white font-medium text-sm leading-snug rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out
						`}
							data-mdb-ripple="true"
							data-mdb-ripple-color="light"
						>
							{loader ? (
								<LoaderButton md />
							) : currentItem.id ? (
								"Edit"
							) : (
								"Add"
							)}
						</button>
					</div>
				</form>
			</Modal>
		</div>
	);
};
export default HabitsModal;
