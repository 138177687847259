import { useState } from "react";
import { flexRender, getCoreRowModel, useReactTable, getFilteredRowModel, getPaginationRowModel, getSortedRowModel } from "@tanstack/react-table";
import { rankItem } from "@tanstack/match-sorter-utils";

const ReactTable = ({ tableBorder, headerColor, pageSize, columns, data, hideSearch }) => {
  const [sorting, setSorting] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const fuzzyFilter = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);

    addMeta({
      itemRank,
    });

    return itemRank.passed;
  };

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      sorting,
      globalFilter,
    },
    initialState: { pagination: { pageSize: pageSize ? pageSize : 10 } },
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    defaultColumn: {
      size: 200,
    },
  });

  return (
    <>
      {!hideSearch && (
        <div className="w-full md:w-1/4 mb-4">
          <input
            className="focus:outline-none bg-transparent border border-white rounded px-4 py-2 text-white"
            type="text"
            label=""
            name="search"
            value={globalFilter ?? ""}
            onChange={(e) => setGlobalFilter(String(e.target.value))}
            placeholder="Search"
          />
        </div>
      )}

      <div
        className={`overflow-x-scroll overflow-y-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg ${tableBorder ? "border border-[0.5px]" : ""} `}
      >
        <table style={{ width: table.getCenterTotalSize() }} className="min-w-full">
          <thead className="bg-gray-50 sticky top-0 z-10">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      style={{ width: header.getSize() }}
                      key={header.id}
                      className={` ${headerColor ? `bg-[${headerColor}]` : `bg-[#354352]`} text-white px-3 py-4 text-left text-sm font-semibold max-w-max`}
                    >
                      {header.isPlaceholder ? null : header.id === "actions" ? (
                        <span>Action</span>
                      ) : (
                        <span
                          {...{
                            className: header.column.getCanSort() ? "cursor-pointer" : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: " ↑",
                            desc: " ↓",
                          }[header.column.getIsSorted()] ?? " ↑↓"}
                        </span>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.getVisibleCells().map((cell, cellIndex) => (
                  <td key={cellIndex} className="px-3 py-4 border-b border-white border-opacity-10 bg-transparent text-sm max-w-max">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-end items-center mt-2">
        <div className="mr-4 text-sm">
          <span className="flex items-center gap-1 text-white">
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
            </strong>
          </span>
        </div>
        <div>
          <button type="button" className="text-white mr-2 text-2xl" size="xs" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
            {"<"}
          </button>
          <button type="button" className="text-white text-2xl" size="xs" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
            {">"}
          </button>
        </div>
      </div>
    </>
  );
};
export default ReactTable;
