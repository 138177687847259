import DownArrow from "../../Assets/arrow-down.svg";
import { Link } from "react-router-dom";
const Navigation = ({ links }) => {
  return (
    <div className="py-1 flex items-center">
      {links.map((link, linkIndex) => {
        return (
          <div key={linkIndex} className={`flex ${linkIndex !== 0 && "ml-1 items-center"}`}>
            <Link to={link.url}>
              {linkIndex === links.length - 1 ? (
                <u className="text-white text-xs font-medium">{link.name}</u>
              ) : (
                <p className="text-gray-400 text-xs font-medium">{link.name}</p>
              )}
            </Link>
            {linkIndex !== links.length - 1 ? (
              <img
                src={DownArrow}
                className="transform -rotate-90 w-2 ml-1"
                alt="Left Arrow"
              />
            ) : (
              <div />
            )}
          </div>
        );
      })}
    </div>
  );
};
export default Navigation;
