import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import Table from "../../Components/Table";
import Loader from "../../Components/Loader";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import HistoryIcon from "../../Assets/history-color.svg";
import ProtectedRoute from "../../utils/ProtectedRoute";
import { formattedDate } from "../../utils";
import Dumbell from "../../Assets/dumbell-black.svg";
import HistoryDrawer from "../../Components/HistoryDrawer";
import Navigation from "../../Components/Navigation";

const HistoryDetail = ({ user, role }) => {
  const [historyItems, setHistoryItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentItem, setCurrentItem] = useState(null);
  const params = useParams();
  const router = useLocation();

  const fetchHistoryDetail = async () => {
    axios
      .get(
        process.env.REACT_APP_BACKEND_URL +
          `/api/client-history/${params.id}/getSingleExerciseDetail/${params.exerciseId}`
      )
      .then((res) => {
        setHistoryItems(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast("Unable to fetch history detail", {
          type: "error",
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchHistoryDetail();
    // eslint-disable-next-line
  }, [user.id]);
  let _name = router?.search?.split("name=")[1];
  return (
    <div className="min-h-screen bg-no-repeat bg-cover bg-[#111111]">
      <Header user={user} role={role} />
      <div className="container mx-auto pb-12 w-11/12 mx-auto lg:W-full">
        <Navigation
          links={[
            {
              name: "Clients",
              url: "/coach/dashboard",
            },
            {
              name: _name,
              url: `/member/${params.id}`,
            },
            {
              name: "History Detail",
              url: `/member/${params.id}/history${params.exerciseId}`,
            },
          ]}
        />
        <div className="bg-[#1e1e1e] rounded-xl py-8 relative mt-12">
          <div className="w-full px-8 relative pt-12">
            <div className="flex items-center flex-col absolute top-0 -mt-12">
              <div className="h-12 w-12 rounded bg-[#d6b369] shadow flex items-center justify-center">
                <img alt="Client Icon" src={HistoryIcon} className="w-8 h-8" />
              </div>
              <p className="text-white text-lg font-bold mt-1 uppercase">
                {historyItems[0]?.exercise_database?.name || "History"}
              </p>
            </div>

            <Table
              headerColor="#1e1e1e"
              columns={[
                {
                  id: "exercise_database",
                  header: () => "Exercise",
                  accessorFn: (row) => `${row.exercise_database.name}`,
                  cell: (info) => {
                    return (
                      <span className="whitespace-nowrap py-4 text-sm text-white text-right">
                        {info.row.original.exercise_database.name}
                      </span>
                    );
                  },
                },
                {
                  id: "result",
                  header: () => "Result",
                  accessorFn: (row) => `${row.result}`,
                  cell: (info) => {
                    return (
                      <span className="whitespace-nowrap py-4 text-sm text-white text-right">
                        {info.row.original.result}
                      </span>
                    );
                  },
                },
                {
                  id: "createdAt",
                  header: () => "Tracked on",
                  accessorFn: (row) => `${formattedDate(row.createdAt, true)}`,
                  cell: (info) => {
                    return (
                      <span className="whitespace-nowrap py-4 text-sm text-white text-right">
                        {formattedDate(info.row.original.createdAt, true)}
                      </span>
                    );
                  },
                },
                {
                  id: "session",
                  header: () => "Session",
                  cell: (info) => {
                    return (
                      <div className="flex">
                        <button
                          onClick={() => {
                            setCurrentItem(info.row.original);
                          }}
                          className="cursor-pointer text-white"
                        >
                          <img src={Dumbell} alt="Open Icon" />
                        </button>
                      </div>
                    );
                  },
                },
              ]}
              data={historyItems}
            />
          </div>
        </div>
      </div>
      {currentItem && (
        <HistoryDrawer open={currentItem} setOpen={setCurrentItem} />
      )}
      <Loader loading={loading} />
    </div>
  );
};

const HistoryDetailPage = () => {
  return (
    <ProtectedRoute>
      <HistoryDetail />
    </ProtectedRoute>
  );
};
export default HistoryDetailPage;
