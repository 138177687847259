import Close from "../../Assets/close.svg";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { LoaderButton } from "../Loader";
import { useNavigate, useParams } from "react-router-dom";
const TrainingSessionDrawer = ({
	sessionData,
	currentItem,
	setCurrentItem,
	setSessionData,
	clientId,
	coachId,
}) => {
	const [loading, setLoading] = useState(false);
	const [openDrawer, setOpenDrawer] = useState(false);
	const params = useParams();
	const navigate = useNavigate();
	const wrapperRef = useRef(null);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		defaultValues: {
			name: currentItem?.name || "",
			text_note: currentItem?.text_note || "",
		},
	});

	useEffect(() => {
		if (currentItem) {
			setTimeout(() => {
				setOpenDrawer(true);
			}, 200);
		}
	}, [currentItem]);

	const closeDrawer = () => {
		setOpenDrawer(false);
		setTimeout(() => {
			setCurrentItem(null);
		}, 200);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				wrapperRef.current &&
				!wrapperRef.current.contains(event.target) &&
				openDrawer
			) {
				closeDrawer();
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [wrapperRef, openDrawer]);

	const createOrUpdateTrainingSession = (values) => {
		setLoading(true);
		if (currentItem?.id) {
			axios
				.put(
					process.env.REACT_APP_BACKEND_URL +
						`/api/training-sessions/${currentItem.id}`,
					{
						...values,
						clientId: params.id,
					}
				)
				.then((res) => {
					let _data = { ...sessionData };
					const { id, status } = res.data;

					switch (status) {
						case "draft":
							_data.draft = _data.draft.map((item) =>
								item.id === id ? res.data : item
							);
							break;
						case "live":
							_data.live = _data.live.map((item) =>
								item.id === id ? res.data : item
							);
							break;
						default:
							_data.completed = _data.completed.map((item) =>
								item.id === id ? res.data : item
							);
					}
					setSessionData(_data);

					setCurrentItem(null);
					toast("Record updated.", {
						type: "success",
					});
				})
				.catch((err) => {
					let _message = err.response.data.error.message;
					if (_message === "noBelonging") {
						toast("Record does not belong to you.", {
							type: "error",
						});
					} else {
						toast("Something went wrong while updating record.", {
							type: "error",
						});
					}
					setLoading(false);
				});
		} else {
			axios
				.post(
					process.env.REACT_APP_BACKEND_URL +
						`/api/training-sessions`,
					{
						...values,
						session_data: {
							items: [],
						},
						clientId: clientId,
						coachId: coachId,
					}
				)
				.then((res) => {
					toast("Record added successfully.", {
						type: "success",
					});
					navigate(`/coach/${params.id}/sessions/${res.data.id}`);
				})
				.catch((err) => {
					toast("Something went wrong while adding record.", {
						type: "error",
					});
					setLoading(false);
				});
		}
	};
	return (
		<div className={`fixed inset-0 z-20 bg-black bg-opacity-50`}>
			<div
				ref={wrapperRef}
				className={`w-full md:w-[400px] bg-[#1e1e1e] h-screen overflow-y-scroll right-0 absolute shadow px-4 py-12 ease-in-out transition ${
					openDrawer ? "" : "translate-x-full"
				} `}
			>
				<button
					onClick={() => {
						closeDrawer();
					}}
				>
					<img
						src={Close}
						alt="Close"
						className="w-8 absolute right-[10px] top-[20px]"
					/>
				</button>
				<h1 className="text-center text-white font-Medium text-2xl">
					{currentItem?.id ? "Edit" : "Add"} Training Session
				</h1>
				<form
					className="mt-8"
					onSubmit={handleSubmit(createOrUpdateTrainingSession)}
				>
					<div className="mb-6 w-full">
						<label className="text-white font-medium">Name</label>
						<input
							{...register("name", {
								required: "Required.",
							})}
							type="text"
							className="placeholder-gray-400 mt-2 form-control block w-full px-4 py-2 text-xl font-normal bg-transparent border border-white border-opacity-20 rounded transition ease-in-out m-0 focus:outline-none text-gray-400 shadow"
						/>
						{errors.name && (
							<p className="mt-1 text-xs text-red-500">
								{errors.name.message}
							</p>
						)}
					</div>
					<div className="mb-6 w-full">
						<label className="text-white font-medium">
							Text Note (if any)
						</label>
						<textarea
							{...register("text_note", {})}
							type="text"
							className="placeholder-gray-400 mt-2 form-control block w-full px-4 py-2 text-xl font-normal bg-transparent border border-white border-opacity-20 rounded transition ease-in-out m-0 focus:outline-none text-gray-400 shadow"
						/>
						{errors.text_note && (
							<p className="mt-1 text-xs text-red-500">
								{errors.text_note.message}
							</p>
						)}
					</div>
					<button
						type="submit"
						className="inline-block px-7 py-2 bg-button text-white font-medium text-sm leading-snug rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out mx-auto"
						data-mdb-ripple="true"
						data-mdb-ripple-color="light"
					>
						{loading ? (
							<LoaderButton />
						) : currentItem?.id ? (
							"Edit"
						) : (
							"Create"
						)}
					</button>
				</form>
			</div>
		</div>
	);
};
export default TrainingSessionDrawer;
