import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Loader from "../Loader";
import { habits } from "../../data";
import { formatEmail, restructureHabit, sortWithKey } from "../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import HabitLogTable from "../HabitLogTable";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import FullEditor from "ckeditor5-build-full";
import LinksTable from "../LinksTable";

const ClientForm = ({ create, user }) => {
	const params = useParams();
	const navigate = useNavigate();
	const [currentUser, setCurrentUser] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");
	const [notes, setNotes] = useState("");
	const [clientLinks, setClientLinks] = useState([]);
	const {
		register,
		handleSubmit,
		reset,
		watch,
		setValue,
		formState: { errors, isSubmitting },
	} = useForm({
		defaultValues: {
			first_name: "",
			last_name: "",
			current_habit: "",
			customHabit: "",
			email: "",
			steps_tracking: false,
			weight_tracking: false,
		},
	});

	const fetchClient = async () => {
		await axios
			.get(
				process.env.REACT_APP_BACKEND_URL +
					"/api/client/getClientProfileData/" +
					params.id
			)
			.then((response) => {
				let _user = response.data.client;
				setCurrentUser(_user);
				setClientLinks(
					sortWithKey(response.data.client.links, "createdAt")
				);
				reset({
					first_name: _user?.first_name || "",
					last_name: _user?.last_name || "",
					steps_tracking: _user?.steps_tracking || false,
					weight_tracking: _user?.weight_tracking || false,
				});
				if (_user.current_habit) {
					if (
						!habits.find(
							(item) => item.value === _user.current_habit
						)
					) {
						setValue("current_habit", "other");
						setValue(
							"customHabit",
							restructureHabit("", _user.current_habit)
						);
					} else {
						setValue("current_habit", _user?.current_habit);
						setValue("customHabit", "");
					}
				}
				setLoading(false);
			})
			.catch((err) => {
				setError("No User Found.");
				setLoading(false);
			});
	};

	useEffect(() => {
		if (params?.id) {
			fetchClient();
		} else {
			setLoading(false);
		}
		// eslint-disable-next-line
	}, [params, reset]);

	const createOrUpdate = async (values) => {
		setLoading(true);
		let _values = { ...values };
		if (_values.current_habit === "other") {
			_values.current_habit = restructureHabit(
				"save",
				_values.customHabit
			);
		}
		delete _values.customHabit;
		if (currentUser) {
			axios
				.put(
					process.env.REACT_APP_BACKEND_URL +
						`/api/clients/${params.id}`,
					{
						..._values,
						notes,
					}
				)
				.then((response) => {
					toast("Client Updated.", {
						type: "success",
					});
					setLoading(false);
				})
				.catch((err) => {
					toast("Unable to update client.", {
						type: "error",
					});
					setLoading(false);
				});
		}
		// Create client
		else if (create) {
			axios
				.post(
					process.env.REACT_APP_BACKEND_URL +
						`/api/client/createClient`,
					{
						..._values,
						email: formatEmail(_values.email),
						notes,
						coachId: user.id,
					}
				)
				.then((response) => {
					toast("Client Created.", {
						type: "success",
					});
					navigate("/");
				})
				.catch((err) => {
					setLoading(false);
					if (err.response.data.error.message === "already_exist") {
						toast("User already exist with this email", {
							type: "error",
						});
					} else {
						toast("Unable to create client.", {
							type: "error",
						});
					}
				});
		}
	};
	const disableClient = (val) => {
		setLoading(true);
		axios
			.post(
				process.env.REACT_APP_BACKEND_URL +
					"/api/client/updateClientAccess/" +
					params.id,
				{
					blocked: val,
				}
			)
			.then((res) => {
				toast("Client updated.", {
					type: "success",
				});
				navigate("/coach/dashboard");
			})
			.catch((err) => {
				let _message = err.response.data.error.message;
				if (_message === "noBelonging") {
					toast("Client does not belong to you.", {
						type: "error",
					});
				} else {
					toast("Something went wrong while disabling user.", {
						type: "error",
					});
				}
				setLoading(false);
			});
	};
	return (
		<>
			{error ? (
				<div className="h-screen flex items-center justify-center">
					<h1 className="text-center text-white text-5xl">{error}</h1>
				</div>
			) : (
				<>
					<form
						onSubmit={handleSubmit(createOrUpdate)}
						className="flex justify-between w-full flex-wrap"
					>
						<div className="mb-6 w-full md:w-[48%]">
							<label className="text-white font-medium">
								First Name
							</label>
							<input
								{...register("first_name", {
									required: "Required.",
								})}
								type="text"
								defaultValue={currentUser?.first_name || ""}
								className="placeholder-gray-400 mt-2 form-control block w-full px-4 py-2 text-xl font-normal bg-transparent border border-white border-opacity-20 rounded transition ease-in-out m-0 focus:outline-none text-gray-400 shadow"
							/>
							{errors.first_name && (
								<p className="mt-1 text-xs text-red-500">
									{errors.first_name.message}
								</p>
							)}
						</div>

						<div className="mb-6 w-full md:w-[48%]">
							<label className="text-white font-medium">
								Last Name
							</label>
							<input
								{...register("last_name", {
									required: "Required.",
								})}
								type="text"
								className="placeholder-gray-400 mt-2 form-control block w-full px-4 py-2 text-xl font-normal bg-transparent border border-white border-opacity-20 rounded transition ease-in-out m-0 focus:outline-none text-gray-400 shadow"
							/>
							{errors.last_name && (
								<p className="mt-1 text-xs text-red-500">
									{errors.last_name.message}
								</p>
							)}
						</div>
						{create && (
							<div className="mb-6 w-full md:w-[48%]">
								<label className="text-white font-medium">
									Email
								</label>
								<input
									{...register("email", {
										required: "Required.",
									})}
									type="email"
									className="placeholder-gray-400 mt-2 form-control block w-full px-4 py-2 text-xl font-normal bg-transparent border border-white border-opacity-20 rounded transition ease-in-out m-0 focus:outline-none text-gray-400 shadow"
								/>
								{errors.email && (
									<p className="mt-1 text-xs text-red-500">
										{errors.email.message}
									</p>
								)}
							</div>
						)}

						<div className="mb-6 w-full md:w-[48%]">
							<label className="text-white font-medium">
								Habit
							</label>
							<select
								{...register("current_habit")}
								className="placeholder-gray-400 mt-2 form-control block w-full px-4 py-2 text-xl font-normal bg-transparent border border-white border-opacity-20 rounded transition ease-in-out m-0 focus:outline-none text-gray-400 shadow"
							>
								<option selected value=""></option>
								{habits.map((habit, habitIndex) => {
									return (
										<option
											key={habitIndex}
											value={habit.value}
										>
											{habit.title}
										</option>
									);
								})}
								<option value="other">Other</option>
							</select>
							{watch("current_habit") === "other" && (
								<input
									{...register("customHabit", {
										required: "Required.",
									})}
									type="text"
									className="placeholder-gray-400 mt-2 form-control block w-full px-4 py-2 text-xl font-normal bg-transparent border border-white border-opacity-20 rounded transition ease-in-out m-0 focus:outline-none text-gray-400 shadow"
									placeholder="Custom habit"
								/>
							)}

							{errors.customHabit && (
								<p className="mt-1 text-xs text-red-500">
									{errors.customHabit.message}
								</p>
							)}
						</div>
						<div className="mb-6 w-full md:w-[48%]"></div>
						<div className="mb-6 w-full md:w-[48%]">
							<label className="text-white font-medium">
								Weight Tracking
							</label>
							<input
								{...register("weight_tracking", {})}
								type="checkbox"
								className="placeholder-gray-400 mt-2 form-control block  px-4 py-2 text-xl font-normal bg-transparent border border-white border-opacity-20 rounded transition ease-in-out m-0 focus:outline-none text-gray-400 shadow"
							/>
							{errors.weight_tracking && (
								<p className="mt-1 text-xs text-red-500">
									{errors.weight_tracking.message}
								</p>
							)}
						</div>
						<div className="mb-6 w-full md:w-[48%]">
							<label className="text-white font-medium">
								Steps Tracking
							</label>
							<input
								{...register("steps_tracking", {})}
								type="checkbox"
								className="placeholder-gray-400 mt-2 form-control block  px-4 py-2 text-xl font-normal bg-transparent border border-white border-opacity-20 rounded transition ease-in-out m-0 focus:outline-none text-gray-400 shadow"
							/>
							{errors.steps_tracking && (
								<p className="mt-1 text-xs text-red-500">
									{errors.steps_tracking.message}
								</p>
							)}
						</div>
						<div className="w-full">
							<label className="text-white font-medium">
								Client Notes
							</label>
							<div className="bg-[#1e1e1e] rounded-xl w-full mt-2 shadow-xl mb-12">
								<CKEditor
									className="h-full"
									editor={FullEditor}
									data={currentUser?.notes || ""}
									onChange={(event, editor) => {
										setNotes(editor.getData());
									}}
								/>
							</div>
						</div>

						<div className="w-full flex justify-center">
							<button
								type="submit"
								className="inline-block px-7 py-3 bg-button text-white font-medium text-sm leading-snug rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out w-[200px] mx-auto"
								data-mdb-ripple="true"
								data-mdb-ripple-color="light"
							>
								Submit
							</button>
						</div>
					</form>
					{!create && (
						<>
							{currentUser?.user?.blocked ? (
								<button
									onClick={() => {
										disableClient(false);
									}}
									className="text-green-800 absolute right-0 top-0 bg-green-300 px-3 py-1 rounded-full"
								>
									Unblock
								</button>
							) : (
								<button
									onClick={() => {
										disableClient(true);
									}}
									className="text-red-800 absolute right-0 top-0 bg-red-300 px-3 py-1 rounded-full"
								>
									Block
								</button>
							)}
						</>
					)}
					<div className="w-full bg-white my-12 h-px" />{" "}
					<div className="flex justify-between flex-wrap">
						{!create && currentUser?.current_habit && (
							<div className="bg-[#1e1e1e] rounded-xl py-8 w-full xl:w-[49%] shadow-xl mb-8 xl:mb-0">
								<HabitLogTable
									tableData={currentUser?.habit_notes || []}
									setFetchLoader={setLoading}
									fetchData={fetchClient}
									setTableData={setCurrentUser}
								/>
							</div>
						)}
						{!create && (
							<div className="bg-[#1e1e1e] rounded-xl py-8 w-full xl:w-[49%] shadow-xl mb-8 xl:mb-0">
								<LinksTable
									tableData={clientLinks || []}
									setFetchLoader={setLoading}
									fetchData={fetchClient}
									clientId={params.id}
								/>
							</div>
						)}
					</div>
				</>
			)}

			<Loader loading={loading || isSubmitting} />
		</>
	);
};
export default ClientForm;
