import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import "react-responsive-modal/styles.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-image-gallery/styles/css/image-gallery.css";
import "react-tooltip/dist/react-tooltip.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { requestInterceptor, responseInterceptor } from "./intercepter";
import InstallationPrompt from "./Components/installationPrompt";
const root = ReactDOM.createRoot(document.getElementById("root"));
requestInterceptor();
responseInterceptor();
root.render(
	<Provider store={store}>
		<InstallationPrompt />
		<ToastContainer position="top-center" />
		<App />
	</Provider>
);
serviceWorkerRegistration.register();

reportWebVitals();
