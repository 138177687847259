import { useEffect, useRef } from "react";
import DownloadApp from "../../Assets/download-app.svg";
const InstallationPrompt = () => {
  const divRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (event) => {
      // Prevent the mini-infobar from appearing on mobile.
      event.preventDefault();
      // console.log("👍", "beforeinstallprompt", event);
      // Stash the event so it can be triggered later.
      window.deferredPrompt = event;
      // Remove the 'hidden' class from the install button container.

      divRef.current.classList.toggle("hidden", false);
    });
    //   ---
    buttonRef.current.addEventListener("click", async () => {
      // console.log("👍", "butInstall-clicked");
      const promptEvent = window.deferredPrompt;
      if (!promptEvent) {
        // The deferred prompt isn't available.
        return;
      }
      // Show the install prompt.
      promptEvent.prompt();
      // Log the result
      // const result = await promptEvent.userChoice;
      // console.log("👍", "userChoice", result);
      // Reset the deferred prompt variable, since
      // prompt() can only be called once.
      window.deferredPrompt = null;
      // Hide the install button.
      divRef.current.classList.toggle("hidden", true);
    });
    window.addEventListener("appinstalled", (event) => {
      // console.log("👍", "appinstalled", event);
      // Clear the deferredPrompt so it can be garbage collected
      window.deferredPrompt = null;
    });
  }, []);

  return (
    <div
      className="hidden w-full shadow flex-col items-center flex md:flex-row justify-center py-3 items-center bg-[#ff4655]"
      ref={divRef}
    >
      <p className="text-white font-medium text-center md:text-left mb-4 md:mb-0">
        Get the app and enjoy the Fitness Dashboard's enhanced features!
      </p>
      <button
        ref={buttonRef}
        className="border border-white rounded px-2 py-1 text-white flex items-center ml-4 justify-center"
      >
        Install Now{" "}
        <img src={DownloadApp} alt="download app" className="ml-2" />
      </button>
    </div>
  );
};
export default InstallationPrompt;
