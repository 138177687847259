import Close from "../../Assets/close.svg";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { LoaderButton } from "../Loader";
const CopySessionDrawer = ({
  open,
  setOpen,
  clientList,
  copyTrainingSessions,
  loading,
}) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      client: "",
    },
  });

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpenDrawer(true);
      }, 200);
    }
  }, [open]);

  const closeDrawer = () => {
    setOpenDrawer(false);
    setTimeout(() => {
      setOpen(false);
    }, 200);
  };

  return (
    <div className={`fixed inset-0 z-20 bg-black bg-opacity-50`}>
      <div
        className={`w-full md:w-[400px] bg-[#1e1e1e] h-screen overflow-y-scroll right-0 absolute shadow px-4 py-12 ease-in-out transition ${
          openDrawer ? "" : "translate-x-full"
        } `}
      >
        <button
          onClick={() => {
            closeDrawer();
          }}
        >
          <img
            src={Close}
            alt="Close"
            className="w-8 absolute right-[10px] top-[20px]"
          />
        </button>
        <h1 className="text-center text-white font-Medium text-2xl">
          Copy sessions
        </h1>
        <form className="mt-8" onSubmit={handleSubmit(copyTrainingSessions)}>
          <div className="mb-6 w-full">
            <label className="text-white font-medium">Select Client</label>
            <select
              {...register("client", {
                required: "Required.",
              })}
              className="placeholder-gray-400 mt-2 form-control block w-full px-4 py-2 text-xl font-normal bg-transparent border border-white border-opacity-20 rounded transition ease-in-out m-0 focus:outline-none text-gray-400 shadow"
            >
              <option selected value=""></option>
              {clientList
                .sort((a, b) => a.first_name.localeCompare(b.first_name))
                .map((client, clientIndex) => {
                  return (
                    <option key={clientIndex} value={client.id}>
                      {client.first_name + " " + client.last_name}
                    </option>
                  );
                })}
            </select>
            {errors.client && (
              <p className="mt-1 text-xs text-red-500">
                {errors.client.message}
              </p>
            )}
          </div>
          <button
            type="submit"
            className="inline-block px-7 py-2 bg-button text-white font-medium text-sm leading-snug rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out mx-auto"
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
          >
            {loading ? <LoaderButton /> : "Copy"}
          </button>
        </form>
      </div>
    </div>
  );
};
export default CopySessionDrawer;
