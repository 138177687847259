import React from "react";
import { Modal } from "react-responsive-modal";
import ReactPlayer from "react-player";

const VideoModal = ({ currentItem, setCurrentItem }) => {
  const closeIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-x"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="white"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <line x1="18" y1="6" x2="6" y2="18" />
      <line x1="6" y1="6" x2="18" y2="18" />
    </svg>
  );

  return (
    <div>
      <Modal
        closeIcon={closeIcon}
        open={currentItem}
        onClose={() => {
          setCurrentItem(null);
        }}
        center
      >
        <ReactPlayer controls={true} url={currentItem} className="w-full" width="100%" />
      </Modal>
    </div>
  );
};
export default VideoModal;
