import Logo from "../../Assets/whiteLogo.png";
import { Link } from "react-router-dom";
import { logout } from "../../utils";
import NotificationImage from "../../Assets/no-notification.svg";
import ActiveNotification from "../../Assets/active-notification.svg";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { updateCoachNofitications } from "../../redux/slices/coachNotifications";
const Header = ({ user, role }) => {
	const coachNotifications = useSelector((state) => state.coachNotifications);
	const dispatch = useDispatch();
	useEffect(() => {
		if (role === "Coach") {
			axios
				.post(
					process.env.REACT_APP_BACKEND_URL +
						"/api/coach-notifications/getNotifications",
					{
						coachId: user.id,
					}
				)
				.then((response) => {
					dispatch(updateCoachNofitications(response.data));
				})
				.catch((err) => {
					toast("Unable to fetch notifications", {
						type: "error",
					});
				});
		}
		// eslint-disable-next-line
	}, []);

	return (
		<div className={`${role !== "Coach" && "lg:block hidden"} `}>
			<nav className="border-gray-200 px-2 sm:px-4 py-2.5 rounded">
				<div className="container flex flex-wrap items-center justify-between mx-auto">
					<Link to="/">
						<img alt="Logo" src={Logo} className="h-12 mr-3" />
					</Link>
					{user && (
						<div className="flex items-center md:order-2">
							{role === "Coach" ? (
								<Link to={`/coach/profile/`}>
									<span className="text-white font-xs">
										{user?.first_name +
											" " +
											user?.last_name}{" "}
									</span>
								</Link>
							) : (
								<span className="text-white font-xs">
									{user?.first_name + " " + user?.last_name}{" "}
								</span>
							)}
							<button
								onClick={() => {
									logout();
								}}
								type="button"
								className="ml-3 cursor-pointer text-gray-700 text-white"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="20px"
									height="20px"
									viewBox="0 0 24 24"
									fill="none"
								>
									<path
										d="M15 16.5V19C15 20.1046 14.1046 21 13 21H6C4.89543 21 4 20.1046 4 19V5C4 3.89543 4.89543 3 6 3H13C14.1046 3 15 3.89543 15 5V8.0625M11 12H21M21 12L18.5 9.5M21 12L18.5 14.5"
										stroke="currentColor"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</button>
							{role === "Coach" && (
								<Link to={`/coach/notifications/`}>
									<button className="ml-2">
										{coachNotifications.length &&
										coachNotifications.find(
											(item) => !item.read
										) ? (
											<img
												src={ActiveNotification}
												alt="notification"
												className="mt-1"
											/>
										) : (
											<img
												src={NotificationImage}
												alt="notification"
												className="mt-1"
											/>
										)}
									</button>
								</Link>
							)}
						</div>
					)}
				</div>
			</nav>
		</div>
	);
};
export default Header;
