import React from "react";
import ProtectedRoute from "../../utils/ProtectedRoute";
import Header from "../../Components/Header";
import ClientForm from "../../Components/ClientForm";

const AddClientComponent = ({ user, role }) => {
	return (
		<div>
			<div className="min-h-screen bg-no-repeat bg-cover bg-[#111111]">
				<Header user={user} />
				<div className="container mx-auto">
					<div className="w-[90%] lg:w-full mx-auto pb-12 relative">
						<h1 className="text-white text-3xl my-12 ">
							Add Client
						</h1>
						<ClientForm user={user} create={true} role={role} />
					</div>
				</div>
			</div>
		</div>
	);
};

const AddClient = () => {
	return (
		<ProtectedRoute>
			<AddClientComponent />
		</ProtectedRoute>
	);
};
export default AddClient;
