import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Loader from "../Loader";
import { toast } from "react-toastify";
import { sortWithKey } from "../../utils";
import LinksTable from "../LinksTable";
import { Collapse } from "react-collapse";
import ArrowDown from "../../Assets/arrow-down.svg";
import ExerciseTable from "../ExerciseTable";
const CoachForm = ({ user }) => {
	const [currentOpen, setCurrentOpen] = useState("");
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors, isSubmitting },
	} = useForm({
		defaultValues: {
			first_name: "",
			last_name: "",
			weight_notification_days: 0,
			weight_notification_enabled: false,
			step_notification_days: 0,
			step_notification_enabled: false,
			habit_notification_days: 0,
			habit_notification_enabled: false,
			photos_notification_days: 0,
			photos_notification_enabled: false,
			primary_link_text: "",
			primary_link_value: "",
		},
	});

	const [loading, setLoading] = useState(true);
	const [currentUser, setCurrentUser] = useState(null);
	const [error, setError] = useState("");
	const [clientLinks, setClientLinks] = useState([]);

	const fetchClient = async () => {
		await axios
			.post(process.env.REACT_APP_BACKEND_URL + "/api/getCoachProfile", {
				coachId: user.id,
			})
			.then((response) => {
				let _user = response.data;
				reset({
					first_name: _user?.first_name || "",
					last_name: _user?.last_name || "",
					weight_notification_days:
						_user?.weight_notification_days || 0,
					weight_notification_enabled:
						_user?.weight_notification_enabled || false,
					step_notification_days: _user?.step_notification_days || 0,
					step_notification_enabled:
						_user?.step_notification_enabled || false,
					habit_notification_days:
						_user?.habit_notification_days || 0,
					habit_notification_enabled:
						_user?.habit_notification_enabled || false,
					photos_notification_days:
						_user?.photos_notification_days || 0,
					photos_notification_enabled:
						_user?.photos_notification_enabled || false,
					primary_link_text: _user?.primary_link_text || "",
					primary_link_value: _user?.primary_link_value || "",
				});
				setCurrentUser(_user);
				setClientLinks(sortWithKey(response.data.links, "createdAt"));

				setLoading(false);
			})
			.catch((err) => {
				setError("No User Found.");
				setLoading(false);
			});
	};
	useEffect(() => {
		fetchClient();
		// eslint-disable-next-line
	}, []);

	const updateCoach = (values) => {
		axios
			.post(
				process.env.REACT_APP_BACKEND_URL +
					"/api/coach/update/" +
					user.id,
				{
					...values,
					coachId: user.id,
				}
			)
			.then((response) => {
				toast("Profile Updated.", {
					type: "success",
				});
			})
			.catch((err) => {
				toast("Unable to update profile.", {
					type: "error",
				});
				setLoading(false);
			});
	};

	const CollapseButton = ({ title, value }) => {
		return (
			<div>
				<button
					onClick={() => {
						if (currentOpen === value) {
							setCurrentOpen("");
						} else {
							setCurrentOpen(value);
						}
					}}
					className="text-white text-xl flex w-max items-center mt-8"
				>
					<span>{title}</span>{" "}
					<img
						src={ArrowDown}
						alt="Arrow down"
						className={`ml-2 ${
							currentOpen === value ? "" : "rotate-[270deg]"
						}`}
					/>
				</button>
				<div className="w-full bg-white mt-4 mb-12 h-px" />{" "}
			</div>
		);
	};

	return (
		<>
			{error ? (
				<div className="h-screen flex items-center justify-center">
					<h1 className="text-center text-white text-5xl">{error}</h1>
				</div>
			) : (
				<div>
					<CollapseButton title="Basic Info" value={"basic"} />
					<Collapse isOpened={currentOpen === "basic"}>
						<form onSubmit={handleSubmit(updateCoach)}>
							<div className="flex justify-between w-full flex-wrap">
								<div className="mb-6 w-full md:w-[48%]">
									<label className="text-white font-medium">
										First Name
									</label>
									<input
										{...register("first_name", {
											required: "Required.",
										})}
										type="text"
										defaultValue={
											currentUser?.first_name || ""
										}
										className="placeholder-gray-400 mt-2 form-control block w-full px-4 py-2 text-xl font-normal bg-transparent border border-white border-opacity-20 rounded transition ease-in-out m-0 focus:outline-none text-gray-400 shadow"
									/>
									{errors.first_name && (
										<p className="mt-1 text-xs text-red-500">
											{errors.first_name.message}
										</p>
									)}
								</div>
								<div className="mb-6 w-full md:w-[48%]">
									<label className="text-white font-medium">
										Last Name
									</label>
									<input
										{...register("last_name", {
											required: "Required.",
										})}
										type="text"
										className="placeholder-gray-400 mt-2 form-control block w-full px-4 py-2 text-xl font-normal bg-transparent border border-white border-opacity-20 rounded transition ease-in-out m-0 focus:outline-none text-gray-400 shadow"
									/>
									{errors.last_name && (
										<p className="mt-1 text-xs text-red-500">
											{errors.last_name.message}
										</p>
									)}
								</div>
							</div>{" "}
							<h1 className="text-white text-2xl my-12 uppercase">
								Check-In notifications
							</h1>
							<div className="flex justify-between w-full flex-wrap">
								<div className="mb-6 w-full md:w-[48%]">
									<label className="text-white font-medium">
										Weight
									</label>
									<input
										{...register(
											"weight_notification_days",
											{
												required: "Required.",
												min: {
													value: 1,
													message:
														"Minimum value should be 1.",
												},
												max: {
													value: 30,
													message:
														"Maximum value should be 10.",
												},
											}
										)}
										type="number"
										className="placeholder-gray-400 mt-2 form-control block w-full px-4 py-2 text-xl font-normal bg-transparent border border-white border-opacity-20 rounded transition ease-in-out m-0 focus:outline-none text-gray-400 shadow"
									/>
									<div className="flex items-center mt-4">
										<input
											{...register(
												"weight_notification_enabled"
											)}
											type="checkbox"
											className="cursor-pointer mr-2 form-control block px-4 py-2 text-xl font-normal bg-transparent border border-white border-opacity-20 rounded transition ease-in-out m-0 focus:outline-none text-white shadow"
										/>
										<label className="text-white">
											Enabled
										</label>
									</div>
									{errors.weight_notification_days && (
										<p className="mt-1 text-xs text-red-500">
											{
												errors.weight_notification_days
													.message
											}
										</p>
									)}
								</div>
								<div className="mb-6 w-full md:w-[48%]">
									<label className="text-white font-medium">
										Steps
									</label>
									<input
										{...register("step_notification_days", {
											required: "Required.",
											min: {
												value: 1,
												message:
													"Minimum value should be 1.",
											},
											max: {
												value: 30,
												message:
													"Maximum value should be 10.",
											},
										})}
										type="number"
										className="placeholder-gray-400 mt-2 form-control block w-full px-4 py-2 text-xl font-normal bg-transparent border border-white border-opacity-20 rounded transition ease-in-out m-0 focus:outline-none text-gray-400 shadow"
									/>
									<div className="flex items-center mt-4">
										<input
											{...register(
												"step_notification_enabled"
											)}
											type="checkbox"
											className="cursor-pointer mr-2 form-control block px-4 py-2 text-xl font-normal bg-transparent border border-white border-opacity-20 rounded transition ease-in-out m-0 focus:outline-none text-white shadow"
										/>
										<label className="text-white">
											Enabled
										</label>
									</div>
									{errors.step_notification_days && (
										<p className="mt-1 text-xs text-red-500">
											{
												errors.step_notification_days
													.message
											}
										</p>
									)}
								</div>
								<div className="mb-6 w-full md:w-[48%]">
									<label className="text-white font-medium">
										Habit
									</label>
									<input
										{...register(
											"habit_notification_days",
											{
												required: "Required.",
												min: {
													value: 1,
													message:
														"Minimum value should be 1.",
												},
												max: {
													value: 30,
													message:
														"Maximum value should be 10.",
												},
											}
										)}
										type="number"
										className="placeholder-gray-400 mt-2 form-control block w-full px-4 py-2 text-xl font-normal bg-transparent border border-white border-opacity-20 rounded transition ease-in-out m-0 focus:outline-none text-gray-400 shadow"
									/>
									<div className="flex items-center mt-4">
										<input
											{...register(
												"habit_notification_enabled"
											)}
											type="checkbox"
											className="cursor-pointer mr-2 form-control block px-4 py-2 text-xl font-normal bg-transparent border border-white border-opacity-20 rounded transition ease-in-out m-0 focus:outline-none text-white shadow"
										/>
										<label className="text-white">
											Enabled
										</label>
									</div>
									{errors.habit_notification_days && (
										<p className="mt-1 text-xs text-red-500">
											{
												errors.habit_notification_days
													.message
											}
										</p>
									)}
								</div>
								<div className="mb-6 w-full md:w-[48%]">
									<label className="text-white font-medium">
										Progress photos
									</label>
									<input
										{...register(
											"photos_notification_days",
											{
												required: "Required.",
												min: {
													value: 1,
													message:
														"Minimum value should be 1.",
												},
												max: {
													value: 30,
													message:
														"Maximum value should be 10.",
												},
											}
										)}
										type="number"
										className="placeholder-gray-400 mt-2 form-control block w-full px-4 py-2 text-xl font-normal bg-transparent border border-white border-opacity-20 rounded transition ease-in-out m-0 focus:outline-none text-gray-400 shadow"
									/>
									<div className="flex items-center mt-4">
										<input
											{...register(
												"photos_notification_enabled"
											)}
											type="checkbox"
											className="cursor-pointer mr-2 form-control block px-4 py-2 text-xl font-normal bg-transparent border border-white border-opacity-20 rounded transition ease-in-out m-0 focus:outline-none text-white shadow"
										/>
										<label className="text-white">
											Enabled
										</label>
									</div>
									{errors.photos_notification_days && (
										<p className="mt-1 text-xs text-red-500">
											{
												errors.photos_notification_days
													.message
											}
										</p>
									)}
								</div>
							</div>
							<div className="w-full flex justify-center">
								<button
									type="submit"
									className="inline-block px-7 py-3 bg-button text-white font-medium text-sm leading-snug rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out w-[200px] mx-auto"
									data-mdb-ripple="true"
									data-mdb-ripple-color="light"
								>
									Submit
								</button>
							</div>
						</form>
					</Collapse>
				</div>
			)}
			{/* Linkssss */}
			<div>
				<CollapseButton title="Links" value={"links"} />
				<Collapse isOpened={currentOpen === "links"}>
					<form onSubmit={handleSubmit(updateCoach)}>
						<h1 className="text-white text-2xl my-12 uppercase">
							Links
						</h1>
						<div className="flex justify-between w-full flex-wrap">
							<div className="mb-6 w-full md:w-[48%]">
								<label className="text-white font-medium">
									Primary
								</label>
								<div>
									<input
										{...register("primary_link_text", {
											required: "Required.",
										})}
										type="text"
										placeholder="Text"
										className="placeholder-gray-400 mt-2 form-control block w-full px-4 py-2 text-xl font-normal bg-transparent border border-white border-opacity-20 rounded transition ease-in-out m-0 focus:outline-none text-gray-400 shadow"
									/>

									{errors.primary_link_text && (
										<p className="mt-1 text-xs text-red-500">
											{errors.primary_link_text.message}
										</p>
									)}
								</div>
								<input
									{...register("primary_link_value", {
										required: "Required.",
									})}
									type="text"
									placeholder="Value"
									className="placeholder-gray-400 mt-2 form-control block w-full px-4 py-2 text-xl font-normal bg-transparent border border-white border-opacity-20 rounded transition ease-in-out m-0 focus:outline-none text-gray-400 shadow"
								/>

								{errors.primary_link_value && (
									<p className="mt-1 text-xs text-red-500">
										{errors.primary_link_value.message}
									</p>
								)}
							</div>
						</div>
						<div className="w-full flex justify-center">
							<button
								type="submit"
								className="inline-block px-7 py-3 bg-button text-white font-medium text-sm leading-snug rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out w-[200px] mx-auto"
								data-mdb-ripple="true"
								data-mdb-ripple-color="light"
							>
								Submit
							</button>
						</div>
					</form>
					<div className="bg-[#1e1e1e] rounded-xl py-8 w-full my-12 shadow-xl mb-8 xl:mb-0">
						<LinksTable
							tableData={clientLinks || []}
							setFetchLoader={setLoading}
							fetchData={fetchClient}
							coachId={user.id}
						/>
					</div>
				</Collapse>
			</div>
			{/* Linkssss */}
			<div>
				<CollapseButton
					title="Exercise Database"
					value={"exercise_database"}
				/>
				<Collapse isOpened={currentOpen === "exercise_database"}>
					<ExerciseTable
						coachId={user.id}
						currentOpen={currentOpen}
					/>
				</Collapse>
			</div>
			<Loader loading={loading || isSubmitting} />
		</>
	);
};
export default CoachForm;
