import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import { toast } from "react-toastify";
import axios from "axios";
import { useParams } from "react-router-dom";
import { sortWithKey } from "../../utils";
import { LoaderButton } from "../Loader";
const LogBookModal = ({
	currentItem,
	setCurrentItem,
	tableData,
	setTableData,
}) => {
	const [value, setValue] = useState(currentItem?.log || "");
	const [loader, setLoader] = useState(false);
	const params = useParams();
	const createOrUpdate = async () => {
		setLoader(true);
		if (currentItem?.id) {
			axios
				.put(
					process.env.REACT_APP_BACKEND_URL +
						`/api/log-books/${currentItem.id}`,
					{
						clientId: params.id,
						log: value,
					}
				)
				.then((res) => {
					let _data = [...tableData];
					_data[_data.findIndex((item) => item.id === res.data.id)] =
						res.data;
					setTableData(_data);
					setCurrentItem(null);
					setLoader(false);
					toast("Record updated.", {
						type: "success",
					});
				})
				.catch((err) => {
					let _message = err.response.data.error.message;
					if (_message === "noBelonging") {
						toast("Record does not belong to you.", {
							type: "error",
						});
					} else if (_message === "recordExist") {
						toast("Entry for this date already exist.", {
							type: "info",
						});
					} else {
						toast("Something went wrong while updating record.", {
							type: "error",
						});
					}
					setLoader(false);
				});
		} else {
			axios
				.post(process.env.REACT_APP_BACKEND_URL + `/api/log-books/`, {
					log: value,
					clientId: params.id,
				})
				.then((res) => {
					console.log(res);
					let _data = [...tableData];
					_data.unshift(res.data);
					console.log("chekc data", _data);
					setTableData(sortWithKey(_data, "createdAt"));
					setLoader(false);
					setCurrentItem(null);
					toast("Record added successfully.", {
						type: "success",
					});
				})
				.catch((err) => {
					let _message = err.response.data.error.message;
					if (_message === "recordExist") {
						toast("Entry for this date already exist.", {
							type: "info",
						});
					} else {
						toast("Something went wrong while updating record.", {
							type: "error",
						});
					}
					setLoader(false);
				});
		}
	};

	const closeIcon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className="icon icon-tabler icon-tabler-x"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			strokeWidth="1.5"
			stroke="white"
			fill="none"
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<path stroke="none" d="M0 0h24v24H0z" fill="none" />
			<line x1="18" y1="6" x2="6" y2="18" />
			<line x1="6" y1="6" x2="18" y2="18" />
		</svg>
	);

	return (
		<div>
			<Modal
				closeIcon={closeIcon}
				open={currentItem}
				onClose={() => {
					setCurrentItem(null);
				}}
				center
			>
				<h2 className="w-full text-center text-2xl text-gray-400">
					{currentItem?.id ? "Edit" : "Add"} Log
				</h2>
				<form
					className="flex flex-col items-center"
					onSubmit={(e) => {
						e.preventDefault();
						createOrUpdate();
					}}
				>
					<div className="flex flex-col w-full mt-8 mx-auto">
						<input
							type="text"
							onChange={(e) => {
								setValue(e.target.value);
							}}
							defaultValue={currentItem?.log || value}
							required
							className=" w-11/12 md:w-[68%] mt-6 focus:outline-none resize-none bg-gray-800 rounded p-4 text-white shadow mx-auto"
							maxLength="60"
						/>
					</div>
					<div className="flex justify-center">
						<button
							type="submit"
							className={`${
								loader ? "px-0 py-2" : "px-7 py-3"
							} mx-auto mt-8 bg-button text-white font-medium text-sm leading-snug rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out
						`}
							data-mdb-ripple="true"
							data-mdb-ripple-color="light"
						>
							{loader ? (
								<LoaderButton md />
							) : currentItem.id ? (
								"Edit"
							) : (
								"Add"
							)}
						</button>
					</div>
				</form>
			</Modal>
		</div>
	);
};
export default LogBookModal;
