import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../../Components/Header";
import Loader from "../../Components/Loader";
import RedLightCall from "../../Assets/emergencyLight.png";
import Pencil from "../../Assets/pencil.svg";
import moment from "moment";
import WeightTable from "../../Components/WeightTable";
import StepsTable from "../../Components/StepsTable";
import HabitsTable from "../../Components/HabitsTable";
import LogBookTable from "../../Components/LogBookTable";
import { habits } from "../../data";
import { formattedDate, restructureHabit, sortWithKey } from "../../utils";
import ProtectedRoute from "../../utils/ProtectedRoute";
import FloatingMenu from "../../Components/FloatingMenu";
import OverviewMobile from "../../Components/OverViewMobile";
import OverViewDesktop from "../../Components/OverViewDesktop";
import axios from "axios";
import Navigation from "../../Components/Navigation";

const Detail = ({ user, role }) => {
	const params = useParams();
	const [userData, setUserData] = useState(null);
	const [logBook, setLogBook] = useState([]);
	const [weightData, setWeightData] = useState([]);
	const [stepsData, setStepsData] = useState([]);
	const [habitsData, setHabitsData] = useState([]);
	const [habitsLogData, setHabitsLogData] = useState([]);
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(true);
	const [fetchLoader, setFetchLoader] = useState(true);
	const [coachData, setCoachData] = useState(null);
	const [linksData, setLinksData] = useState([]);
	const [firstLoad, setFirstLoad] = useState(true);
	const [calendar, setCalendar] = useState([
		moment().subtract(13, "days"),
		moment(),
	]);

	const [calculation, setCalculation] = useState({
		weight: {
			items: [],
			average: 0,
			days: 0,
			min: 0,
			max: 0,
		},
		steps: {
			items: [],
			average: 0,
			days: 0,
			min: 0,
			max: 0,
		},
		habit: {
			items: [],
			average: 0,
			days: 0,
		},
	});
	const [activeGraph, setActiveGraph] = useState("weight");
	const [menuOpen, setMenuOpen] = useState(false);
	// Opened Items
	const [weightItem, setWeightItem] = useState(null);
	const [stepItem, setStepItem] = useState(null);
	const [habitItem, setHabitItem] = useState(null);
	const [logItem, setLogItem] = useState(null);

	const fetchClient = async () => {
		await axios
			.get(
				process.env.REACT_APP_BACKEND_URL +
					"/api/client/getClientDashboardData/" +
					params.id
			)
			.then((response) => {
				setUserData(response.data.client);
				setCoachData(response.data.coach);
				setLinksData(sortWithKey(response.data.links, "createdAt"));
				setWeightData(sortWithKey(response.data.weights, "date"));
				setStepsData(sortWithKey(response.data.steps, "date"));
				setLogBook(sortWithKey(response.data.logs, "createdAt"));
				setHabitsData(sortWithKey(response.data.habits, "date"));
				setHabitsLogData(
					sortWithKey(response.data.habit_notes, "createdAt")
				);

				setLoading(false);
				setFetchLoader(false);
				setMenuOpen(false);
			})
			.catch((err) => {
				setError("No User Found.");
				setLoading(false);
				setFetchLoader(false);
				setMenuOpen(false);
			});
	};

	useEffect(() => {
		fetchClient();
		// eslint-disable-next-line
	}, [params]);

	useEffect(() => {
		let _calculation = {
			...calculation,
		};

		// Steps Calucation
		let _steps = [...stepsData];
		_steps = _steps.filter(
			(item) =>
				formattedDate(item.date) >= formattedDate(calendar[0]) &&
				formattedDate(item.date) <= formattedDate(calendar[1])
		);

		if (!_steps.length || _steps.length < 3) {
			_calculation = {
				..._calculation,
				steps: {
					items: [],
					average: 0,
					days: 0,
					min: 0,
					max: 0,
				},
			};
		}

		if (_steps.length && _steps.length >= 3) {
			let _stepItems = [];
			_steps.forEach((step) => {
				_stepItems.push(parseFloat(step.steps));
			});
			_calculation = {
				..._calculation,
				steps: {
					items: _steps.reverse(),
					average: (
						_stepItems.reduce((a, b) => a + b) / _stepItems.length
					).toFixed(0),
					days: _stepItems.length,
					min: Math.min(..._stepItems),
					max: Math.max(..._stepItems),
				},
			};
		}
		// Weight Calucation
		let _weight = [...weightData];
		_weight = _weight.filter(
			(item) =>
				formattedDate(item.date) >= formattedDate(calendar[0]) &&
				formattedDate(item.date) <= formattedDate(calendar[1])
		);
		if (!_weight.length || _weight.length < 3) {
			_calculation = {
				..._calculation,
				weight: {
					items: [],
					average: 0,
					days: 0,
					min: 0,
					max: 0,
				},
			};
		}

		if (_weight.length && _weight.length >= 3) {
			let _weightItems = [];
			_weight.forEach((weight) => {
				_weightItems.push(parseFloat(weight.weight));
			});
			_calculation = {
				..._calculation,
				weight: {
					items: _weight.reverse(),
					average: (
						_weightItems.reduce((a, b) => a + b) /
						_weightItems.length
					).toFixed(1),
					days: _weightItems.length,
					min: Math.min(..._weightItems),
					max: Math.max(..._weightItems),
				},
			};
		}

		// Habit Calucation
		let _habit = [...habitsData];
		_habit = _habit.filter(
			(item) =>
				formattedDate(item.date) >= formattedDate(calendar[0]) &&
				formattedDate(item.date) <= formattedDate(calendar[1])
		);
		if (!_habit.length || _habit.length < 3) {
			_calculation = {
				..._calculation,
				habit: {
					items: [],
					average: 0,
					days: 0,
				},
			};
		}

		if (_habit.length && _habit.length >= 3) {
			let _habitItems = [];
			_habit.forEach((habit) => {
				_habitItems.push(Number(habit.habit));
			});
			_calculation = {
				..._calculation,
				habit: {
					items: _habit.reverse(),
					average:
						(_habitItems.reduce((a, b) => a + b) /
							(_habit.length * 5)) *
						100,
					days: _habitItems.length,
				},
			};
		}

		setCalculation(_calculation);
		// eslint-disable-next-line
	}, [habitsData, stepsData, weightData, calendar, activeGraph]);

	const displayHabit =
		userData?.current_habit &&
		!habits.find((item) => item.value === userData.current_habit)?.title
			? restructureHabit("", userData.current_habit)
			: habits?.find((item) => item?.value === userData?.current_habit)
					?.title || "Habit";
	let notTracking =
		!userData?.current_habit &&
		!userData?.weight_tracking &&
		!userData?.steps_tracking;

	return (
		<div className="min-h-screen bg-no-repeat bg-cover bg-[#111111]">
			<Header user={user} role={role} />
			{loading ? (
				<Loader loading={loading} />
			) : error ? (
				<div className="h-screen flex items-center justify-center">
					<h1 className="text-center text-white text-5xl">{error}</h1>
				</div>
			) : (
				<>
					<div className="container mx-auto lg:mt-12">
						<div className="w-11/12 mx-auto lg:w-full">
							<Navigation
								links={
									role === "Coach"
										? [
												{
													name: "Clients",
													url: "/coach/dashboard",
												},
												{
													name:
														userData?.first_name ||
														"Dashboard",
													url: `/member/${params.id}`,
												},
										  ]
										: []
								}
							/>
						</div>
						<div
							className={`flex justify-between flex-wrap lg:mt-4 ${
								notTracking ? "flex-row-reverse	" : ""
							}`}
						>
							{/* Graph Area Desktop */}
							{userData.weight_tracking ||
							userData.steps_tracking ||
							userData.current_habit ? (
								<OverViewDesktop
									userData={userData}
									setCalendar={setCalendar}
									calendar={calendar}
									displayHabit={displayHabit}
									calculation={calculation}
									params={params}
									activeGraph={activeGraph}
									setActiveGraph={setActiveGraph}
									role={role}
									openWeightModal={setWeightItem}
									openStepsModal={setStepItem}
									openHabitModal={setHabitItem}
								/>
							) : (
								<></>
							)}
							{/* Graph Area Desktop Ends*/}

							{/* Graph Area Ends*/}
							<div
								className={`${
									notTracking ? "xl:w-5/12" : "xl:w-3/12"
								} h-screen lg:h-auto xl:w-3/12 xl:pl-12 mt-4 xl:mt-0 w-11/12 mx-auto block lg:flex xl:block flex-wrap justify-between`}
							>
								<Link
									to={
										role === "Coach"
											? `/coach/${params.id}/sessions?name=${userData?.first_name}`
											: `/member/${params.id}/sessions?name=${userData?.first_name}`
									}
								>
									<div className="rounded-xl flex items-center cursor-pointer mb-4vh lg:mb-6 gradient-bg p-[2px]">
										<div className="h-[24vh] lg:h-[400px] p-4 bg-[#1e1e1e] w-full bg-cover bg-center bg-no-repeat relative shadow-xl rounded-xl flex items-center justify-center flex-col">
											<div className="absolute z-0 inset-0 bg-[#1e1e1e] bg-opacity-50 rounded-xl" />
											<p className="text-white relative text-4xl font-semibold">
												Training
											</p>
										</div>
									</div>
								</Link>
								{userData.weight_tracking ||
								userData.steps_tracking ||
								userData.current_habit ? (
									<OverviewMobile
										userData={userData}
										setCalendar={setCalendar}
										calendar={calendar}
										displayHabit={displayHabit}
										calculation={calculation}
										activeGraph={activeGraph}
										setActiveGraph={setActiveGraph}
										openWeightModal={setWeightItem}
										openStepsModal={setStepItem}
										openHabitModal={setHabitItem}
									/>
								) : (
									<></>
								)}
								{coachData?.primary_link_text &&
								coachData?.primary_link_value ? (
									<a
										href={coachData?.primary_link_value}
										target="_blank"
										rel="noreferrer"
										className="lg:w-[48%] h-[16vh] lg:h-auto xl:w-full block"
									>
										<div className=" bg-[#1e1e1e] rounded-xl px-4 py-4 flex items-center cursor-pointer lg:mb-6">
											<div className="h-12 w-12 rounded bg-blue-200 shadow flex items-center justify-center">
												<img
													alt="Form Check"
													src={RedLightCall}
													className="w-8 h-8"
												/>
											</div>
											<p className="text-gray-400 pl-4 text-xl font-medium">
												{coachData.primary_link_text}
											</p>
										</div>
									</a>
								) : (
									<div />
								)}

								{/* <Link
                  className="lg:w-[48%] xl:w-full"
                  to={`/member/${params.id}/media`}
                >
                  <div className="hidden lg:flex w-full bg-[#1e1e1e] rounded-xl px-4 py-4 items-center cursor-pointer xl:mb-6">
                    <div className="h-12 w-12 rounded bg-[#7fb77d] shadow flex items-center justify-center">
                      <img alt="Log Book" src={Media} className="w-8 h-8" />
                    </div>
                    <p className="text-gray-400 pl-4 text-xl font-medium">
                      Photo Tracking
                    </p>
                  </div>
                </Link> */}
								<button
									onClick={() => {
										setMenuOpen(true);
										setFirstLoad(false);
									}}
									className="hidden lg:flex w-full lg:w-[48%] xl:w-full bg-[#1e1e1e] rounded-xl px-4 py-4 items-center cursor-pointer"
								>
									<div className="h-12 w-12 rounded bg-[#d6b369] shadow flex items-center justify-center">
										<img
											alt="Form Check"
											src={Pencil}
											className="w-8 h-8"
										/>
									</div>
									<p className="text-gray-400 pl-4 text-xl font-medium">
										Updates
									</p>
								</button>
							</div>
							{userData?.weight_tracking && (
								<div className="bg-[#1e1e1e] rounded-xl py-8 mx-auto lg:mx-0 w-11/12 xl:w-[49%] shadow-xl mt-12">
									<WeightTable
										setFetchLoader={setFetchLoader}
										tableData={weightData}
										setTableData={setWeightData}
										currentItem={weightItem}
										setCurrentItem={setWeightItem}
									/>
								</div>
							)}
							{userData.steps_tracking && (
								<div className="bg-[#1e1e1e] rounded-xl py-8  mx-auto lg:mx-0 w-11/12 xl:w-[49%] shadow-xl mt-12">
									<StepsTable
										setFetchLoader={setFetchLoader}
										tableData={stepsData}
										setTableData={setStepsData}
										currentItem={stepItem}
										setCurrentItem={setStepItem}
									/>
								</div>
							)}
							{userData?.current_habit && (
								<div className="bg-[#1e1e1e] rounded-xl py-8  mx-auto lg:mx-0 w-11/12 xl:w-[49%] shadow-xl mt-12">
									<HabitsTable
										role={role}
										habitsLogData={habitsLogData}
										setFetchLoader={setFetchLoader}
										displayHabit={displayHabit}
										current_habit={userData.current_habit}
										tableData={habitsData}
										setTableData={setHabitsData}
										currentItem={habitItem}
										setCurrentItem={setHabitItem}
									/>
								</div>
							)}

							<div className="bg-[#1e1e1e] rounded-xl py-8  mx-auto lg:mx-0 w-11/12 xl:w-[49%] shadow-xl mt-12">
								<LogBookTable
									setFetchLoader={setFetchLoader}
									tableData={logBook}
									setTableData={setLogBook}
									currentItem={logItem}
									setCurrentItem={setLogItem}
								/>
							</div>
						</div>
					</div>
					<footer className="py-12"></footer>
					<Loader loading={fetchLoader} />
				</>
			)}
			<FloatingMenu
				userData={userData}
				setWeightItem={setWeightItem}
				menuOpen={menuOpen}
				setMenuOpen={setMenuOpen}
				setStepItem={setStepItem}
				currentHabit={userData?.current_habit}
				setHabitItem={setHabitItem}
				setLogItem={setLogItem}
				coachData={coachData}
				clientLinks={linksData}
				loading={loading}
				params={params}
				firstLoad={firstLoad}
				setFirstLoad={setFirstLoad}
			/>
		</div>
	);
};

const ClientDetail = () => {
	return (
		<ProtectedRoute>
			<Detail />
		</ProtectedRoute>
	);
};
export default ClientDetail;
