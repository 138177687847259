import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import Table from "../../Components/Table";
import EditIcon from "../../Assets/edit.svg";
import DeleteIcon from "../../Assets/delete.svg";
import SessionIcon from "../../Assets/trainingSession.svg";
import OpenIcon from "../../Assets/open.svg";
import HistoryIcon from "../../Assets/history.svg";
import Loader from "../../Components/Loader";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Client from "../../Assets/client.svg";
import AddIcon from "../../Assets/addIcon.svg";
import { sortWithKey } from "../../utils";
import moment from "moment";

const ClientListComponent = ({ user, role }) => {
	const [clients, setClients] = useState([]);
	const [loading, setLoading] = useState(true);
	const [clientsCopy, setClientsCopy] = useState([]);

	const filterHandler = (val) => {
		if (val === "blocked") {
			setClients([...clientsCopy].filter((item) => item.user.blocked));
		} else {
			setClients([...clientsCopy].filter((item) => !item.user.blocked));
		}
	};

	const fetchClients = async () => {
		axios
			.post(
				process.env.REACT_APP_BACKEND_URL +
					"/api/coach/getCoachClients",
				{
					coachId: user.id,
				}
			)
			.then((res) => {
				if (res.data.length) {
					let _clients = [...res.data];
					setClients(_clients.filter((item) => !item.user.blocked));
					setClientsCopy(_clients);
				} else {
					setClients(res.data);
					setClientsCopy(res.data);
				}

				setLoading(false);
			})
			.catch((err) => {
				toast("Unable to fetch clients", {
					type: "error",
				});
				setLoading(false);
			});
	};

	useEffect(() => {
		fetchClients();
		// eslint-disable-next-line
	}, [user.id]);

	const deleteClient = async (id) => {
		if (window.confirm(`Are you sure to delete this client`)) {
			setLoading(true);
			axios
				.post(
					process.env.REACT_APP_BACKEND_URL +
						"/api/client/updateClientAccess/" +
						id,
					{
						remove: true,
					}
				)
				.then((res) => {
					toast("Client deleted.", {
						type: "success",
					});
					fetchClients();
				})
				.catch((err) => {
					let _message = err.response.data.error.message;
					if (_message === "noBelonging") {
						toast("Client does not belong to you.", {
							type: "error",
						});
					} else {
						toast("Something went wrong while deleting user.", {
							type: "error",
						});
					}
					setLoading(false);
				});
		}
	};
	const getLastTrainingSession = (sessions) => {
		let _completedSessions = sessions.filter(
			(item) => item.status === "completed"
		);
		_completedSessions = _completedSessions.sort(
			(a, b) =>
				new Date(b.session_data.completeTime) -
				new Date(a.session_data.completeTime)
		);

		if (_completedSessions.length) {
			if (_completedSessions[0].session_data.completeTime) {
				let date1 = moment(new Date());
				let date2 = moment(
					_completedSessions[0].session_data.completeTime
				);
				var diffInDays = date1.diff(date2, "days");

				if (diffInDays && diffInDays >= 1) {
					return diffInDays + " " + "day(s) ago";
				} else return "Today";
			}
		}

		return "N/A";
	};
	return (
		<div className="min-h-screen bg-no-repeat bg-cover bg-[#111111]">
			<Header user={user} role={role} />
			<div className="container mx-auto pb-12 w-11/12 mx-auto lg:W-full mt-12">
				<div className="bg-[#1e1e1e] rounded-xl py-8 relative mt-8">
					<div className="w-full px-8 relative pt-12">
						<div className="flex items-center flex-col absolute top-0 -mt-12">
							<div className="h-12 w-12 rounded bg-[#7fb77d] shadow flex items-center justify-center">
								<img
									alt="Client Icon"
									src={Client}
									className="w-8 h-8"
								/>
							</div>
							<p className="text-white text-lg font-bold mt-1">
								Clients
							</p>
						</div>
						<div className="flex sm:float-right items-center mb-4 xl:mb-0">
							<select
								disabled={!clientsCopy.length}
								onChange={(e) => {
									filterHandler(e.target.value);
								}}
								className="mr-4 w-[200px] placeholder-gray-400 form-control block px-4 py-2 font-normal bg-transparent border border-white rounded transition ease-in-out m-0 focus:outline-none text-gray-400 shadow"
							>
								<option selected value="active">
									Active
								</option>
								<option value="blocked">Blocked</option>
							</select>
							<Link to={`/member/add`}>
								<button className="float-right" type="button">
									<img
										alt="Add"
										src={AddIcon}
										className="w-8"
									/>
								</button>
							</Link>
						</div>
						<Table
							headerColor="#1e1e1e"
							columns={[
								{
									id: "first_name",
									header: () => "First Name",
									accessorFn: (row) => `${row.first_name}`,
									cell: (info) => {
										return (
											<Link
												to={`/member/${info.row.original.uuid}`}
											>
												<span className="whitespace-nowrap py-4 text-sm text-white text-right">
													{`${
														info.row.original
															.first_name
															? info.row.original
																	.first_name
															: "--"
													}`}
												</span>
											</Link>
										);
									},
								},
								{
									id: "sort",
									header: () => "Last Name",
									accessorFn: (row) => `${row.last_name}`,
									cell: (info) => {
										return (
											<Link
												to={`/member/${info.row.original.uuid}`}
											>
												<span className="whitespace-nowrap py-4 text-sm text-white text-right">
													{`${
														info.row.original
															.last_name
															? info.row.original
																	.last_name
															: "--"
													}`}
												</span>
											</Link>
										);
									},
								},

								{
									id: "sessions",
									header: () => "Live Sessions",
									accessorFn: (row) =>
										`${
											row.sessions.length
												? row.sessions.filter(
														(item) =>
															item.status ===
															"live"
												  ).length
												: 0
										}`,
									cell: (info) => {
										let _upcomingSessions = info.row
											.original.sessions.length
											? info.row.original.sessions.filter(
													(item) =>
														item.status === "live"
											  ).length
											: "0";
										return (
											<Link
												to={`/coach/${info.row.original.uuid}/sessions?name=${info.row.original.first_name}`}
											>
												<span className="`whitespace-nowrap py-4 text-sm text-right text-white">
													{_upcomingSessions}
												</span>
											</Link>
										);
									},
								},
								{
									id: "lastSession",
									header: () => "Last Session",
									accessorFn: (row) =>
										`${getLastTrainingSession(
											row.sessions
										)}`,
									cell: (info) => {
										return (
											<Link
												to={`/coach/${info.row.original.uuid}/sessions?name=${info.row.original.first_name}`}
											>
												<span className="`whitespace-nowrap py-4 text-sm text-right text-white">
													{getLastTrainingSession(
														info.row.original
															.sessions
													)}
												</span>
											</Link>
										);
									},
								},
								{
									id: "actions",
									header: () => "Action",
									cell: (info) => {
										return (
											<div className="flex">
												<div className="cursor-pointer text-white">
													<Link
														to={`/member/${info.row.original.uuid}`}
													>
														{" "}
														<img
															src={OpenIcon}
															alt="Open Icon"
														/>
													</Link>
												</div>
												<Link
													to={`/member/edit/${info.row.original.uuid}`}
												>
													<div className="ml-4 cursor-pointer">
														<img
															src={EditIcon}
															alt="Edit Icon"
														/>
													</div>
												</Link>
												<Link
													to={`/coach/${info.row.original.uuid}/sessions?name=${info.row.original.first_name}`}
												>
													<div className="ml-4 cursor-pointer">
														<img
															src={SessionIcon}
															alt="Session Icon"
														/>
													</div>
												</Link>
												<Link
													to={`/member/${info.row.original.uuid}/history?name=${info.row.original.first_name}`}
												>
													<div className="ml-4 cursor-pointer">
														<img
															src={HistoryIcon}
															alt="Edit Icon"
														/>
													</div>
												</Link>
												<div
													className="ml-4 cursor-pointer"
													onClick={() => {
														deleteClient(
															info.row.original
																.uuid
														);
													}}
												>
													<img
														src={DeleteIcon}
														alt="Delete Icon"
													/>
												</div>
											</div>
										);
									},
								},
							]}
							data={clients}
						/>
					</div>
				</div>
			</div>
			<Loader loading={loading} />
		</div>
	);
};
export default ClientListComponent;
