import React from "react";
import "./App.css";
import Login from "./pages/login";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ClientList from "./pages/client-list";
import ClientDetail from "./pages/client-detail";
import EditClient from "./pages/edit-client";
import AddClient from "./pages/add-client";
import Verify from "./pages/login/verify";
import ProtectedRoute from "./utils/ProtectedRoute";
import Media from "./pages/media";
import CoachProfile from "./pages/coach-profile";
import CoachNotifications from "./pages/coach-notifications";
import Sessions from "./pages/training-sessions";
import SessionDetail from "./pages/training-session-detail";
import ClientSessions from "./pages/client-training-sessions";
import ClientSessionDetail from "./pages/client-training-session-detail";
import ClientSessionPreview from "./pages/client-training-session-preview";
import HistoryList from "./pages/history-list";
import HistoryDetailPage from "./pages/history-detail";
export default function App() {
	return (
		<Router>
			<Routes>
				{/* Protected Route */}
				<Route path="/" element={<ProtectedRoute />} />

				{/* Other routes */}
				<Route path="/login" element={<Login />} />
				<Route path="/verify" element={<Verify />} />
				<Route path="/coach/dashboard" element={<ClientList />} />
				<Route path="/member/:id/" element={<ClientDetail />} />
				{/* <Route path="/member/:id/media" element={<Media />} /> */}
				<Route path="/member/edit/:id" element={<EditClient />} />
				<Route path="/coach/profile" element={<CoachProfile />} />
				<Route
					path="/coach/notifications/"
					element={<CoachNotifications />}
				/>
				<Route path="/member/add" element={<AddClient />} />
				<Route path="/coach/:id/sessions" element={<Sessions />} />
				<Route
					path="/member/:id/sessions"
					element={<ClientSessions />}
				/>
				<Route
					path="/coach/:id/sessions/:sessionId"
					element={<SessionDetail />}
				/>
				<Route
					path="/member/:id/sessions/:sessionId"
					element={<ClientSessionDetail />}
				/>
				<Route
					path="/member/:id/preview/:sessionId"
					element={<ClientSessionPreview />}
				/>
				<Route path="/member/:id/history" element={<HistoryList />} />
				<Route
					path="/member/:id/history/:exerciseId"
					element={<HistoryDetailPage />}
				/>
				{/* Redirect to root for unmatched routes */}
				<Route path="*" element={<NotFound />} />
			</Routes>
		</Router>
	);
}

function NotFound() {
	const navigateToRoot = () => {
		window.location.href = "/";
	};

	// Perform the redirection to root
	React.useEffect(() => {
		navigateToRoot();
	}, []);

	return <div></div>;
}
