import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import ProtectedRoute from "../../utils/ProtectedRoute";
import Header from "../../Components/Header";
import Loader from "../../Components/Loader";
import TrainingExerciseDrawer from "../../Components/TrainingExerciseDrawer";
import AddIcon from "../../Assets/add.svg";
import CloseIcon from "../../Assets/close.svg";
import { toast } from "react-toastify";
import GroupIcon from "../../Assets/groupIcon.svg";
import DeleteIcon from "../../Assets/delete.svg";
import OpenIcon from "../../Assets/open.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import Navigation from "../../Components/Navigation";
import { TfiWrite } from "react-icons/tfi";

const TrainingSessionDetailComponent = ({ user, role }) => {
	const [trainingSession, setTrainingSession] = useState(null);
	const [currentItem, setCurrentItem] = useState(false);
	const [exerciseDatabase, setExerciseDatabase] = useState([]);
	const [historyItems, setHistoryItems] = useState([]);
	const [loader, setLoader] = useState(true);
	const [editMode, setEditMode] = useState(false);
	const [descriptionEditMode, setDescriptionEditMode] = useState(false);
	const [editingId, setEditingId] = useState(null);
	const [editingValue, setEditingValue] = useState("");
	const headingRef = useRef(null);
	const descriptionRef = useRef(null);

	const dispatch = useDispatch();
	const params = useParams();
	useEffect(() => {
		// Call to get training Sessions
		axios
			.post(
				process.env.REACT_APP_BACKEND_URL +
					`/api/training-sessions/getTrainingSession/`,
				{
					clientId: params.id,
					id: params.sessionId,
				}
			)
			.then((response) => {
				setTrainingSession(response.data);
			})
			.catch((err) => {
				toast("Unable to get session data.", {
					type: "error",
				});
				setLoader(false);
			});

		// Call to get exercise database
		axios
			.post(
				process.env.REACT_APP_BACKEND_URL +
					"/api/exercise-database/getList/",
				{
					coachId: user.id,
				}
			)
			.then((response) => {
				setExerciseDatabase(response.data);
				setLoader(false);
			})
			.catch((err) => {
				toast("Unable to get exercise database.", {
					type: "error",
				});
				setLoader(false);
			});

		axios
			.get(
				process.env.REACT_APP_BACKEND_URL +
					`/api/client-history/${params.id}/getClientHistory`
			)
			.then((res) => {
				setHistoryItems(res.data);
				setLoader(false);
			})
			.catch((err) => {
				toast("Unable to fetch history", {
					type: "error",
				});
				setLoader(false);
			});
		// eslint-disable-next-line
	}, []);

	const deleteExercise = (type, itemIndex, exerciseIndex) => {
		if (window.confirm("Are you sure you want to delete this exercise?")) {
			let _sessiondata = { ...trainingSession.session_data };
			setLoader(true);

			let deleteRequest = () => {
				axios
					.post(
						process.env.REACT_APP_BACKEND_URL +
							"/api/training-sessions/updateSessionData/",
						{
							id: params.sessionId,
							session_data: _sessiondata,
						}
					)
					.then((response) => {
						toast("Exercise deleted", {
							type: "success",
						});
						setTrainingSession(response.data);
					})
					.catch((err) => {
						toast("Unable to delete exercise.", {
							type: "error",
						});
					})
					.finally(() => {
						setLoader(false);
					});
			};

			if (type === "single") {
				_sessiondata.items = _sessiondata.items.filter(
					(item) => item.uuid !== exerciseIndex
				);
				deleteRequest();
			} else {
				let group = _sessiondata.items[exerciseIndex];
				let groupLength = group.exercises.length;
				if (groupLength === 2) {
					group.name = "";
				}
				group.exercises.splice(itemIndex, 1);
				deleteRequest();
			}
		}
	};

	const dragEnd = (val) => {
		let _sessionData = { ...trainingSession.session_data };
		const requestData = {
			id: trainingSession.id,
			session_data: _sessionData,
		};
		const updateTrainingSession = () => {
			axios
				.post(
					process.env.REACT_APP_BACKEND_URL +
						"/api/training-sessions/updateSessionData/",
					requestData
				)
				.then((response) => {
					toast("Training session updated", {
						type: "success",
					});
					setTrainingSession(response.data);
				})
				.catch((err) => {
					toast("Unable to update session.", {
						type: "error",
					});
				})
				.finally(() => {
					setLoader(false);
				});
		};

		let { source, destination } = val;

		if (
			!destination ||
			(source.droppableId === destination.droppableId &&
				source.index === destination.index)
		) {
			return;
		}

		setLoader(true);

		if (
			source.droppableId === "mainColumn" &&
			destination.droppableId === "mainColumn"
		) {
			let draggedItem = _sessionData.items[source.index];
			_sessionData.items.splice(source.index, 1);
			_sessionData.items.splice(destination.index, 0, draggedItem);
			updateTrainingSession();
		} else {
			// Handle dragging and dropping inside the "exercises" array
			let sourceGroupIndex = _sessionData.items.findIndex(
				(item) => item.uuid === source.droppableId
			);
			let destinationGroupIndex = _sessionData.items.findIndex(
				(item) => item.uuid === destination.droppableId
			);

			if (sourceGroupIndex !== -1 && destinationGroupIndex !== -1) {
				let sourceGroup =
					_sessionData.items[sourceGroupIndex].exercises;
				let destinationGroup =
					_sessionData.items[destinationGroupIndex].exercises;

				if (sourceGroup && destinationGroup) {
					let draggedItem = sourceGroup[source.index];
					sourceGroup.splice(source.index, 1);
					destinationGroup.splice(destination.index, 0, draggedItem);
					updateTrainingSession();
				}
			}
		}
	};
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				headingRef.current &&
				!headingRef.current.contains(event.target)
			) {
				if (editMode) {
					updateHeadingText("name");
					setEditMode(false);
				}
			}
			if (
				descriptionRef.current &&
				!descriptionRef.current.contains(event.target)
			) {
				if (descriptionEditMode) {
					updateHeadingText("desc");
					setDescriptionEditMode(false);
				}
			}

			if (
				(editingId || editingId === 0) &&
				event.target.id.indexOf("group-name-") === -1 &&
				editingValue
			) {
				updateGroupTitle();
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
		// eslint-disable-next-line
	}, [editMode, descriptionEditMode, editingId, editingValue]);

	const updateGroupTitle = () => {
		let _sessionData = { ...trainingSession }.session_data;
		if (_sessionData?.items[editingId].name === editingValue.trim()) {
			setEditingId(null);
			// setEditingValue("");
		} else {
			_sessionData.items[editingId].name = editingValue;
			const requestData = {
				id: trainingSession.id,
				session_data: _sessionData,
			};
			axios
				.post(
					process.env.REACT_APP_BACKEND_URL +
						"/api/training-sessions/updateSessionData/",
					requestData
				)
				.then((response) => {
					toast("Training session updated", {
						type: "success",
					});
					setTrainingSession(response.data);
					setEditingId(null);
					setEditingValue("");
				})
				.catch((err) => {
					toast("Unable to update session.", {
						type: "error",
					});
				})
				.finally(() => {
					setLoader(false);
					setEditingId(null);
					setEditingValue("");
				});
		}
	};

	const updateHeadingText = async (type) => {
		if (
			type === "name" &&
			headingRef?.current?.value?.trim() === trainingSession.name
		) {
			return;
		}
		if (
			type === "desc" &&
			descriptionRef?.current?.value?.trim() === trainingSession.text_note
		) {
			return;
		}

		setLoader(true);
		try {
			const response = await axios.post(
				process.env.REACT_APP_BACKEND_URL +
					"/api/training-sessions/updateSessionData/",
				{
					id: trainingSession.id,
					type: "nameUpdate",
					name: headingRef?.current?.value,
					text_note: descriptionRef?.current?.value,
				}
			);

			toast("Training session updated", {
				type: "success",
			});
			setTrainingSession(response.data);
			setLoader(false);
		} catch (error) {
			setLoader(false);
			toast("Unable to update session.", {
				type: "error",
			});
		}
	};
	return (
		<div className="min-h-screen bg-no-repeat bg-cover bg-[#111111] pb-12">
			<Header user={user} role={role} />
			<div className="container mx-auto mt-8 md:mt-12 w-11/12 sm:w-full">
				<Navigation
					links={[
						{
							name: "Clients",
							url: "/coach/dashboard",
						},
						{
							name: trainingSession?.client?.first_name,
							url: `/member/${params.id}`,
						},
						{
							name: "Sessions",
							url: `/coach/${params.id}/sessions?name=${trainingSession?.client?.first_name}`,
						},
						{
							name: trainingSession?.name || "Training Session",
							url: `/coach/${params.id}/sessions/${params.sessionId}`,
						},
					]}
				/>
				{editMode ? (
					<input
						required
						className="mx-auto w-[300px] placeholder-gray-400 mt-2 form-control block px-4 py-2 text-xl font-normal bg-transparent border border-white border-opacity-20 rounded transition ease-in-out m-0 focus:outline-none text-gray-400 shadow"
						type="text"
						defaultValue={trainingSession.name}
						ref={headingRef}
					/>
				) : (
					<>
						{trainingSession?.client && (
							<h1
								onClick={() => {
									setEditMode(true);
								}}
								className="text-2xl md:text-6xl text-gray-700 font-medium text-center mt-8 md:mt-4"
							>
								{trainingSession?.client?.first_name}'s{" "}
								{trainingSession?.name}
							</h1>
						)}
					</>
				)}
				{descriptionEditMode ? (
					<textarea
						rows={5}
						className="mx-auto w-full md:w-[500px] text-white mt-2 form-control block px-4 py-2 text-xl font-normal bg-transparent border border-white border-opacity-20 rounded transition ease-in-out m-0 focus:outline-none text-gray-400 shadow mt-4"
						type="text"
						defaultValue={trainingSession.text_note}
						ref={descriptionRef}
					/>
				) : (
					<>
						{trainingSession && (
							<>
								{trainingSession?.text_note ? (
									<pre
										onClick={() => {
											setDescriptionEditMode(true);
										}}
										className="text-xl mx-auto max-w-[600px] text-white font-medium text-center mt-4 text-wrap"
									>
										{trainingSession?.text_note}
									</pre>
								) : (
									<button
										onClick={() => {
											setDescriptionEditMode(true);
										}}
										className="mx-auto flex justify-center items-center mt-4"
									>
										<span className="text-white">
											Add Note
										</span>{" "}
										<TfiWrite className="text-white ml-1" />
									</button>
								)}
							</>
						)}
					</>
				)}

				<DragDropContext
					onDragStart={() => {}}
					onDragEnd={(val) => {
						dragEnd(val);
					}}
					onDragUpdate={() => {
						console.log("Drag start");
					}}
				>
					<Droppable droppableId="mainColumn">
						{(provided) => {
							return (
								<div
									className="md:w-8/12 mx-auto mt-12"
									ref={provided.innerRef}
									{...provided.droppableProps}
								>
									{trainingSession?.session_data?.items
										?.length ? (
										<>
											{trainingSession.session_data.items.map(
												(exercise, exerciseIndex) => {
													return (
														<div
															key={exerciseIndex}
														>
															{exercise?.exercises
																?.length > 1 ? (
																<GroupCard
																	exercise={
																		exercise
																	}
																	exerciseIndex={
																		exerciseIndex
																	}
																	key={
																		exerciseIndex
																	}
																	setCurrentItem={
																		setCurrentItem
																	}
																	deleteExercise={
																		deleteExercise
																	}
																	editingId={
																		editingId
																	}
																	setEditingId={
																		setEditingId
																	}
																	editingValue={
																		editingValue
																	}
																	setEditingValue={
																		setEditingValue
																	}
																/>
															) : (
																<SingleCard
																	exercise={
																		exercise
																	}
																	exerciseIndex={
																		exerciseIndex
																	}
																	key={
																		exerciseIndex
																	}
																	setCurrentItem={
																		setCurrentItem
																	}
																	deleteExercise={
																		deleteExercise
																	}
																/>
															)}
														</div>
													);
												}
											)}
										</>
									) : (
										!loader &&
										!trainingSession?.session_data?.items
											?.length && (
											<div className="w-full flex justify-center">
												<button
													className="mx-auto mx-auto mt-8 px-7 py-3 bg-button text-white font-medium text-sm leading-snug rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out"
													onClick={() => {
														setCurrentItem(true);
													}}
												>
													Add Exercises
												</button>
											</div>
										)
									)}
									{provided.placeholder}
								</div>
							);
						}}
					</Droppable>
				</DragDropContext>
			</div>
			<Loader loading={loader} />
			{currentItem && (
				<TrainingExerciseDrawer
					currentItem={currentItem}
					setCurrentItem={setCurrentItem}
					trainingSession={trainingSession}
					setTrainingSession={setTrainingSession}
					exerciseDatabase={exerciseDatabase}
					historyItems={historyItems}
				/>
			)}
			{/* Floating button */}
			{!currentItem && (
				<button className="bg-[#6f4bdb] rounded-full shadow fixed right-0 bottom-0 bottom-[30px] right-[30px] z-50 p-1">
					{currentItem ? (
						<img
							src={CloseIcon}
							alt="Close"
							onClick={() => {
								setCurrentItem(false);
							}}
						/>
					) : (
						<img
							src={AddIcon}
							alt="Open"
							onClick={() => {
								setCurrentItem(true);
							}}
						/>
					)}
				</button>
			)}
		</div>
	);
};

const GroupCard = ({
	exercise,
	exerciseIndex,
	setCurrentItem,
	deleteExercise,
	editingId,
	setEditingId,
	editingValue,
	setEditingValue,
}) => {
	return (
		<Draggable
			key={`card-${exercise.uuid}`}
			draggableId={`card-${exercise.uuid}`}
			index={exerciseIndex}
		>
			{(provided) => {
				return (
					<div
						className="bg-[#1e1e1e] rounded-xl mb-8"
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						ref={provided.innerRef}
					>
						<div className="px-4 py-4">
							<div className="flex justify-center">
								{editingId === exerciseIndex ? (
									<input
										id={`group-name-${exercise.uuid}`}
										onChange={(e) => {
											setEditingValue(e.target.value);
										}}
										className="mx-auto w-[300px] placeholder-gray-400 mt-2 form-control block px-4 py-2 text-xl font-normal bg-transparent border border-white border-opacity-20 rounded transition ease-in-out m-0 focus:outline-none text-gray-400 shadow"
										type="text"
										defaultValue={editingValue}
									/>
								) : (
									<h1
										onClick={() => {
											setEditingValue(exercise.name);
											setEditingId(exerciseIndex);
										}}
										className="text-xl text-white text-center uppercase"
									>
										{exercise.name}
									</h1>
								)}

								<button
									onClick={(event) => {
										event.stopPropagation();
										setCurrentItem({
											type: "createGroup",
											groupIndex: exerciseIndex,
										});
									}}
									className="cursor-pointer text-white ml-4"
								>
									<img src={GroupIcon} alt="Open Icon" />
								</button>
							</div>
						</div>
						<Droppable
							droppableId={exercise.uuid}
							type="insideColumn"
						>
							{(provided) => {
								return (
									<div
										className="flex"
										ref={provided.innerRef}
										{...provided.droppableProps}
									>
										<Dots color="[#ff4655]" />
										<div className="w-full">
											{exercise.exercises.map(
												(item, index) => {
													return (
														<Draggable
															key={`card-${item.uuid}`}
															draggableId={`card-${item.uuid}`}
															index={index}
														>
															{(provided) => {
																return (
																	<div
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}
																		ref={
																			provided.innerRef
																		}
																		key={
																			index
																		}
																		className={`bg-[#2f2e2e] flex w-full ${
																			index !==
																			exercise
																				.exercises
																				.length -
																				1
																				? "mb-3 rounded-tr-xl"
																				: "rounded-br-xl"
																		} `}
																		onClick={(
																			event
																		) => {
																			setCurrentItem(
																				{
																					type: "editGroupItem",
																					groupIndex:
																						exerciseIndex,
																					itemIndex:
																						index,
																				}
																			);
																		}}
																	>
																		<Dots
																			color="[#7fb77d]"
																			notRounded={
																				true
																			}
																		/>
																		<div className="flex items-center px-4 justify-between w-full">
																			<div className="flex md:items-center md:flex-row flex-col">
																				<h1 className="text-white font-medium text-lg md:w-[300px]">
																					{item
																						.exercise
																						?.name ||
																						item.exercise}
																				</h1>
																				<pre className="text-white md:font-medium text-xs sm:text-sm mt-2 md:mt-0 max-w-[200px] md:max-w-[300px] text-wrap">
																					{item
																						?.training_note
																						?.length >
																					80
																						? item?.training_note?.substring(
																								0,
																								80
																						  ) +
																						  "..."
																						: item?.training_note}
																				</pre>
																			</div>
																			<div className="flex">
																				<button
																					onClick={(
																						event
																					) => {
																						event.stopPropagation();
																						setCurrentItem(
																							{
																								type: "editGroupItem",
																								groupIndex:
																									exerciseIndex,
																								itemIndex:
																									index,
																							}
																						);
																					}}
																					className="cursor-pointer text-white ml-2"
																				>
																					<img
																						className="w-6"
																						src={
																							OpenIcon
																						}
																						alt="Open Icon"
																					/>
																				</button>
																				<button
																					onClick={(
																						event
																					) => {
																						event.stopPropagation();
																						deleteExercise(
																							"group",
																							index,
																							exerciseIndex
																						);
																					}}
																					className="ml-2 cursor-pointer"
																				>
																					<img
																						className="w-6"
																						src={
																							DeleteIcon
																						}
																						alt="Delete Icon"
																					/>
																				</button>
																			</div>
																		</div>
																	</div>
																);
															}}
														</Draggable>
													);
												}
											)}
											{provided.placeholder}
										</div>
									</div>
								);
							}}
						</Droppable>
					</div>
				);
			}}
		</Draggable>
	);
};
const SingleCard = ({
	exercise,
	exerciseIndex,
	setCurrentItem,
	deleteExercise,
}) => {
	return (
		<Draggable
			key={`card-${exercise.uuid}`}
			draggableId={`card-${exercise.uuid}`}
			index={exerciseIndex}
		>
			{(provided) => {
				return (
					<div
						className="min-h-[60px] bg-[#1e1e1e] rounded-xl flex mb-8"
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						ref={provided.innerRef}
						onClick={() => {
							setCurrentItem({
								type: "singleItem",
								exerciseIndex: exerciseIndex,
							});
						}}
					>
						<Dots color="[#7fb77d]" />
						<div className="flex items-center px-4 justify-between w-full">
							<div className="flex md:items-center md:flex-row flex-col">
								<h1 className="text-white font-medium text-lg md:w-[300px]">
									{exercise?.exercises[0].exercise?.name ||
										exercise?.exercises[0].exercise}
								</h1>
								<pre className="text-white md:font-medium text-xs sm:text-sm mt-2 md:mt-0 max-w-[200px] md:max-w-[300px] text-wrap">
									{exercise?.exercises[0].training_note
										?.length > 80
										? exercise?.exercises[0].training_note?.substring(
												0,
												80
										  ) + "..."
										: exercise?.exercises[0].training_note}
								</pre>
							</div>
							<div className="flex">
								<button
									onClick={(event) => {
										event.stopPropagation();
										setCurrentItem({
											type: "createGroup",
											groupIndex: exerciseIndex,
										});
									}}
									className="cursor-pointer text-white"
								>
									<img src={GroupIcon} alt="Open Icon" />
								</button>
								<button
									onClick={(event) => {
										event.stopPropagation();
										setCurrentItem({
											type: "singleItem",
											exerciseIndex: exerciseIndex,
										});
									}}
									className="cursor-pointer text-white ml-2"
								>
									<img
										className="w-6"
										src={OpenIcon}
										alt="Open Icon"
									/>
								</button>
								<button
									onClick={(event) => {
										event.stopPropagation();
										deleteExercise(
											"single",
											0,
											exercise.uuid
										);
									}}
									className="ml-2 cursor-pointer"
								>
									<img
										className="w-6"
										src={DeleteIcon}
										alt="Delete Icon"
									/>
								</button>
							</div>
						</div>
					</div>
				);
			}}
		</Draggable>
	);
};
const Dots = ({ color, notRounded }) => {
	return (
		<div
			className={`w-[30px] bg-${color} ${
				!notRounded && "rounded-tl-xl rounded-bl-xl"
			}  flex justify-center flex-col items-center py-8 md:py-6`}
		>
			<div className="flex items-center py-1 justify-between w-full px-1.5">
				<div className="h-1 w-1 bg-white rounded-full" />
				<div className="h-1 w-1 bg-white rounded-full" />
			</div>
			<div className="flex items-center py-1 justify-between w-full px-1.5">
				<div className="h-1 w-1 bg-white rounded-full" />
				<div className="h-1 w-1 bg-white rounded-full" />
			</div>
			<div className="flex items-center py-1 justify-between w-full px-1.5">
				<div className="h-1 w-1 bg-white rounded-full" />
				<div className="h-1 w-1 bg-white rounded-full" />
			</div>
		</div>
	);
};
const TrainingSessionDetail = () => {
	return (
		<ProtectedRoute>
			<TrainingSessionDetailComponent />
		</ProtectedRoute>
	);
};

export default TrainingSessionDetail;
