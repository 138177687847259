import Logo from "../../Assets/whiteLogo.png";
import { useForm } from "react-hook-form";
import Loader from "../../Components/Loader";
import ProtectedRoute from "../../utils/ProtectedRoute";
import { toast } from "react-toastify";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { loginUser } from "../../utils";

const LoginForm = ({ isSubmitting, errors, handleSubmit, register, signInWithToken }) => {
  return (
    <>
      <form onSubmit={handleSubmit(signInWithToken)} className="w-full">
        <div className="mb-6">
          <label className="text-xs font-medium text-white md:text-black">Please enter the login code that you've received in your email.</label>
          <input
            autoComplete="off"
            {...register("code", {
              required: "Login code is required.",
            })}
            type="text"
            className="mt-4 form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            placeholder="Enter login code"
          />

          {errors.code && <p className="mt-1 text-xs text-red-500">{errors.code.message}</p>}
          <Link to="/login" className="underline text-green-500 text-xs">
            Get another code
          </Link>
        </div>
        <button
          type="submit"
          className="inline-block px-7 py-3 bg-button text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out w-full"
        >
          {isSubmitting ? "Submitting..." : "Submit"}
        </button>
      </form>
    </>
  );
};

const CoachSignIn = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      code: "",
    },
  });

  const signInWithToken = async (data) => {
    setLoading(true);
    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/passwordless/login?loginToken=${data.code}`)
      .then(async (response) => {
        await loginUser(response.data.jwt, navigate, setLoading);
      })
      .catch((error) => {
        if (error.response.data.error.message === "token.invalid") {
          toast("Invalid Code.", {
            type: "error",
          });
        }
        setLoading(false);
      });
  };
  let formProps = {
    errors,
    handleSubmit: handleSubmit,
    register: register,
    isSubmitting,
    signInWithToken,
  };

  const isMobile = window.screen.width < 767;

  return (
    <div className="h-screen">
      <div className="block md:flex items-center">
        <div className="w-full md:w-1/2 min-h-screen flex flex-col md:flex-row items-center justify-center bg-[#111111]">
          <img alt="Logo" src={Logo} className="w-1/2" />

          <div className="md:hidden mt-4">
            <h5 className="text-center text-3xl font-semibold text-white">Fitness Dashboard</h5>

            <div className="w-w-11/12	mx-auto mt-12">{isMobile && <LoginForm {...formProps} />}</div>
          </div>
        </div>
        <div className="hidden md:flex justify-center flex-col items-center w-1/2">
          <h1 className="text-4xl mb-12 font-semibold">Fitness Dashboard</h1>
          <div className="w-10/12 lg:w-1/2 mx-auto">{!isMobile && <LoginForm {...formProps} />}</div>
        </div>
      </div>
      <Loader loading={loading} />
    </div>
  );
};

const PageComponent = () => {
  return (
    <ProtectedRoute>
      <CoachSignIn />
    </ProtectedRoute>
  );
};
export default PageComponent;
