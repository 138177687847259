import cookie from "react-cookies";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";
import { useState } from "react";
function ordinal(number) {
	const english_ordinal_rules = new Intl.PluralRules("en", {
		type: "ordinal",
	});
	const suffixes = {
		one: "st",
		two: "nd",
		few: "rd",
		other: "th",
	};

	const suffix = suffixes[english_ordinal_rules.select(number)];
	return number + suffix;
}
const monthNames = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];
export const dateFormatHandler = (date) => {
	let _date = date ? new Date(date).toDateString().split(" ") : new Date();

	return (
		monthNames[new Date(date).getMonth()] +
		" " +
		ordinal(_date[2][0] === "0" ? _date[2][1] : _date[2]) +
		" " +
		_date[3]
	);
};
export const formattedDate = (date, display) => {
	if (display) {
		return moment(date).format("MMM DD, YYYY");
	}
	return moment(date).format("YYYY-MM-DD");
};
export const restructureHabit = (type, value) => {
	return type === "save"
		? value.split(" ").join("_").trim()
		: value.split("_").join(" ").trim();
};
export const logout = async () => {
	cookie.remove("profile", { path: "/" });
	cookie.remove("role", { path: "/" });
	cookie.remove("token", { path: "/" });
	cookie.remove("userId", { path: "/" });
	setTimeout(() => {
		window.location.reload();
	}, 1000);
};
export const sortWithKey = (currentArray, value) => {
	return (
		currentArray?.sort((a, b) => new Date(b[value]) - new Date(a[value])) ||
		[]
	);
};
export const daysPassed = (date, days) => {
	let _date = moment(date);
	return moment().diff(_date, "days") > days;
};

export const loginUser = async (jwt, navigate, setLoading) => {
	if (setLoading) {
		setLoading(true);
	}
	cookie.save("token", jwt, {
		path: "/",
		maxAge: 15550000,
	});
	await axios
		.get(process.env.REACT_APP_BACKEND_URL + "/api/getProfile", {
			headers: {
				Authorization: `Bearer ${jwt}`,
			},
		})
		.then((res) => {
			cookie.save("role", res.data.role, { path: "/", maxAge: 15550000 });
			cookie.save("userId", res.data.id, { path: "/", maxAge: 15550000 });
			if (res.data.role === "Coach") {
				cookie.save("profile", JSON.stringify(res.data.coach), {
					path: "/",
					maxAge: 15550000,
				});
				navigate("/coach/dashboard");
			} else {
				cookie.save("profile", JSON.stringify(res.data.client), {
					path: "/",
					maxAge: 15550000,
				});
				navigate(`/member/${res.data.client.uuid}`);
			}
		})
		.catch((err) => {
			toast("Unable to login, Plese try again.", {
				type: "error",
			});
			if (setLoading) {
				setLoading(false);
			}
			navigate("/login");
		});
};
export const returnCompletionDate = (session) => {
	if (session.session_data.completeTime) {
		let date1 = moment(new Date());
		let date2 = moment(session.session_data.completeTime);
		var diffInDays = date1.diff(date2, "days");

		if (diffInDays && diffInDays >= 1) {
			return diffInDays + " " + "day(s) ago";
		} else {
			const duration = moment.duration(
				moment().diff(session.session_data.completeTime)
			);
			const hoursAgo = Math.floor(duration.asHours());
			const minutesAgo = Math.floor(duration.asMinutes());
			if (hoursAgo < 1) {
				return `${minutesAgo} minute(s) ago`;
			}
			return `${hoursAgo} hour(s) ago`;
		}
	}
};

export const formatEmail = (email) => {
	return email.charAt(0).toLowerCase() + email.slice(1);
};

export const usePagination = (items, initialCount = 10, step = 10, sorting) => {
	const [count, setCount] = useState(initialCount);
	let sortedItems = [...items];
	if (sorting) {
		sortedItems = [...items].sort((a, b) => {
			const timeA = a.session_data.completeTime
				? new Date(a.session_data.completeTime)
				: 0;
			const timeB = b.session_data.completeTime
				? new Date(b.session_data.completeTime)
				: 0;
			return timeB - timeA;
		});
	}

	const loadMore = () => {
		if (count < sortedItems.length) {
			setCount(count + step);
		}
	};

	const loadLess = () => {
		if (count > initialCount) {
			setCount(count - step);
		}
	};

	const paginatedItems = sortedItems.slice(0, count);

	return {
		paginatedItems,
		loadMore,
		loadLess,
		hasMore: count < sortedItems.length,
		hasLess: count > initialCount,
	};
};
