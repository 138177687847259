import { useState } from "react";
import Table from "../Table";
import DeleteIcon from "../../Assets/delete.svg";
import EditIcon from "../../Assets/edit.svg";
import AddIcon from "../../Assets/addIcon.svg";
import LinksImage from "../../Assets/links.svg";
import { formattedDate } from "../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import LinksModal from "../LinksModal";
const LinksTable = ({
	tableData,
	fetchData,
	setFetchLoader,
	clientId,
	coachId,
}) => {
	const [currentItem, setCurrentItem] = useState(null);
	const deleteEntry = async (id) => {
		if (window.confirm(`Are you sure to delete`)) {
			setFetchLoader(true);
			axios
				.post(
					process.env.REACT_APP_BACKEND_URL +
						`/api/link/delete/${id}`,
					{
						clientId: clientId,
						coachId: coachId,
					}
				)
				.then((res) => {
					fetchData();
					toast("Record deleted.", {
						type: "success",
					});
				})
				.catch((err) => {
					let _message = err.response.data.error.message;
					if (_message === "noBelonging") {
						toast("Record does not belong to you.", {
							type: "error",
						});
					} else {
						toast("Something went wrong while updating record.", {
							type: "error",
						});
					}
					setFetchLoader(false);
				});
		}
	};
	return (
		<div className="w-full px-8 relative pt-12">
			<div className="flex items-center flex-col absolute top-0 -mt-12">
				<div className="h-12 w-12 rounded bg-[#6f4bdb] shadow flex items-center justify-center">
					<img alt="Log Book" src={LinksImage} className="w-8 h-8" />
				</div>
				<p className="text-white text-lg font-bold mt-1">Links</p>
			</div>
			<button
				onClick={() => {
					setCurrentItem(true);
				}}
				className="float-right"
				type="button"
			>
				<img alt="Add" src={AddIcon} className="w-8" />
			</button>

			<Table
				headerColor="#1e1e1e"
				pageSize={5}
				columns={[
					{
						id: "Title",
						header: () => "Title",
						accessorFn: (row) => `${row.title}`,
						width: 90,
						cell: (info) => {
							return (
								<span className="whitespace-nowrap py-4 text-sm text-white text-right">
									{info.row.original.title}
								</span>
							);
						},
					},
					{
						id: "link",
						header: () => "Link",
						accessorFn: (row) => `${row.link}`,
						width: 90,
						cell: (info) => {
							return (
								<a
									target="_blank"
									rel="noreferrer"
									href={info.row.original.link}
									className="whitespace-nowrap py-4 text-sm text-blue-500 text-right"
								>
									{info.row.original.link}
								</a>
							);
						},
					},
					{
						id: "createdAt",
						header: () => "Date",
						accessorFn: (row) => `row.createdAt`,
						cell: (info) => {
							return (
								<div className="w-[100px]">
									<span className="whitespace-nowrap py-4 text-sm text-white text-right">
										{formattedDate(
											new Date(
												info.row.original.createdAt
											),
											true
										)}
									</span>
								</div>
							);
						},
					},
					{
						id: "actions",
						header: () => "Action",
						cell: (info) => {
							return (
								<div className="w-[200px]">
									<div className="flex">
										<div className="ml-4 cursor-pointer">
											<img
												src={EditIcon}
												alt="Edit Icon"
												onClick={() => {
													setCurrentItem({
														...info.row.original,
													});
												}}
											/>
										</div>
										<div
											className="ml-4 cursor-pointer"
											onClick={() => {
												deleteEntry(
													info.row.original.id
												);
											}}
										>
											<img
												src={DeleteIcon}
												alt="Delete Icon"
											/>
										</div>
									</div>
								</div>
							);
						},
					},
				]}
				data={tableData}
			/>
			{currentItem && (
				<LinksModal
					setFetchLoader={setFetchLoader}
					currentItem={currentItem}
					setCurrentItem={setCurrentItem}
					fetchData={fetchData}
					tableData={tableData}
					clientId={clientId}
					coachId={coachId}
				/>
			)}
		</div>
	);
};

export default LinksTable;
