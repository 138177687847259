import moment from "moment";
import { useState, useEffect } from "react";
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	Area,
	ResponsiveContainer,
	AreaChart,
} from "recharts";
const Graph = ({ calculation, activeGraph }) => {
	const formatXAxis = (tickItem) => {
		return moment(tickItem).format("MM/DD");
	};
	function formatLargeNumber(num) {
		if (num < 1000) {
			return num.toString();
		} else {
			let formattedNum = (num / 1000).toFixed(1);
			if (formattedNum.endsWith(".0")) {
				formattedNum = formattedNum.slice(0, -2);
			}
			return formattedNum + "K";
		}
	}
	const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
	useEffect(() => {
		const handleResize = () => {
			setIsDesktop(window.innerWidth > 768);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<>
			{activeGraph === "steps" ? (
				<>
					{calculation.steps.items.length ? (
						<ResponsiveContainer>
							<LineChart data={calculation.steps.items}>
								{isDesktop && <Tooltip />}
								<XAxis
									dataKey="date"
									tickFormatter={formatXAxis}
									ticks={[
										calculation.steps.items[0].date,
										calculation.steps.items[
											calculation.steps.items.length - 1
										].date,
									]}
								/>
								<YAxis
									tickFormatter={formatLargeNumber}
									domain={[
										calculation.steps.min,
										calculation.steps.max,
									]}
									ticks={[
										calculation.steps.min,
										calculation.steps.max,
									]}
								/>
								<Legend />
								<Line
									type="monotone"
									dot={false}
									dataKey="steps"
									strokeWidth="3"
									stroke="#d3b065"
								/>
							</LineChart>
						</ResponsiveContainer>
					) : (
						<h1 className="text-white text-sm xl:text-2xl  text-center md:text-left">
							Not enough data in the selected date range
						</h1>
					)}
				</>
			) : activeGraph === "weight" ? (
				<>
					{calculation.weight.items.length ? (
						<ResponsiveContainer>
							<LineChart data={calculation.weight.items}>
								{isDesktop && <Tooltip />}
								<XAxis
									dataKey="date"
									tickFormatter={formatXAxis}
									ticks={[
										calculation.weight.items[0].date,
										calculation.weight.items[
											calculation.weight.items.length - 1
										].date,
									]}
								/>
								<YAxis
									tickFormatter={(number) => {
										return Math.round(number * 10) / 10;
									}}
									ticks={[
										calculation.weight.min,
										calculation.weight.max,
									]}
									domain={[
										calculation.weight.min,
										calculation.weight.max,
									]}
								/>
								<Legend />
								<Line
									type="monotone"
									dot={false}
									dataKey="weight"
									strokeWidth="3"
									stroke="#9370db"
								/>
							</LineChart>
						</ResponsiveContainer>
					) : (
						<h1 className="text-white text-sm xl:text-2xl text-center md:text-left">
							Not enough data in the selected date range
						</h1>
					)}
				</>
			) : (
				<>
					{calculation.habit.items.length ? (
						<ResponsiveContainer>
							<AreaChart data={calculation.habit.items}>
								<XAxis
									dataKey="date"
									tickFormatter={formatXAxis}
									ticks={[
										calculation.habit.items[0].date,
										calculation.habit.items[
											calculation.habit.items.length - 1
										].date,
									]}
								/>
								<YAxis domain={[1, 5]} ticks={[1, 5]} />
								<Tooltip />
								<Area
									type="monotone"
									dataKey="habit"
									stroke="#7fb77d"
									fill="#7fb77d"
								/>
							</AreaChart>
						</ResponsiveContainer>
					) : (
						<h1 className="text-white text-sm xl:text-2xl text-center md:text-left">
							Not enough data in the selected date range
						</h1>
					)}
				</>
			)}
		</>
	);
};
export default Graph;
