import Table from "../Table";
import DeleteIcon from "../../Assets/delete.svg";
import EditIcon from "../../Assets/edit.svg";
import AddIcon from "../../Assets/addIcon.svg";
import HabitsModal from "../HabitsModal";
import HabitIcon from "../../Assets/habit.png";
import IncreaseArrow from "../../Assets/increase.svg";
import DecreaseArrow from "../../Assets/decrease.svg";
import Info from "../../Assets/info.svg";
import EqualSvg from "../../Assets/equal.svg";
import { formattedDate } from "../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import moment from "moment";
import { useEffect, useState } from "react";

const HabitsTable = ({
	tableData,
	current_habit,
	displayHabit,
	setFetchLoader,
	habitsLogData,
	currentItem,
	setCurrentItem,
	setTableData,
}) => {
	const params = useParams();
	const [duration, setDuration] = useState(0);
	const deleteEntry = async (id) => {
		if (window.confirm(`Are you sure to delete`)) {
			setFetchLoader(true);
			axios
				.post(
					process.env.REACT_APP_BACKEND_URL +
						`/api/habit/delete/${id}`,
					{
						clientId: params.id,
					}
				)
				.then((res) => {
					let _tableData = [...tableData];
					_tableData = _tableData.filter(
						(item) => item.id !== res.data.id
					);
					setTableData(_tableData);
					setFetchLoader(false);
					toast("Record deleted.", {
						type: "success",
					});
				})
				.catch((err) => {
					let _message = err.response.data.error.message;
					if (_message === "noBelonging") {
						toast("Record does not belong to you.", {
							type: "error",
						});
					} else {
						toast("Something went wrong while updating record.", {
							type: "error",
						});
					}
					setFetchLoader(false);
				});
		}
	};
	useEffect(() => {
		if (tableData?.length) {
			let date1 = moment(new Date());
			let date2 = moment(tableData[tableData.length - 1].date);
			var diffInDays = date1.diff(date2, "days");
			if (diffInDays >= 1) {
				setDuration(diffInDays);
			}
		}
	}, [tableData]);

	return (
		<div className="w-full px-8 pt-12">
			<div className="items-center relative">
				<div className="flex absolute top-0 -mt-[6.3rem] items-end">
					<div className="flex items-center flex-col">
						<div className="h-12 w-12 rounded bg-[#6f4bdb] shadow flex items-center justify-center">
							<img
								alt="Habit"
								src={HabitIcon}
								className="w-8 h-8"
							/>
						</div>
						<p className="text-white text-lg font-bold mt-1">
							{" "}
							{displayHabit}
						</p>
					</div>

					<p className="ml-2 text-xs text-white mb-1">
						{duration ? duration + " days" : ""}
					</p>
					{habitsLogData?.length ? (
						<div>
							<Tooltip
								openOnClick={true}
								id="habit-tooltip"
							></Tooltip>
							<img
								data-tooltip-id="habit-tooltip"
								data-tooltip-content={habitsLogData[0].note}
								alt="Habit"
								src={Info}
								className="w-5 h-5 ml-2"
							/>
						</div>
					) : (
						<div />
					)}
				</div>
			</div>
			<button
				onClick={() => {
					if (displayHabit === "Habit") {
						window.alert(
							"Please consult your coach for tracking habits."
						);
					} else {
						setCurrentItem(true);
					}
				}}
				className="float-right"
				type="button"
			>
				<img alt="Add" src={AddIcon} className="w-8" />
			</button>

			<Table
				headerColor="#1e1e1e"
				pageSize={5}
				columns={[
					{
						id: "habit",
						header: () => "Habit",
						accessorFn: (row) => `${row.habit}`,
						width: 90,
						cell: (info) => {
							let prevValue =
								info.row.index !== tableData.length - 1 &&
								parseFloat(info.row.original.habit);
							let nextValue =
								info.row.index !== tableData.length - 1 &&
								parseFloat(tableData[info.row.index + 1].habit);
							let difference =
								prevValue &&
								nextValue &&
								(prevValue - nextValue).toFixed(0);
							return (
								<div className="w-[100px] relative">
									<div className="items-center absolute right-[-10px] -mt-2">
										{tableData.length > 1 &&
											info.row.index !==
												tableData.length - 1 && (
												<div>
													{prevValue > nextValue ? (
														<div className="flex items-end">
															<img
																alt="Increase"
																src={
																	IncreaseArrow
																}
																className="w-10"
															/>
															<span className="text-white text-xs">
																+{difference}
															</span>
														</div>
													) : prevValue ===
													  nextValue ? (
														<div className="flex items-end">
															<img
																alt="Equal"
																src={EqualSvg}
																className="w-8"
															/>
															<span className="text-white text-xs opacity-0">
																+{difference}
															</span>
														</div>
													) : (
														<div className="flex items-end">
															<img
																alt="Decrease"
																src={
																	DecreaseArrow
																}
																className="w-10 "
															/>
															<span className="text-white text-xs">
																{difference}
															</span>
														</div>
													)}
												</div>
											)}
									</div>
									<span className="whitespace-nowrap py-4 text-sm text-white text-right">
										{info.row.original.habit} / 5
									</span>
								</div>
							);
						},
					},
					{
						id: "date",
						header: () => "Date",
						width: 100,
						accessorFn: (row) => `${formattedDate(row.date, true)}`,
						cell: (info) => {
							return (
								<span className="whitespace-nowrap py-4 text-sm text-white text-right">
									{formattedDate(
										info.row.original.date,
										true
									)}
								</span>
							);
						},
					},
					{
						id: "actions",
						header: () => "Action",
						width: "50px",
						cell: (info) => {
							return (
								<div className="w-[100px]">
									<div className="flex">
										<div className="ml-4 cursor-pointer">
											<img
												src={EditIcon}
												alt="Edit Icon"
												onClick={() => {
													setCurrentItem({
														...info.row.original,
													});
												}}
											/>
										</div>
										<div
											className="ml-4 cursor-pointer"
											onClick={() => {
												deleteEntry(
													info.row.original.id
												);
											}}
										>
											<img
												src={DeleteIcon}
												alt="Delete Icon"
											/>
										</div>
									</div>
								</div>
							);
						},
					},
				]}
				data={tableData}
			/>
			{currentItem && (
				<HabitsModal
					title={displayHabit}
					currentItem={currentItem}
					setCurrentItem={setCurrentItem}
					current_habit={current_habit}
					setTableData={setTableData}
					tableData={tableData}
				/>
			)}
		</div>
	);
};

export default HabitsTable;
