import Close from "../../Assets/close.svg";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { LoaderButton } from "../Loader";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import Table from "../../Components/Table";
import HistoryDrawer from "../../Components/HistoryDrawer";
import Dumbell from "../../Assets/dumbell-black.svg";
import VideoIcon from "../../Assets/camera.svg";
import Notes from "../../Assets/notes.svg";
import { formattedDate, returnCompletionDate } from "../../utils";
const TrainingExerciseDrawer = ({ trainingSession, currentItem, setCurrentItem, setTrainingSession, exerciseDatabase, historyItems }) => {
  const [loading, setLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [tags, setTags] = useState([]);
  const [currentType, setCurrentType] = useState("database");
  const [exercisevalues, setExerciseValues] = useState([]);
  const [exerciseDetails, setExerciseDetails] = useState(null);
  const [currentHistoryItems, setCurrentHistoryItems] = useState([]);
  const [currentSessionItem, setCurrentSessionItem] = useState(null);
  const [note, setNote] = useState(false);
  const textAreaRef = useRef(null);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      group_name: "",
      training_note: "",
      selected_exercise: "",
      selected_tags: [],
      coach_notes: "",
    },
  });

  let selectedTags = watch("selected_tags");
  let selectedExercise = watch("selected_exercise");
  let watchTrainingNote = watch("training_note");

  const sortAsc = (data, key) => {
    // Create a new array using slice() to avoid modifying the original data
    const sortedData = data.slice().sort((a, b) => (a[key].toUpperCase() < b[key].toUpperCase() ? -1 : a[key].toUpperCase() > b[key].toUpperCase() ? 1 : 0));

    return sortedData;
  };
  useEffect(() => {
    if (currentItem) {
      setTimeout(() => {
        setOpenDrawer(true);
      }, 200);
    }

    // Setting up tags
    const tagsSet = new Set();
    exerciseDatabase.forEach((obj) => {
      if (obj.tags) {
        const tags = obj.tags.split(",");
        tags.forEach((tag) => {
          const trimmedTag = tag.trim();
          tagsSet.add(trimmedTag);
        });
      }
    });

    const tagsArray = sortAsc(
      Array.from(tagsSet).map((tag) => {
        return { label: tag, value: tag };
      }),
      "label"
    );
    let _exerciseValues = exerciseDatabase.map((exercise) => {
      return {
        label: exercise.name,
        value: exercise.id,
      };
    });
    setExerciseValues(sortAsc(_exerciseValues, "label"));
    setTags(tagsArray);

    // If exercise is only one and editing that exercise
    if (currentItem?.type === "singleItem") {
      let _currentItem = trainingSession.session_data.items[currentItem.exerciseIndex].exercises[0];
      if (_currentItem.exercise.id) {
        setValue("training_note", _currentItem.training_note || "");
        setValue("coach_notes", _currentItem.coach_notes || "");
        setValue(
          "selected_exercise",
          _exerciseValues.find((item) => item.value === _currentItem.exercise.id)
        );
        setValue("selected_tags", _currentItem.tags || []);
      } else {
        setCurrentType("custom");
        setValue("name", _currentItem.exercise);
        setValue("training_note", _currentItem.training_note || "");
        setValue("coach_notes", _currentItem.coach_notes || "");
      }
    } else if (currentItem?.type === "createGroup") {
      let _currentItem = trainingSession.session_data.items[currentItem.groupIndex];
      setValue("group_name", _currentItem.name);
    } else if (currentItem?.type === "editGroupItem") {
      let _currentItem = trainingSession.session_data.items[currentItem.groupIndex];
      let exerciseItem = _currentItem.exercises[currentItem.itemIndex];
      setValue("group_name", _currentItem.name);
      if (exerciseItem.exercise.id) {
        setValue("training_note", exerciseItem.training_note || "");
        setValue("coach_notes", exerciseItem.coach_notes || "");
        setValue(
          "selected_exercise",
          _exerciseValues.find((item) => item.value === exerciseItem.exercise.id)
        );
        setValue("selected_tags", exerciseItem.tags || []);
      } else {
        setCurrentType("custom");
        setValue("name", exerciseItem.exercise);
        setValue("training_note", exerciseItem.training_note || "");
        setValue("coach_notes", exerciseItem.coach_notes || "");
      }
    }
    // If any item in group is being added
    // eslint-disable-next-line
  }, [currentItem]);

  const closeDrawer = () => {
    setOpenDrawer(false);
    setTimeout(() => {
      setCurrentItem(null);
    }, 200);
  };

  useEffect(() => {
    if (!selectedTags.length) {
      setExerciseValues(
        sortAsc(
          exerciseDatabase.map((exercise) => ({
            label: exercise.name,
            value: exercise.id,
          })),
          "label"
        )
      );
    } else {
      const filteredExercises = exerciseDatabase.filter((exercise) => {
        const exerciseTags = exercise?.tags?.split(",") || [];
        const exerciseTagsSet = new Set(exerciseTags.map((tag) => tag.trim()));
        return selectedTags.every((tag) => exerciseTagsSet.has(tag.label));
      });

      setExerciseValues(
        sortAsc(
          filteredExercises.map((exercise) => ({
            label: exercise.name,
            value: exercise.id,
          })),
          "label"
        )
      );
    }
  }, [selectedTags, exerciseDatabase]);

  const resetForm = () => {
    reset({
      name: "",
      group_name: "",
      training_note: "",
      selected_exercise: "",
      selected_tags: [],
      coach_notes: "",
    });
  };
  const createOrUpdateTrainingSession = (values) => {
    setLoading(true);
    let _trainingSession = trainingSession.session_data
      ? JSON.parse(JSON.stringify(trainingSession.session_data))
      : {
          items: [],
        };
    const requestData = {
      id: trainingSession.id,
      session_data: _trainingSession,
    };

    const addItemToSession = (item) => {
      requestData.session_data.items.push(item);
    };

    const updateTrainingSession = () => {
      axios
        .post(process.env.REACT_APP_BACKEND_URL + "/api/training-sessions/updateSessionData/", requestData)
        .then((response) => {
          toast("Training session updated", {
            type: "success",
            position: "top-center",
          });
          resetForm();
          setTrainingSession(response.data);
        })
        .catch((err) => {
          toast("Unable to update session..", {
            type: "error",
            position: "top-center",
          });
        })
        .finally(() => {
          setLoading(false);
          closeDrawer();
        });
    };

    if (!currentItem?.type) {
      const addedItem = {
        name: "",
        uuid: uuidv4(),
        exercises: [
          {
            uuid: uuidv4(),
            training_note: values.training_note,
            coach_notes: values.coach_notes,
            exercise:
              currentType === "database" && values.selected_exercise
                ? exerciseDatabase.find((item) => item.id === values.selected_exercise.value)
                : values.name,
            tags: values.selected_tags,
          },
        ],
      };
      addItemToSession(addedItem);
    } else if (currentItem.type === "singleItem") {
      const items = [...trainingSession?.session_data.items];
      let _uuid = items[currentItem.exerciseIndex].exercises[0].uuid;
      items[currentItem.exerciseIndex].exercises[0] = {
        uuid: _uuid,
        training_note: values.training_note,
        coach_notes: values.coach_notes,
        exercise:
          currentType === "database" && values.selected_exercise ? exerciseDatabase.find((item) => item.id === values.selected_exercise.value) : values.name,
        tags: values.selected_tags,
      };
      requestData.session_data.items = items;
    } else if (currentItem.type === "createGroup") {
      const mainItem = {
        ...requestData.session_data.items[currentItem.groupIndex],
      };
      mainItem.name = values.group_name;
      mainItem.exercises.push({
        uuid: uuidv4(),
        training_note: values.training_note,
        coach_notes: values.coach_notes,
        exercise:
          currentType === "database" && values.selected_exercise ? exerciseDatabase.find((item) => item.id === values.selected_exercise.value) : values.name,
        tags: values.selected_tags,
      });
      requestData.session_data.items[currentItem.groupIndex] = mainItem;
    } else if (currentItem.type === "editGroupItem") {
      const items = [...trainingSession?.session_data.items];
      let _uuid = items[currentItem.groupIndex].exercises[currentItem.itemIndex].uuid;
      items[currentItem.groupIndex].exercises[currentItem.itemIndex] = {
        uuid: _uuid,
        training_note: values.training_note,
        coach_notes: values.coach_notes,
        exercise:
          currentType === "database" && values.selected_exercise ? exerciseDatabase.find((item) => item.id === values.selected_exercise.value) : values.name,
        tags: values.selected_tags,
      };
      requestData.session_data.items = items;
    }

    updateTrainingSession();
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "transparent",
      color: "#ccc",
      height: "46px",
      border: "1px solid rgba(255, 255, 255, .2)",
    }),
    option: (styles) => {
      return {
        ...styles,
        backgroundColor: "white",
        color: "rgb(156 163 175)",
        cursor: "pointer",
      };
    },
    singleValue: (styles) => {
      return { ...styles, color: "white" };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "rgb(134 239 172)",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "rgb(22 101 52);",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "rgb(22 101 52)",
      ":hover": {
        backgroundColor: "rgb(134 239 172)",
        color: "rgb(22 101 52)",
      },
    }),
  };
  const changeExerciseType = (val) => {
    setCurrentType(val);
    setExerciseDetails(null);
    setNote(false);
    setCurrentHistoryItems([]);
    setCurrentSessionItem(null);
    reset({
      name: "",
      training_note: "",
      selected_exercise: "",
      selected_tags: [],
      coach_notes: "",
    });
  };
  let showNameField = currentItem.type === "createGroup";

  useEffect(() => {
    if (selectedExercise && selectedExercise.value) {
      let _selectedExercise = exerciseDatabase.find((item) => item.id === selectedExercise.value);

      if (historyItems?.length) {
        let _currentHistoryItems = historyItems.filter((item) => item.exercise_database.id === _selectedExercise.id);
        if (_currentHistoryItems.length) {
          setCurrentHistoryItems(_currentHistoryItems);
        } else {
          setCurrentHistoryItems([]);
        }
      }

      setExerciseDetails(_selectedExercise);
    }
    // eslint-disable-next-line
  }, [selectedExercise]);
  useEffect(() => {
    let _textArea = document.getElementById("training_note");
    if (_textArea) {
      _textArea.style.height = "auto";
      _textArea.style.height = _textArea.scrollHeight + "px";
    }
  }, [watchTrainingNote]);
  console.log("currentSessionItem", currentSessionItem);
  return (
    <div className={`fixed inset-0 z-20 bg-black bg-opacity-50`}>
      <div
        className={` w-full md:w-[100%] bg-[#1e1e1e] h-screen overflow-y-scroll right-0 absolute shadow px-4 ease-in-out transition ${
          openDrawer ? "" : "translate-x-full"
        } `}
      >
        <button
          onClick={() => {
            closeDrawer();
          }}
        >
          <img src={Close} alt="Close" className="w-8 absolute right-[10px] top-[20px]" />
        </button>
        <h1 className="text-center text-white font-Medium text-2xl">{currentItem?.id ? "Edit" : "Add"} exercise</h1>
        <form className="mt-8 flex flex-col-reverse lg:flex-row justify-between" onSubmit={handleSubmit(createOrUpdateTrainingSession)}>
          <div className="lg:w-[45%] w-full pb-4 xl:pb-0">
            <div className="mb-6 w-full">
              {showNameField && (
                <div className="mb-6 w-full">
                  <label className="text-white font-medium">Group Name</label>
                  <input
                    required
                    {...register("group_name", {
                      required: "Required.",
                    })}
                    type="text"
                    className="placeholder-gray-400 mt-2 form-control block w-full px-4 py-2 text-xl font-normal bg-transparent border border-white border-opacity-20 rounded transition ease-in-out m-0 focus:outline-none text-gray-400 shadow"
                  />
                  {errors.group_name && <p className="mt-1 text-xs text-red-500">{errors.group_name.message}</p>}
                </div>
              )}
              <div className="flex items-center">
                <input
                  onChange={(e) => {
                    changeExerciseType(e.target.value);
                  }}
                  checked={currentType === "database"}
                  type="radio"
                  id="database"
                  name="import_from"
                  value="database"
                />

                <label htmlFor="database" className="text-white ml-2">
                  Import from database
                </label>
              </div>
              <div className="flex items-center">
                <input
                  onChange={(e) => {
                    changeExerciseType(e.target.value);
                  }}
                  checked={currentType === "custom"}
                  type="radio"
                  id="custom"
                  name="import_from"
                  value="custom"
                />
                <label htmlFor="custom" className="text-white ml-2">
                  Add custom exercise
                </label>
              </div>
            </div>

            {currentType === "database" && (
              <>
                <div className="mb-6 w-full">
                  <label className="text-white font-medium">Filter by tag</label>
                  <Select
                    onChange={(val) => {
                      setValue("selected_tags", val);
                    }}
                    options={tags}
                    isMulti
                    className="mt-2 py-2"
                    styles={colourStyles}
                    value={selectedTags}
                  />
                </div>
                <div className="mb-6 w-full">
                  <label className="text-white font-medium">Select exercise</label>
                  <Select
                    {...register("selected_exercise", {
                      required: "Required.",
                    })}
                    onChange={(val) => {
                      setValue("selected_exercise", val);
                    }}
                    value={selectedExercise}
                    options={exercisevalues}
                    className="mt-2 py-2"
                    styles={colourStyles}
                  />
                  {errors.selected_exercise && <p className="mt-1 text-xs text-red-500">{errors.selected_exercise.message}</p>}
                  {exerciseDetails && (
                    <div>
                      <div className="flex items-center">
                        {exerciseDetails.youtube_url && (
                          <a href={exerciseDetails.youtube_url} target="_blank" rel="noreferrer">
                            <img className="w-6" src={VideoIcon} alt="video" />
                          </a>
                        )}

                        {exerciseDetails.text_note && (
                          <button
                            type="button"
                            onClick={() => {
                              setNote(!note);
                            }}
                          >
                            <img className="w-6 ml-1" src={Notes} alt="Notes" />
                          </button>
                        )}
                      </div>
                      {note && <pre className="text-white mt-1 text-xs text-wrap">{exerciseDetails.text_note}</pre>}
                    </div>
                  )}
                </div>
              </>
            )}
            {currentType === "custom" && (
              <div className="mb-6 w-full">
                <label className="text-white font-medium">Name</label>
                <input
                  required
                  {...register("name", {
                    required: "Required.",
                  })}
                  type="text"
                  className="placeholder-gray-400 mt-2 form-control block w-full px-4 py-2 text-xl font-normal bg-transparent border border-white border-opacity-20 rounded transition ease-in-out m-0 focus:outline-none text-gray-400 shadow"
                />
                {errors.name && <p className="mt-1 text-xs text-red-500">{errors.name.message}</p>}
              </div>
            )}
            <div className="mb-6 w-full">
              <label className="text-white font-medium">Training Notes</label>
              <textarea
                rows={6}
                id="training_note"
                ref={textAreaRef}
                {...register("training_note", {
                  required: "Required.",
                })}
                type="text"
                className="placeholder-gray-400 mt-2 form-control block w-full px-4 py-2 text-xl font-normal bg-transparent border border-white border-opacity-20 rounded transition ease-in-out m-0 focus:outline-none text-gray-400 shadow"
              />
              {errors.training_note && <p className="mt-1 text-xs text-red-500">{errors.training_note.message}</p>}
            </div>

            <button
              type="submit"
              className="inline-block px-7 py-2 bg-button text-white font-medium text-sm leading-snug rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out mx-auto"
              data-mdb-ripple="true"
              data-mdb-ripple-color="light"
            >
              {loading ? <LoaderButton /> : currentItem?.type ? "Edit" : "Create"}
            </button>
          </div>
          <div className="lg:w-[50%] w-full">
            {" "}
            <div>
              <h1 className="text-white font-medium mb-2">Exercise History</h1>
              {currentHistoryItems.length ? (
                <Table
                  hideSearch={true}
                  pageSize={5}
                  headerColor="#1e1e1e"
                  columns={[
                    {
                      id: "result",
                      header: () => "Result",
                      accessorFn: (row) => `${row.result}`,
                      cell: (info) => {
                        return <span className="whitespace-nowrap py-4 text-sm text-white text-right">{info.row.original.result}</span>;
                      },
                    },
                    {
                      id: "createdAt",
                      header: () => "Tracked on",
                      accessorFn: (row) => `${formattedDate(row.createdAt, true)}`,
                      cell: (info) => {
                        return (
                          <span className="whitespace-nowrap py-4 text-sm text-white text-right">
                            {returnCompletionDate(info.row.original.training_session)}
                          </span>
                        );
                      },
                    },
                    {
                      id: "session",
                      header: () => "Session",
                      cell: (info) => {
                        return (
                          <div className="flex">
                            <button
                              onClick={() => {
                                setCurrentSessionItem(info.row.original);
                              }}
                              className="cursor-pointer text-white"
                            >
                              <img src={Dumbell} alt="Open Icon" />
                            </button>
                          </div>
                        );
                      },
                    },
                  ]}
                  data={currentHistoryItems}
                />
              ) : (
                <div className="min-h-[200px]">
                  <h1 className="text-3xl text-white mt-8">No History found for this exercise.</h1>
                </div>
              )}

              <label className="text-white font-medium">Coach Notes</label>
              <textarea
                id="coach_notes"
                {...register("coach_notes")}
                rows={6}
                type="text"
                className="placeholder-gray-400 mt-2 form-control block w-full px-4 py-2 text-xl font-normal bg-transparent border border-white border-opacity-20 rounded transition ease-in-out m-0 focus:outline-none text-gray-400 shadow"
              />
            </div>
          </div>
        </form>
      </div>
      {currentSessionItem && <HistoryDrawer open={currentSessionItem} setOpen={setCurrentSessionItem} />}
    </div>
  );
};
export default TrainingExerciseDrawer;
