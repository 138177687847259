import { useEffect, useState } from "react";
import Header from "../../Components/Header";
import ProtectedRoute from "../../utils/ProtectedRoute";
import { useLocation, useParams } from "react-router-dom";
import TrainingSessionDrawer from "../../Components/TrainingSessionDrawer";
import AddIcon from "../../Assets/add.svg";
import CloseIcon from "../../Assets/close.svg";
import axios from "axios";
import EditIcon from "../../Assets/edit.svg";
import DeleteIcon from "../../Assets/delete.svg";
import OpenIcon from "../../Assets/open.svg";
import CopyIcon from "../../Assets/copy.svg";
import { toast } from "react-toastify";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";
import CopySessionDrawer from "../../Components/CopySessionDrawer";
import ResultDrawer from "../../Components/ResultDrawer";
import { useDispatch } from "react-redux";
import Navigation from "../../Components/Navigation";
import HistoryIcon from "../../Assets/history.svg";
import Repeat from "../../Assets/repeat.svg";
import { returnCompletionDate, usePagination } from "../../utils";
import { GrNotes } from "react-icons/gr";
import ClientNotesDrawer from "../../Components/ClientNotesDrawer";

const SessionsComponent = ({ user, role }) => {
	const router = useLocation();
	const [currentItem, setCurrentItem] = useState(false);
	const [sessions, setSessions] = useState({
		draft: [],
		completed: [],
		live: [],
	});
	const [loading, setLoading] = useState(true);
	const params = useParams();
	const [selectedItems, setSelectedItems] = useState([]);
	const [client, setClient] = useState(null);
	const [open, setOpen] = useState(false);
	const [clientList, setClientList] = useState([]);
	const [view, setView] = useState(null);
	const [openNotesDrawer, setOpenNotesDrawer] = useState(null);
	const { paginatedItems, loadMore, loadLess, hasMore, hasLess } =
		usePagination(sessions.completed, 10, 10, true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.post(
					`${process.env.REACT_APP_BACKEND_URL}/api/training-sessions/getList/`,
					{ clientId: params.id, coachId: user.id }
				);
				const { data } = response;
				if (data.sessionsList.length) {
					setClient(data.sessionsList[0].client);
				}
				setClientList(data.clientList);
				const { draft, live, completed } = data.sessionsList.reduce(
					(acc, item) => {
						if (item.status === "draft") {
							acc.draft.push(item);
						} else if (item.status === "live") {
							acc.live.push(item);
						} else if (item.status === "completed") {
							acc.completed.push(item);
						}
						return acc;
					},
					{ draft: [], live: [], completed: [] }
				);
				setSessions({ draft, live, completed });
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};

		fetchData();
		// eslint-disable-next-line
	}, [router]);

	const deleteSession = async (id) => {
		if (window.confirm(`Are you sure to delete`)) {
			setLoading(true);
			axios
				.post(
					process.env.REACT_APP_BACKEND_URL +
						`/api/training-sessions/delete/${id}`,
					{
						clientId: params.id,
					}
				)
				.then((res) => {
					const { id, status } = res.data;
					let updatedData = { ...sessions };
					let updatedArray;
					switch (status) {
						case "draft":
							updatedArray = updatedData.draft.filter(
								(item) => item.id !== id
							);
							updatedData.draft = updatedArray;
							break;
						case "live":
							updatedArray = updatedData.live.filter(
								(item) => item.id !== id
							);
							updatedData.live = updatedArray;
							break;
						default:
							updatedArray = updatedData.completed.filter(
								(item) => item.id !== id
							);
							updatedData.completed = updatedArray;
					}
					setSessions(updatedData);
					toast("Record deleted.", {
						type: "success",
					});
					setLoading(false);
				})
				.catch((err) => {
					let _message = err.response.data.error.message;
					if (_message === "noBelonging") {
						toast("Record does not belong to you.", {
							type: "error",
						});
					} else {
						toast("Something went wrong while updating record.", {
							type: "error",
						});
					}
					setLoading(false);
				});
		}
	};

	const reArrangeIndexs = (data) => {
		let _sessions = data ? { ...data } : { ...sessions };
		for (let key in _sessions) {
			_sessions[key].sort((a, b) => a.session_index - b.session_index);
		}
		setSessions(_sessions);
	};

	const dragEnd = (val) => {
		let { source, destination } = val;
		let _sessions = { ...sessions };
		if (
			destination.droppableId === source.droppableId &&
			destination.index === source.index
		) {
			return;
		}

		const swapItems = async (column, index1, index2) => {
			const originalArray = [..._sessions[column]];
			const movedItem = originalArray[index2];
			originalArray.splice(index2, 1);
			originalArray.splice(index1, 0, movedItem);

			const updatedArray = originalArray.map((item, index) => {
				return { ...item, session_index: index };
			});
			_sessions[column] = updatedArray;
			reArrangeIndexs(_sessions);

			return axios.post(
				`${process.env.REACT_APP_BACKEND_URL}/api/training-sessions/swapCards/`,
				{
					swappedItems: updatedArray,
				}
			);
		};
		// If index is changed in the same column
		if (source.droppableId === destination.droppableId) {
			if (source.droppableId === "draft") {
				swapItems("draft", destination.index, source.index)
					.then(() => {
						console.log("Swapped");
					})
					.catch((err) => {
						toast(
							"Something went wrong while updating sessions order.",
							{
								type: "error",
							}
						);
					});
			}
			if (source.droppableId === "live") {
				swapItems("live", destination.index, source.index)
					.then(() => {
						console.log("Swapped");
					})
					.catch((err) => {
						toast(
							"Something went wrong while updating sessions order.",
							{
								type: "error",
							}
						);
					});
			}
		} else {
			if (
				source.droppableId === "draft" &&
				destination.droppableId === "live"
			) {
				const movedItem = _sessions.draft[source.index];
				const liveOriginalArray = [..._sessions.live];
				const draftOriginalArray = [..._sessions.draft];

				// Remove the item from the draft array
				const filteredDraftArray = draftOriginalArray.filter(
					(item) => item.id !== movedItem.id
				);
				// Then setting draft value in session object
				_sessions.draft = filteredDraftArray;

				liveOriginalArray.splice(destination.index, 0, movedItem);
				const updatedLiveArray = liveOriginalArray.map(
					(live, index) => {
						return {
							...live,
							session_index: index,
							status: "live",
						};
					}
				);
				_sessions.live = updatedLiveArray;

				reArrangeIndexs(_sessions);
				return axios
					.post(
						`${process.env.REACT_APP_BACKEND_URL}/api/training-sessions/swapCards/`,
						{
							swappedItems: _sessions.live,
						}
					)
					.then(() => {
						console.log("Swapped");
					})
					.catch((err) => {
						toast(
							"Something went wrong while updating sessions order.",
							{
								type: "error",
							}
						);
					});
			} else if (
				source.droppableId === "live" &&
				destination.droppableId === "draft"
			) {
				const movedItem = _sessions.live[source.index];
				const draftOriginalArray = [..._sessions.draft];
				const liveOriginalArray = [..._sessions.live];

				// Remove the item from the live array
				const filteredLiveArray = liveOriginalArray.filter(
					(item) => item.id !== movedItem.id
				);
				_sessions.live = filteredLiveArray;
				draftOriginalArray.splice(destination.index, 0, movedItem);
				const updatedDraftArray = draftOriginalArray.map(
					(draft, index) => {
						return {
							...draft,
							session_index: index,
							status: "draft",
						};
					}
				);

				_sessions.draft = updatedDraftArray;
				reArrangeIndexs(_sessions);

				return axios
					.post(
						`${process.env.REACT_APP_BACKEND_URL}/api/training-sessions/swapCards/`,
						{
							swappedItems: _sessions.draft,
						}
					)
					.then(() => {
						console.log("Swapped");
					})
					.catch((err) => {
						toast(
							"Something went wrong while updating sessions order.",
							{
								type: "error",
							}
						);
					});
			}
		}
	};
	const changeSelectedItems = (id) => {
		let _selectedItems = [...selectedItems];
		if (_selectedItems.includes(id)) {
			_selectedItems = _selectedItems.filter((item) => item !== id);
		} else {
			_selectedItems.push(id);
		}
		setSelectedItems(_selectedItems);
	};
	const handleUncheckAll = () => {
		const checkboxes = document.querySelectorAll('input[type="checkbox"]');
		checkboxes.forEach((checkbox) => {
			checkbox.checked = false;
		});
	};
	const deleteMultipleItems = () => {
		if (window.confirm("Are you sure you want to delete these items")) {
			setLoading(true);
			axios
				.post(
					`${process.env.REACT_APP_BACKEND_URL}/api/training-sessions/deleteMultipleSessions/`,
					{
						deleteIds: selectedItems,
						clientId: client.id,
					}
				)
				.then((response) => {
					const { data } = response;
					const { draft, live, completed } = data.reduce(
						(acc, item) => {
							if (item.status === "draft") {
								acc.draft.push(item);
							} else if (item.status === "live") {
								acc.live.push(item);
							} else if (item.status === "completed") {
								acc.completed.push(item);
							}
							return acc;
						},
						{ draft: [], live: [], completed: [] }
					);

					setSessions({ draft, live, completed });
					setLoading(false);
					setSelectedItems([]);
					handleUncheckAll();
					toast("Record deleted.", {
						type: "success",
					});
				})
				.catch((err) => {
					toast("Something went wrong while deleting items.", {
						type: "error",
					});
					setLoading(false);
				});
		}
	};
	let _name = router?.search?.split("name=")[1];
	_name = _name.split("%20").join(" ");

	const copyTrainingSessions = (values) => {
		setLoading(true);
		axios
			.post(
				process.env.REACT_APP_BACKEND_URL +
					`/api/training-sessions/copySessions/`,
				{
					clientId: Number(values.client),
					currentClient: client.id,
					copiedItems: selectedItems,
				}
			)
			.then((response) => {
				const { data } = response;
				const { draft, live, completed } = data.reduce(
					(acc, item) => {
						if (item.status === "draft") {
							acc.draft.push(item);
						} else if (item.status === "live") {
							acc.live.push(item);
						} else if (item.status === "completed") {
							acc.completed.push(item);
						}
						return acc;
					},
					{ draft: [], live: [], completed: [] }
				);
				setSessions({ draft, live, completed });
				setSelectedItems([]);
				handleUncheckAll();
				setOpen(null);
				setLoading(false);
				toast("Record Copied.", {
					type: "success",
				});
			})
			.catch((err) => {
				let _message = err.response.data.error.message;
				if (_message === "noBelonging") {
					toast("Record does not belong to you.", {
						type: "error",
					});
				} else {
					toast("Something went wrong while copying record.", {
						type: "error",
					});
				}
				setLoading(false);
			});
	};

	return (
		<div>
			{" "}
			<div className="min-h-screen bg-no-repeat bg-cover bg-[#111111] pb-12">
				<Header user={user} role={role} />
				<div className="container mx-auto lg:mt-12 w-11/12 sm:w-full pt-4 lg:pt-0">
					<Navigation
						links={[
							{
								name: "Clients",
								url: "/coach/dashboard",
							},
							{
								name: _name,
								url: `/member/${params.id}`,
							},
							{
								name: "Sessions",
								url: `/coach/${params.id}/sessions?name=${_name}`,
							},
						]}
					/>
					<div className="flex items-center mt-4 flex-col md:flex-row">
						<h1 className="text-2xl md:text-3xl text-gray-700 font-bold font-rubik">
							{_name}`s Training Session
						</h1>
						<Link to={`/member/${params.id}/history?name=${_name}`}>
							<div className="ml-4 cursor-pointer mt-2 md:mt-0">
								<img src={HistoryIcon} alt="Edit Icon" />
							</div>
						</Link>
						<button
							onClick={() => {
								setOpenNotesDrawer(true);
							}}
						>
							<GrNotes className="text-white ml-4" />
						</button>
					</div>
					<div className="mt-8 mb-8 flex justify-end min-h-[30px]">
						{selectedItems.length ? (
							<button
								onClick={() => {
									copyTrainingSessions({
										client: client.id,
									});
								}}
								className="ml-2 cursor-pointer"
							>
								<img src={Repeat} alt="Repeat Icon" />
							</button>
						) : (
							<div />
						)}
						{selectedItems.length ? (
							<button
								onClick={() => {
									setOpen(true);
								}}
								className="ml-2 cursor-pointer"
							>
								<img src={CopyIcon} alt="Delete Icon" />
							</button>
						) : (
							<div />
						)}
						{selectedItems.length ? (
							<button
								onClick={() => {
									deleteMultipleItems();
								}}
								className="ml-2 cursor-pointer"
							>
								<img src={DeleteIcon} alt="Delete Icon" />
							</button>
						) : (
							<div />
						)}
					</div>
					<div className="flex items-center justify-between flex-wrap">
						<div className="w-full md:w-[31%] mb-4 md:mb-0">
							<div className="bg-[#1e1e1e] shadow border-l border-[#ff4655] border-l-2 w-full py-2 px-4 rounded">
								<p className="text-white font-medium text-5xl">
									{sessions.draft?.length || 0}
								</p>
								<h1 className="font-medium text-sm text-[#ff4655] mt-2">
									Drafts
								</h1>
							</div>
						</div>
						<div className="w-full md:w-[31%] mb-4 md:mb-0">
							<div className="bg-[#1e1e1e] shadow border-l border-blue-200 border-l-2 w-full py-2 px-4 rounded">
								<p className="text-white font-medium text-5xl">
									{" "}
									{sessions.live?.length || 0}
								</p>
								<h1 className="font-medium text-sm text-blue-200 mt-2">
									Upcoming
								</h1>
							</div>
						</div>
						<div className="w-full md:w-[31%] mb-4 md:mb-0">
							<div className="bg-[#1e1e1e] shadow border-l border-[#7fb77d] border-l-2 w-full py-2 px-4 rounded">
								<p className="text-white font-medium text-5xl">
									{" "}
									{sessions.completed?.length || 0}
								</p>
								<h1 className="font-medium text-sm text-[#7fb77d] mt-2">
									Completed
								</h1>
							</div>
						</div>
					</div>
					<div className="w-full bg-white mt-4 mb-12 h-px" />{" "}
					<DragDropContext
						onDragStart={() => {}}
						onDragEnd={(val) => {
							dragEnd(val);
						}}
					>
						<div className="w-full flex justify-between flex-col md:flex-row">
							<Droppable droppableId="draft">
								{(provided) => {
									return (
										<div
											ref={provided.innerRef}
											className="w-full md:w-[31%] min-h-[300px] border-b-2 border-white py-12 md:py-0"
											{...provided.droppableProps}
										>
											{sessions.draft.map(
												(draft, draftIndex) => {
													return (
														<Card
															selectedItems={
																selectedItems
															}
															changeSelectedItems={
																changeSelectedItems
															}
															params={params}
															deleteSession={
																deleteSession
															}
															setCurrentItem={
																setCurrentItem
															}
															value={draft}
															key={draftIndex}
															color="[#ff4655]"
															index={draftIndex}
														/>
													);
												}
											)}
											{provided.placeholder}
										</div>
									);
								}}
							</Droppable>
							<Droppable droppableId="live">
								{(provided) => {
									return (
										<div
											ref={provided.innerRef}
											className="w-full md:w-[31%] min-h-[300px] border-b-2 border-white py-12 md:py-0"
											{...provided.droppableProps}
										>
											{sessions.live.map(
												(live, liveIndex) => {
													return (
														<Card
															selectedItems={
																selectedItems
															}
															changeSelectedItems={
																changeSelectedItems
															}
															params={params}
															deleteSession={
																deleteSession
															}
															setCurrentItem={
																setCurrentItem
															}
															value={live}
															key={liveIndex}
															color="blue-200"
															index={liveIndex}
														/>
													);
												}
											)}
											{provided.placeholder}
										</div>
									);
								}}
							</Droppable>
							<div className="w-full md:w-[31%] min-h-[300px] border-b-2 border-white py-12 md:py-0">
								{paginatedItems
									?.sort((a, b) => {
										const timeA = a.session_data
											.completeTime
											? new Date(
													a.session_data.completeTime
											  )
											: 0;
										const timeB = b.session_data
											.completeTime
											? new Date(
													b.session_data.completeTime
											  )
											: 0;

										return timeB - timeA;
									})
									?.map((completed, completedIndex) => {
										return (
											<div
												key={completedIndex}
												className="min-h-[80px] bg-[#1e1e1e] rounded-xl flex mb-4 relative"
											>
												{returnCompletionDate(
													completed
												) && (
													<span className="absolute right-0 top-0 -mt-2 bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
														{returnCompletionDate(
															completed
														)}
													</span>
												)}

												<Dots color="[#7fb77d]" />
												<div className="flex items-center px-4 justify-between w-full">
													<div className="w-[75%]">
														<h1 className="text-white font-medium text-xl break-all">
															{completed.name}
														</h1>
													</div>
													<div className="flex items-center min-w-[80px]">
														<input
															onChange={() => {
																changeSelectedItems(
																	completed.id
																);
															}}
															className="h-4 w-4"
															type="checkbox"
															defaultChecked={selectedItems.includes(
																completed.id
															)}
														/>

														<button
															className="ml-2"
															onClick={() => {
																setView(
																	completed
																);
															}}
														>
															<img
																className="w-6"
																src={OpenIcon}
																alt="Open Icon"
															/>
														</button>

														<button
															onClick={() => {
																deleteSession(
																	completed.id
																);
															}}
															className="ml-2 cursor-pointer"
														>
															<img
																className="w-6"
																src={DeleteIcon}
																alt="Delete Icon"
															/>
														</button>
													</div>
												</div>
											</div>
										);
									})}
								{hasMore && (
									<button
										onClick={() => {
											loadMore();
										}}
										className="mb-4 mx-auto inline-block px-7 py-2 bg-button text-white font-medium text-sm leading-snug rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out mx-auto"
										data-mdb-ripple="true"
										data-mdb-ripple-color="light"
									>
										Load More
									</button>
								)}
								{hasLess && (
									<button
										onClick={() => {
											loadLess();
										}}
										className="mb-4 mx-auto ml-4 inline-block px-7 py-2 bg-button text-white font-medium text-sm leading-snug rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out mx-auto"
										data-mdb-ripple="true"
										data-mdb-ripple-color="light"
									>
										Load Less
									</button>
								)}
							</div>
						</div>
					</DragDropContext>
				</div>
				<Loader loading={loading} />
			</div>
			{currentItem && (
				<TrainingSessionDrawer
					currentItem={currentItem}
					setCurrentItem={setCurrentItem}
					sessionData={sessions}
					setSessionData={setSessions}
					clientId={client.id}
					coachId={user.id}
				/>
			)}
			{open && (
				<CopySessionDrawer
					open={open}
					clientList={clientList}
					setOpen={setOpen}
					sessionData={sessions}
					setSessionData={setSessions}
					selectedItems={selectedItems}
					setSelectedItems={setSelectedItems}
					currentClient={client}
					handleUncheckAll={handleUncheckAll}
					copyTrainingSessions={copyTrainingSessions}
					loading={loading}
				/>
			)}
			{view && <ResultDrawer open={view} setOpen={setView} />}
			{openNotesDrawer && (
				<ClientNotesDrawer
					open={openNotesDrawer}
					setOpen={setOpenNotesDrawer}
					clientId={params.id}
				/>
			)}
			{/* Floating button */}
			{!currentItem && (
				<button className="bg-[#6f4bdb] rounded-full shadow fixed right-0 bottom-0 bottom-[30px] right-[30px] z-50 p-1">
					{currentItem ? (
						<img
							src={CloseIcon}
							alt="Close"
							onClick={() => {
								setCurrentItem(false);
							}}
						/>
					) : (
						<img
							src={AddIcon}
							alt="Open"
							onClick={() => {
								setCurrentItem(true);
							}}
						/>
					)}
				</button>
			)}
		</div>
	);
};

const Card = ({
	color,
	value,
	setCurrentItem,
	deleteSession,
	index,
	changeSelectedItems,
	params,
	selectedItems,
}) => {
	return (
		<Draggable
			key={`card-${value.id}`}
			draggableId={`card-${value.id}`}
			index={index}
		>
			{(provided) => {
				return (
					<div
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						ref={provided.innerRef}
						className="min-h-[80px] bg-[#1e1e1e] rounded-xl flex mb-4"
					>
						<Dots color={color} />
						<div className="flex items-center px-4 justify-between w-full">
							<div className="w-[66%]">
								<h1 className="text-white font-medium text-xl break-all">
									{value.name}
								</h1>
							</div>
							<div className="flex items-center min-w-[112px]">
								<input
									className="h-4 w-4"
									onChange={() => {
										changeSelectedItems(value.id);
									}}
									type="checkbox"
									defaultChecked={selectedItems.includes(
										value.id
									)}
								/>
								<div className="cursor-pointer text-white ml-2">
									<Link
										to={`/coach/${params.id}/sessions/${value.id}`}
									>
										{" "}
										<img
											src={OpenIcon}
											alt="Open Icon"
											className="w-6"
										/>
									</Link>
								</div>
								{value.status !== "completed" && (
									<button
										onClick={() => {
											setCurrentItem(value);
										}}
										className="ml-2 cursor-pointer"
									>
										<img
											src={EditIcon}
											alt="Edit Icon"
											className="w-6"
										/>
									</button>
								)}

								<button
									onClick={() => {
										deleteSession(value.id);
									}}
									className="ml-2 cursor-pointer"
								>
									<img
										src={DeleteIcon}
										alt="Delete Icon"
										className="w-6"
									/>
								</button>
							</div>
						</div>
					</div>
				);
			}}
		</Draggable>
	);
};

const Dots = ({ color }) => {
	return (
		<div
			className={`w-[30px] bg-${color} rounded-tl-xl rounded-bl-xl flex justify-center flex-col items-center`}
		>
			<div className="flex items-center py-1 justify-between w-full px-1.5">
				<div className="h-1 w-1 bg-white rounded-full" />
				<div className="h-1 w-1 bg-white rounded-full" />
			</div>
			<div className="flex items-center py-1 justify-between w-full px-1.5">
				<div className="h-1 w-1 bg-white rounded-full" />
				<div className="h-1 w-1 bg-white rounded-full" />
			</div>
			<div className="flex items-center py-1 justify-between w-full px-1.5">
				<div className="h-1 w-1 bg-white rounded-full" />
				<div className="h-1 w-1 bg-white rounded-full" />
			</div>
			<div className="flex items-center py-1 justify-between w-full px-1.5">
				<div className="h-1 w-1 bg-white rounded-full" />
				<div className="h-1 w-1 bg-white rounded-full" />
			</div>
		</div>
	);
};

const TrainingSessions = () => {
	return (
		<ProtectedRoute>
			<SessionsComponent />
		</ProtectedRoute>
	);
};
export default TrainingSessions;
