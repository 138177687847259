import axios from "axios";
import cookie from "react-cookies";
import { toast } from "react-toastify";
import { logout } from "./utils";

export const requestInterceptor = () => {
  axios.interceptors.request.use(
    function (config) {
      if (cookie.load("token")) {
        const token = cookie.load("token");
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
};
export const responseInterceptor = () => {
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response) {
        if (error.response.status === 403 || error.response.status === 401) {
          logout();
          toast("Please Login again.", {
            type: "info",
          });
          setTimeout(() => {
            window.location.href = `/`;
          }, 3000);

          // if (error.response.data.error.message === "Invalid credentials") {
          //   logout();
          //   return;
          // } else {
          //   toast("Please Login again.", {
          //     type: "info",
          //   });
          //   setTimeout(() => {
          //     window.location.href = `/`;
          //   }, 2000);
          // }
        }
      }
      return Promise.reject(error);
    }
  );
};
