export const habits = [
  {
    title: "Alcohol",
    value: "alcohol",
  },
  {
    title: "Eat slowly",
    value: "eatSlowly",
  },
  {
    title: "Fruits & Veggies",
    value: "fruitsVeggies",
  },
  {
    title: "N.E.A.T.",
    value: "alcohol",
  },
  {
    title: "Protein",
    value: "protein",
  },
  {
    title: "Sleep",
    value: "sleep",
  },
  {
    title: "Stress",
    value: "stress",
  },
  {
    title: "Water",
    value: "water",
  },
];

export const angles = [
  {
    title: "Side",
    value: "side",
  },
  {
    title: "Front",
    value: "front",
  },
  {
    title: "Back",
    value: "back",
  },
];
