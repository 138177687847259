import React from "react";
import ProtectedRoute from "../../utils/ProtectedRoute";
import ClientListComponent from "../../Components/ClientListComponent";
const ClientList = () => {
  return (
    <ProtectedRoute>
      <ClientListComponent />
    </ProtectedRoute>
  );
};
export default ClientList;
