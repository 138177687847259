import React from "react";
import ProtectedRoute from "../../utils/ProtectedRoute";
import Header from "../../Components/Header";
import CoachForm from "../../Components/CoachForm";
const CoachProfileComponent = ({ user }) => {
  return (
    <div>
      <div className="min-h-screen bg-no-repeat bg-cover bg-[#111111]">
        <Header user={user} />
        <div className="container mx-auto">
          <div className="w-[90%] mx-auto pb-12">
            <h1 className="text-white text-3xl my-12 ">
              Hi, {user?.first_name}
            </h1>
            <CoachForm user={user} />
          </div>
        </div>
      </div>
    </div>
  );
};

const CoachProfile = () => {
  return (
    <ProtectedRoute>
      <CoachProfileComponent />
    </ProtectedRoute>
  );
};
export default CoachProfile;
