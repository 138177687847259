import { useState } from "react";
import Logo from "../../Assets/whiteLogo.png";
import { useForm } from "react-hook-form";
import Loader from "../../Components/Loader";
import ProtectedRoute from "../../utils/ProtectedRoute";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { formatEmail, loginUser } from "../../utils";

const LoginForm = ({
	isSubmitting,
	errors,
	handleSubmit,
	register,
	signInWithPassword,
	linkSent,
}) => {
	return (
		<>
			{linkSent ? (
				<h6 className="text-center text-2xl text-white md:text-black">
					A login code has been sent to your email.
				</h6>
			) : (
				<form
					onSubmit={handleSubmit(signInWithPassword)}
					className="w-full"
				>
					<div className="mb-6">
						<input
							{...register("email", {
								required: "Email is required to login.",
							})}
							type="text"
							className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
							placeholder="Email address"
						/>
						{errors.email && (
							<p className="mt-1 text-xs text-red-500">
								{errors.email.message}
							</p>
						)}
					</div>
					<button
						type="submit"
						className="inline-block px-7 py-3 bg-button text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out w-full"
					>
						{isSubmitting ? "Loading..." : "Login"}
					</button>
				</form>
			)}
		</>
	);
};

const CoachSignIn = () => {
	const [linkSent, SetLinkSet] = useState(false);
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
	} = useForm({
		defaultValues: {
			email: "",
		},
	});

	const signInWithPassword = async (data) => {
		let _email = formatEmail(data.email);
		await axios
			.post(process.env.REACT_APP_BACKEND_URL + `/api/loginWithEmail`, {
				email: _email,
			})
			.then(async (response) => {
				if (response.data.token) {
					await loginUser(response.data.token, navigate);
				} else {
					toast("Email sent.", {
						type: "success",
					});
					SetLinkSet(true);
					setTimeout(() => {
						navigate("/verify");
					}, 2000);
				}
			})
			.catch((error) => {
				let _error = error.response.data.error.message;
				if (_error === "wrong.email") {
					toast("User with this email not found.", {
						type: "error",
					});
				} else if (_error === "blocked.user") {
					toast("User deactivated, plase contact coach.", {
						type: "error",
					});
				} else {
					toast("Unable to login", {
						type: "error",
					});
				}
			});
	};
	let formProps = {
		errors,
		handleSubmit: handleSubmit,
		register: register,
		isSubmitting,
		signInWithPassword,
		linkSent,
	};

	const isMobile = window.screen.width < 767;

	return (
		<div className="h-screen">
			<div className="block md:flex items-center">
				<div className="w-full md:w-1/2 min-h-screen flex flex-col md:flex-row items-center justify-center bg-[#111111]">
					<img alt="Logo" src={Logo} className="w-1/2" />

					<div className="md:hidden mt-4">
						<h5 className="text-center text-3xl font-semibold text-white">
							Fitness Dashboard
						</h5>

						<div className="w-w-11/12	mx-auto mt-12">
							{isMobile && <LoginForm {...formProps} />}
						</div>
					</div>
				</div>
				<div className="hidden md:flex justify-center flex-col items-center w-1/2">
					<h1 className="text-4xl mb-12 font-semibold">
						Fitness Dashboard
					</h1>
					<div className="w-10/12 lg:w-1/2 mx-auto">
						{!isMobile && <LoginForm {...formProps} />}
					</div>
				</div>
			</div>
			<Loader loading={isSubmitting} />
		</div>
	);
};

const PageComponent = () => {
	return (
		<ProtectedRoute>
			<CoachSignIn />
		</ProtectedRoute>
	);
};
export default PageComponent;
