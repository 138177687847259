import React, { useState } from "react";
import moment from "moment";
import { VscSettings } from "react-icons/vsc";
import { formattedDate } from "../../utils";

const DateRangerSelector = ({ calendar, setCalendar, color }) => {
	const [totalWeeks, setTotalWeeks] = useState(2);
	const [customSelection, setCustomSelection] = useState(false);
	const onWeekSelection = (week) => {
		setTotalWeeks(week);
		setCalendar([moment().subtract(week * 7 - 1, "days"), moment()]);
	};
	const WeekButton = ({ week }) => {
		return (
			<button
				onClick={() => {
					setCustomSelection(false);
					onWeekSelection(week);
				}}
				className={`text-xs h-9 w-9 text-white rounded-full border border-gray-500 ${
					!customSelection && totalWeeks === week
						? `border-2 !border-[${color}]`
						: ""
				}`}
			>
				{week + "W"}
			</button>
		);
	};

	return (
		<div>
			<p className="text-white text-lg lg:text-2xl w-full text-center mb-4 lg:mb-8">
				{formattedDate(calendar[0], true) +
					" - " +
					formattedDate(calendar[1], true)}
			</p>
			<div className="flex justify-between w-full">
				<WeekButton week={1} />
				<WeekButton week={2} />
				<WeekButton week={4} />
				<button
					onClick={() => {
						setCustomSelection(!customSelection);
					}}
					className={` h-9 w-9 p-2 text-white rounded-full border border-gray-500 ${
						customSelection || totalWeeks > 4
							? `border-2 !border-[${color}]`
							: ""
					}`}
				>
					<VscSettings />
				</button>
			</div>
			{customSelection && (
				<div className="mt-3">
					<h1 className="text-white text-sm w-full text-center font-medium">
						Select Weeks
					</h1>
					<div className="flex items-center justify-between slidecontainer">
						<p className="text-white">1</p>
						<input
							onChange={(e) => {
								e.preventDefault();
								onWeekSelection(Number(e.target.value));
							}}
							step={1}
							max={52}
							min={1}
							defaultValue={totalWeeks}
							type="range"
							className="w-10/12"
						/>
						<p className={`text-white`}>52</p>
					</div>
					<h1 className="text-white text-sm w-full text-center font-medium">
						{totalWeeks}
					</h1>
				</div>
			)}
		</div>
	);
};
export default DateRangerSelector;
