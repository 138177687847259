import LoadingImage from "../../Assets/loader.svg";
import ButtonLoader from "../../Assets/loaderButton.svg";
const Loader = ({ loading }) => {
	return (
		<>
			{loading && (
				<div
					style={{ zIndex: 100000 }}
					className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-20"
				>
					<img className="w-20" src={LoadingImage} alt="Loader" />
				</div>
			)}
		</>
	);
};
export const LoaderButton = ({ md }) => {
	return (
		<img className={md ? "w-24" : "w-20"} src={ButtonLoader} alt="Loader" />
	);
};
export const SmallLoader = ({ loading }) => {
	return (
		<>
			{loading && (
				<div
					style={{ zIndex: 100000 }}
					className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-20"
				>
					<img className="w-20" src={LoadingImage} alt="Loader" />
				</div>
			)}
		</>
	);
};

export default Loader;
