import { useEffect, useState, useRef } from "react";
import ProtectedRoute from "../../utils/ProtectedRoute";
import Header from "../../Components/Header";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader";
import axios from "axios";
import { toast } from "react-toastify";
import Navigation from "../../Components/Navigation";

const ClientSessionListing = ({ user, role }) => {
	const router = useLocation();
	const [sessions, setSessions] = useState([]);
	const [loading, setLoading] = useState(true);
	const params = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.post(
					`${process.env.REACT_APP_BACKEND_URL}/api/training-sessions/client/getList/`,
					{ clientId: params.id }
				);
				if (response.data.length) {
					let _alreadyStarted = response.data.find(
						(item) =>
							item.session_data.startTime &&
							item.status === "live"
					);
					if (_alreadyStarted) {
						toast("Resuming training session", {
							type: "success",
						});
						navigate(
							`/member/${params.id}/sessions/${_alreadyStarted.id}`
						);
					} else {
						setSessions(response.data);
					}
				}

				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};

		fetchData();
		// eslint-disable-next-line
	}, [router]);

	const openTrainingSession = (session) => {
		localStorage.removeItem("session_data");
		navigate(`/member/${params.id}/sessions/${session.id}`);
	};

	let _name = router?.search?.split("name=")[1];
	return (
		<div>
			{" "}
			<div className="min-h-screen bg-no-repeat bg-cover bg-[#111111] pb-12">
				<Header user={user} role={role} />
				<div className="container mx-auto lg:mt-12 w-11/12 sm:w-full pt-4 lg:pt-0">
					<Navigation
						links={[
							{
								name: _name,
								url: `/member/${params.id}`,
							},
							{
								name: "Sessions",
								url: `/member/${params.id}/sessions?name=${_name}`,
							},
						]}
					/>
					<h1 className="text-2xl md:text-5xl text-gray-700 font-bold font-rubik mt-4">
						{_name}`s Training Sessions
					</h1>
					{!loading && (
						<div className="mt-12 border-t border-white">
							<>
								{sessions.map((session, sessionIndex) => {
									return (
										<div
											key={sessionIndex}
											className="md:w-9/12 p-[2px] mt-12 rounded bg-[#1e1e1e] mx-auto gradient-bg"
										>
											<div className="w-full bg-black p-4 md:p-8 rounded cursor-pointer relative">
												<div className="flex justify-center items-center">
													<h1 className="text-white font-semibold text-2xl">
														{session.name}
													</h1>
												</div>

												<div className="flex items-end justify-center">
													<button
														onClick={() => {
															navigate(
																`/member/${params.id}/preview/${session.id}?name=${_name}`
															);
														}}
														className="w-[95px] text-white mt-4 gradient-bg p-[2px] rounded mr-2"
													>
														<div className="px-4 py-2 bg-black">
															Preview
														</div>
													</button>
													<button
														onClick={() =>
															openTrainingSession(
																session
															)
														}
														className="w-[95px] text-white mt-4 gradient-bg p-[2px] rounded"
													>
														<div className="px-4 py-2 bg-black">
															Start
														</div>
													</button>
												</div>
											</div>
										</div>
									);
								})}
							</>
						</div>
					)}
				</div>
			</div>
			<Loader loading={loading} />
		</div>
	);
};
const ClientTrainingSessions = () => {
	return (
		<ProtectedRoute>
			<ClientSessionListing />
		</ProtectedRoute>
	);
};
export default ClientTrainingSessions;
