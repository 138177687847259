import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import Table from "../../Components/Table";
import OpenIcon from "../../Assets/open.svg";
import Loader from "../../Components/Loader";
import { Link, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import HistoryIcon from "../../Assets/history-color.svg";
import ProtectedRoute from "../../utils/ProtectedRoute";
import { formattedDate } from "../../utils";
import Navigation from "../../Components/Navigation";

const HistoryList = ({ user, role }) => {
	const [historyItems, setHistoryItems] = useState([]);
	const [loading, setLoading] = useState(true);
	const params = useParams();
	const router = useLocation();

	const fetchHistory = async () => {
		axios
			.get(
				process.env.REACT_APP_BACKEND_URL +
					`/api/client-history/${params.id}/getClientHistory`
			)
			.then((res) => {
				setHistoryItems(
					res.data.filter((item) => item?.exercise_database?.id)
				);
				setLoading(false);
			})
			.catch((err) => {
				toast("Unable to fetch history", {
					type: "error",
				});
				setLoading(false);
			});
	};

	useEffect(() => {
		fetchHistory();
		// eslint-disable-next-line
	}, [user.id]);
	let _name = router?.search?.split("name=")[1];
	_name = _name.split("%20").join(" ");
	return (
		<div className="min-h-screen bg-no-repeat bg-cover bg-[#111111]">
			<Header user={user} role={role} />
			<div className="container mx-auto pb-12 w-11/12 mx-auto lg:W-full mt-12">
				<Navigation
					links={[
						{
							name: "Clients",
							url: "/coach/dashboard",
						},
						{
							name: _name,
							url: `/coach/${params.id}/sessions?name=${_name}`,
						},
						{
							name: "History",
							url: `/member/${params.id}/history?name=${_name}`,
						},
					]}
				/>
				<div className="bg-[#1e1e1e] rounded-xl py-8 relative mt-12">
					<div className="w-full px-8 relative pt-12">
						<div className="flex items-center flex-col absolute top-0 -mt-12">
							<div className="h-12 w-12 rounded bg-[#d6b369] shadow flex items-center justify-center">
								<img
									alt="Client Icon"
									src={HistoryIcon}
									className="w-8 h-8"
								/>
							</div>
							<p className="text-white text-lg font-bold mt-1">
								History
							</p>
						</div>

						<Table
							headerColor="#1e1e1e"
							columns={[
								{
									id: "exercise_database",
									header: () => "Exercise",
									accessorFn: (row) =>
										`${row.exercise_database.name}`,
									cell: (info) => {
										return (
											<Link
												to={`/member/${params.id}/history/${info.row.original.exercise_database.id}?name=${_name}`}
											>
												<span className="whitespace-nowrap py-4 text-sm text-white text-right">
													{
														info.row.original
															.exercise_database
															.name
													}
												</span>
											</Link>
										);
									},
								},
								{
									id: "result",
									header: () => "Result",
									accessorFn: (row) => `${row.result}`,
									cell: (info) => {
										return (
											<Link
												to={`/member/${params.id}/history/${info.row.original.exercise_database.id}?name=${_name}`}
											>
												<span className="whitespace-nowrap py-4 text-sm text-white text-right">
													{info.row.original.result}
												</span>
											</Link>
										);
									},
								},
								{
									id: "createdAt",
									header: () => "Tracked on",
									accessorFn: (row) =>
										`${formattedDate(row.createdAt, true)}`,
									cell: (info) => {
										return (
											<Link
												to={`/member/${params.id}/history/${info.row.original.exercise_database.id}?name=${_name}`}
											>
												<span className="whitespace-nowrap py-4 text-sm text-white text-right">
													{formattedDate(
														info.row.original
															.createdAt,
														true
													)}
												</span>
											</Link>
										);
									},
								},
								{
									id: "view",
									header: () => "View",
									cell: (info) => {
										return (
											<div className="flex">
												<div className="cursor-pointer text-white">
													<Link
														to={`/member/${params.id}/history/${info.row.original.exercise_database.id}?name=${_name}`}
													>
														{" "}
														<img
															src={OpenIcon}
															alt="Open Icon"
														/>
													</Link>
												</div>
											</div>
										);
									},
								},
							]}
							data={historyItems}
						/>
					</div>
				</div>
			</div>
			<Loader loading={loading} />
		</div>
	);
};

const HistoryListPage = () => {
	return (
		<ProtectedRoute>
			<HistoryList />
		</ProtectedRoute>
	);
};
export default HistoryListPage;
