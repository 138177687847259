import Graph from "../Graph";
import CountUp from "react-countup";
import { FaPlus, FaWeight } from "react-icons/fa";
import { IoFootsteps } from "react-icons/io5";
import { FaArrowsRotate } from "react-icons/fa6";
import DateRangerSelector from "../DateRangerSelector";
import { useEffect, useState } from "react";
const OverviewMobile = ({
	userData,
	calculation,
	setCalendar,
	calendar,
	displayHabit,
	activeGraph,
	setActiveGraph,
	openWeightModal,
	openStepsModal,
	openHabitModal,
}) => {
	const [noData, setNoData] = useState(false);
	useEffect(() => {
		setNoData(calculation[activeGraph].items.length);
	}, [activeGraph, calculation]);
	console.log("check no data", noData);
	return (
		<div className="md:hidden min-h-[50vh] md:min-h-auto mb-4vh md:mb-0">
			<div className="bg-[#1e1e1e] rounded-xl py-1 xl:w-9/12 mx-auto shadow-xl">
				<div className="flex justify-between flex-col xl:flex-row px-1">
					<div className={`relative transition-height duration-500`}>
						<div className="rangePicker w-full px-3 pt-1">
							<DateRangerSelector
								setCalendar={setCalendar}
								calendar={calendar}
								color={
									activeGraph === "habit"
										? "#587a57"
										: activeGraph === "steps"
										? "#8a7549"
										: "#9370db"
								}
							/>
						</div>
						<div
							className={`mt-4 xl:mt-12 h-[200px] flex items-center justify-center w-full ${
								noData ? "-ml-3" : ""
							}`}
						>
							<Graph
								setNoData={setNoData}
								calculation={calculation}
								activeGraph={activeGraph}
							/>
						</div>
						{userData?.weight_tracking ||
						userData?.steps_tracking ||
						userData?.current_habit ? (
							<div className="flex justify-center">
								<button
									onClick={() => {
										if (activeGraph === "habit") {
											openHabitModal(true);
										} else if (activeGraph === "weight") {
											openWeightModal(true);
										} else {
											openStepsModal(true);
										}
									}}
									className={`text-white font-medium mx-auto rounded-full text-sm flex items-center py-1 px-2 uppercase ${
										activeGraph === "habit"
											? "bg-[#587a57]"
											: activeGraph === "steps"
											? "bg-[#8a7549]"
											: "bg-[#9370db]"
									}`}
								>
									<FaPlus className="mr-1" />{" "}
									{activeGraph === "habit"
										? displayHabit
										: activeGraph === "weight"
										? "Weight"
										: "Steps"}
								</button>
							</div>
						) : (
							<div />
						)}

						<div
							className={` ${
								userData.weight_tracking &&
								userData.steps_tracking &&
								userData.current_habit
									? " justify-between"
									: "justify-center gap-x-5"
							} flex items-start mt-4 px-1 mx-auto flex-wrap`}
						>
							{userData.weight_tracking && (
								<div className="w-[32%] bg-[#1e1e1e] rounded-xl flex items-center cursor-pointer">
									<div
										onClick={() => {
											setActiveGraph("weight");
										}}
										className={`flex justify-center md:block w-full md:w-[98%] lg:w-[80%] border border-gray-500 ${
											activeGraph === "weight"
												? "bg-[#111111] shadow border-2 rounded !border-[#9370db]"
												: ""
										}`}
									>
										<div className="flex flex-col items-center p-2">
											<FaWeight className="text-white text-xl mb-1" />
											{calculation.weight.average ? (
												<p className="text-base text-white font-medium w-full text-center md:text-left">
													<CountUp
														end={
															calculation.weight
																.average
														}
														decimals={1}
													/>
													<br />
													<span className="text-xs text-gray-500 font-medium">
														&nbsp;
														{
															calculation.weight
																.days
														}{" "}
														days
													</span>
												</p>
											) : (
												<>
													<p className="text-base text-white font-medium w-full text-center md:text-left">
														N/A
													</p>

													<span className="opacity-0 text-xs text-gray-500 font-medium mt-2">
														&nbsp; days
													</span>
												</>
											)}
										</div>
									</div>
								</div>
							)}
							{userData.steps_tracking && (
								<div className="w-[32%] bg-[#1e1e1e] rounded-xl flex items-center cursor-pointer justify-center">
									<div
										onClick={() => {
											setActiveGraph("steps");
										}}
										className={`flex justify-center md:block w-full md:w-[98%] lg:w-[80%] border border-gray-500 ${
											activeGraph === "steps"
												? "bg-[#111111] shadow border-2 rounded !border-[#8a7549]"
												: ""
										}`}
									>
										<div className="flex flex-col items-center p-2">
											<IoFootsteps className="text-white text-xl mb-1" />
											{calculation.steps.average ? (
												<p className="text-base text-white font-medium w-full text-center md:text-left">
													<CountUp
														end={
															calculation.steps
																.average
														}
													/>
													<br />
													<span className="text-xs text-gray-500 font-medium">
														&nbsp;
														{
															calculation.steps
																.days
														}{" "}
														days
													</span>
												</p>
											) : (
												<>
													<p className="text-base text-white font-medium w-full text-center md:text-left">
														N/A
													</p>

													<span className="opacity-0 text-xs text-gray-500 font-medium mt-2">
														&nbsp; days
													</span>
												</>
											)}
										</div>
									</div>
								</div>
							)}
							{userData?.current_habit && (
								<div className="w-[32%] bg-[#1e1e1e] rounded-xl flex items-center cursor-pointer flex justify-end">
									<div
										onClick={() => {
											setActiveGraph("habit");
										}}
										className={`flex justify-center md:block w-full md:w-[98%] lg:w-[80%] border border-gray-500 ${
											activeGraph === "habit"
												? "bg-[#111111] shadow border-2 rounded !border-[#587a57]"
												: ""
										}`}
									>
										<div className="flex flex-col items-center p-2">
											<FaArrowsRotate className="text-white text-xl mb-1" />
											{calculation.habit.average ? (
												<p className="text-base text-white font-medium w-full text-center md:text-left">
													<CountUp
														end={
															calculation.habit
																.average
														}
													/>
													%
													<br />
													<span className="text-xs text-gray-500 font-medium">
														&nbsp;
														{
															calculation.habit
																.days
														}{" "}
														days
													</span>
												</p>
											) : (
												<>
													<p className="text-base text-white font-medium w-full text-center md:text-left">
														N/A
													</p>

													<span className="opacity-0 text-xs text-gray-500 font-medium mt-2">
														&nbsp; days
													</span>
												</>
											)}
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default OverviewMobile;
