import Close from "../../Assets/close.svg";
import { useEffect, useState } from "react";
import { formattedDate, usePagination } from "../../utils";

const ExerciseHistoryDrawer = ({ open, setOpen }) => {
	const [openDrawer, setOpenDrawer] = useState(false);
	const { paginatedItems, loadMore, loadLess, hasMore, hasLess } =
		usePagination(open || []);
	useEffect(() => {
		if (open.length) {
			setTimeout(() => {
				setOpenDrawer(true);
			}, 200);
		}
	}, [open]);

	const closeDrawer = () => {
		setOpenDrawer(false);
		setTimeout(() => {
			setOpen([]);
		}, 200);
	};

	return (
		<div className={`fixed inset-0 z-20 bg-black bg-opacity-50`}>
			<div
				className={`w-full md:w-[400px] bg-[#1e1e1e] h-screen overflow-y-scroll right-0 absolute shadow px-4 py-12 ease-in-out transition ${
					openDrawer ? "" : "translate-x-full"
				} `}
			>
				<button
					onClick={() => {
						closeDrawer();
					}}
				>
					<img
						src={Close}
						alt="Close"
						className="w-8 absolute right-[10px] top-[20px]"
					/>
				</button>
				<h1 className="text-center text-white font-Medium text-2xl">
					{open[0]?.exercise_database?.name}
				</h1>
				<ul className="pl-4 pt-4">
					{paginatedItems.map((item, index) => {
						return (
							<li
								className="text-white mb-2 text-base"
								key={index}
							>
								<b>{formattedDate(item.createdAt, true)}: </b>
								{item.result}
							</li>
						);
					})}
				</ul>
				<div className="mt-4" />
				{hasMore && (
					<button
						onClick={() => {
							loadMore();
						}}
						className="mb-4 mx-auto inline-block underline text-white font-medium text-sm leading-snug rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out mx-auto"
						data-mdb-ripple="true"
						data-mdb-ripple-color="light"
					>
						Load More
					</button>
				)}
				{hasLess && (
					<button
						onClick={() => {
							loadLess();
						}}
						className="mb-4 mx-auto ml-4 inline-block underline text-white font-medium text-sm leading-snug rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out mx-auto"
						data-mdb-ripple="true"
						data-mdb-ripple-color="light"
					>
						Load Less
					</button>
				)}
			</div>
		</div>
	);
};
export default ExerciseHistoryDrawer;
