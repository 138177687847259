import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import backgroundImage from "../Assets/main-bg.jpeg";
import cookie from "react-cookies";
import { logout } from "../utils";
const ProtectedRoute = ({ children }) => {
	const router = useLocation();
	const navigate = useNavigate();
	const [loader, setLoader] = useState(true);
	let user = cookie.load("profile");
	let role = cookie.load("role");
	let token = cookie.load("token");
	let userId = cookie.load("userId");

	useEffect(() => {
		if (user) {
			if (!userId) {
				logout();
				return;
			}
			if (
				router.pathname === "/login" ||
				router.pathname === "/verify" ||
				router.pathname === "/"
			) {
				if (role === "Coach") {
					navigate("/coach/dashboard");
				} else {
					navigate(`/member/${user.uuid}`);
				}
			}
		} else {
			if (router.pathname !== "/verify" && router.pathname !== "/login") {
				navigate("/login");
			}
		}
		setLoader(false);

		// eslint-disable-next-line
	}, [user, token, role]);

	return (
		<div>
			{loader ? (
				<div
					className="min-h-screen bg-no-repeat bg-cover"
					style={{ backgroundImage: `url(${backgroundImage})` }}
				></div>
			) : (
				<div>
					<div>
						{children &&
							React.cloneElement(children, {
								user: user,
								role: role,
								token: token,
							})}
					</div>
				</div>
			)}
		</div>
	);
};

export default ProtectedRoute;
