import Close from "../../Assets/close.svg";
import AddIcon from "../../Assets/addIcon.svg";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { LoaderButton } from "../Loader";
const ExerciseDrawer = ({
	tableData,
	currentItem,
	setCurrentItem,
	setTableData,
	coachId,
}) => {
	const [loading, setLoading] = useState(false);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [tags, setTags] = useState([]);
	const tagsInputRef = useRef(null);
	const wrapperRef = useRef(null);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		defaultValues: {
			name: currentItem?.name || "",
			youtube_url: currentItem?.youtube_url || "",
			text_note: currentItem?.text_note || "",
		},
	});

	useEffect(() => {
		if (currentItem) {
			if (currentItem?.id) {
				setTags(currentItem?.tags?.split(",") || []);
			}
			setTimeout(() => {
				setOpenDrawer(true);
			}, 200);
		}
	}, [currentItem]);

	const closeDrawer = () => {
		setOpenDrawer(false);
		setTimeout(() => {
			setCurrentItem(null);
		}, 200);
	};
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				wrapperRef.current &&
				!wrapperRef.current.contains(event.target) &&
				openDrawer
			) {
				closeDrawer();
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [wrapperRef, openDrawer]);

	const createOrUpdateExercise = (values) => {
		setLoading(true);
		if (currentItem?.id) {
			let _data = {
				...values,
				coachId: coachId,
			};
			if (tags?.length) {
				_data.tags = tags?.toString();
			} else {
				_data.tags = null;
			}
			axios
				.put(
					process.env.REACT_APP_BACKEND_URL +
						`/api/exercise-databases/${currentItem.id}`,
					_data
				)
				.then((res) => {
					let _data = [...tableData];
					_data[_data.findIndex((item) => item.id === res.data.id)] =
						res.data;
					setTableData(_data);
					setCurrentItem(null);
					toast("Record updated.", {
						type: "success",
					});
				})
				.catch((err) => {
					let _message = err.response.data.error.message;
					if (_message === "noBelonging") {
						toast("Record does not belong to you.", {
							type: "error",
						});
					} else {
						toast("Something went wrong while updating record.", {
							type: "error",
						});
					}
					setLoading(false);
				});
		} else {
			let _data = {
				...values,
				coachId: coachId,
			};
			if (tags?.length) {
				_data.tags = tags?.toString();
			}
			console.log(_data);
			axios
				.post(
					process.env.REACT_APP_BACKEND_URL +
						`/api/exercise-databases`,
					_data
				)
				.then((res) => {
					let _data = [...tableData];
					_data.push(res.data);
					setTableData(_data);
					closeDrawer(null);
					toast("Record added successfully.", {
						type: "success",
					});
				})
				.catch((err) => {
					toast("Something went wrong while adding record.", {
						type: "error",
					});
					setLoading(false);
				});
		}
	};
	return (
		<div className={`fixed inset-0 z-20 bg-black bg-opacity-50`}>
			<div
				ref={wrapperRef}
				className={`w-full md:w-[400px] bg-[#1e1e1e] h-screen overflow-y-scroll right-0 absolute shadow px-4 py-12 ease-in-out transition ${
					openDrawer ? "" : "translate-x-full"
				} `}
			>
				<button
					onClick={() => {
						closeDrawer();
					}}
				>
					<img
						src={Close}
						alt="Close"
						className="w-8 absolute right-[10px] top-[20px]"
					/>
				</button>
				<h1 className="text-center text-white font-Medium text-2xl">
					Add Exercise
				</h1>
				<form
					className="mt-8"
					onSubmit={handleSubmit(createOrUpdateExercise)}
				>
					<div className="mb-6 w-full">
						<label className="text-white font-medium">Name</label>
						<input
							{...register("name", {
								required: "Required.",
							})}
							type="text"
							className="placeholder-gray-400 mt-2 form-control block w-full px-4 py-2 text-xl font-normal bg-transparent border border-white border-opacity-20 rounded transition ease-in-out m-0 focus:outline-none text-gray-400 shadow"
						/>
						{errors.name && (
							<p className="mt-1 text-xs text-red-500">
								{errors.name.message}
							</p>
						)}
					</div>
					<div className="mb-6 w-full">
						<label className="text-white font-medium">
							Youtube URL
						</label>
						<input
							{...register("youtube_url", {})}
							type="text"
							className="placeholder-gray-400 mt-2 form-control block w-full px-4 py-2 text-xl font-normal bg-transparent border border-white border-opacity-20 rounded transition ease-in-out m-0 focus:outline-none text-gray-400 shadow"
						/>
						{errors.youtube_url && (
							<p className="mt-1 text-xs text-red-500">
								{errors.youtube_url.message}
							</p>
						)}
					</div>
					<div className="mb-6 w-full">
						<label className="text-white font-medium">
							Text Note
						</label>
						<textarea
							{...register("text_note", {})}
							type="text"
							className="placeholder-gray-400 mt-2 form-control block w-full px-4 py-2 text-xl font-normal bg-transparent border border-white border-opacity-20 rounded transition ease-in-out m-0 focus:outline-none text-gray-400 shadow"
						/>
						{errors.text_note && (
							<p className="mt-1 text-xs text-red-500">
								{errors.text_note.message}
							</p>
						)}
					</div>
					<div className="mb-6 w-full flex items-center justify-between">
						<div className="w-[85%]">
							<label className="text-white font-medium">
								Tags
							</label>
							<input
								ref={tagsInputRef}
								type="text"
								className="placeholder-gray-400 mt-2 form-control block w-full px-4 py-2 text-xl font-normal bg-transparent border border-white border-opacity-20 rounded transition ease-in-out m-0 focus:outline-none text-gray-400 shadow"
							/>
						</div>
						<button
							onClick={() => {
								let value = tagsInputRef.current.value;
								let _tagValues = [...tags];
								if (value) {
									_tagValues.push(value.trim());
									setTags(_tagValues);
									tagsInputRef.current.value = "";
								}
							}}
							type="button"
						>
							{" "}
							<img alt="Add" src={AddIcon} className="w-8 mt-6" />
						</button>
					</div>
					<div className="flex mb-6">
						{tags.map((tag, tagIndex) => {
							return (
								<div
									key={tagIndex}
									className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300"
								>
									{tag}{" "}
									<button
										onClick={() => {
											let _tagValues = [...tags];
											_tagValues = _tagValues.filter(
												(item) => item !== tag
											);
											setTags(_tagValues);
										}}
										type="button"
									>
										X
									</button>
								</div>
							);
						})}
					</div>
					<button
						type="submit"
						className="inline-block px-7 py-2 bg-button text-white font-medium text-sm leading-snug rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out mx-auto"
						data-mdb-ripple="true"
						data-mdb-ripple-color="light"
					>
						{loading ? (
							<LoaderButton />
						) : currentItem?.id ? (
							"Edit"
						) : (
							"Create"
						)}
					</button>
				</form>
			</div>
		</div>
	);
};
export default ExerciseDrawer;
