import { useEffect } from "react";
import AddIcon from "../../Assets/add.svg";
import AddBlack from "../../Assets/add-black.svg";
import CloseIcon from "../../Assets/close.svg";
import { logout, sortWithKey } from "../../utils";
const FloatingMenu = ({
	menuOpen,
	setMenuOpen,
	setLogItem,
	coachData,
	clientLinks,
	firstLoad,
	setFirstLoad,
}) => {
	useEffect(() => {
		if (menuOpen) {
			document.body.classList.add("overflow-hidden");
		} else {
			document.body.classList.remove("overflow-hidden");
		}
	}, [menuOpen]);

	return (
		<>
			<div
				className={`${
					firstLoad && "h-0 w-0"
				} overflow-hidden flex justify-end items-end bg-black bg-opacity-90 ${
					menuOpen && "animate-fade-in-slow"
				} ${!firstLoad && !menuOpen && "animate-fade-out-slow"} `}
			>
				<div
					className={`${
						menuOpen ? "translate-x-0" : "translate-x-full"
					} transition ease-in-out delay-150 duration-500 pb-32 pr-4`}
				>
					<ul className="flex flex-col justify-end items-end">
						<li
							onClick={() => {
								logout();
							}}
							className="font-semibold text-white text-right text-xl mb-6 cursor-pointer"
						>
							Logout
						</li>
						{coachData?.primary_link_text &&
						coachData?.primary_link_value ? (
							<li className="font-semibold text-white text-right text-xl mb-6 cursor-pointer">
								<a
									target="_blank"
									rel="noreferrer"
									href={coachData?.primary_link_value}
								>
									{" "}
									{coachData?.primary_link_text}
								</a>
							</li>
						) : (
							<></>
						)}

						{coachData?.links?.length ? (
							sortWithKey(coachData?.links, "createdAt").map(
								(link, linIndex) => {
									return (
										<li
											key={linIndex}
											className="font-semibold text-white text-right text-xl mb-6 cursor-pointer"
										>
											<a
												target="_blank"
												rel="noreferrer"
												href={link?.link}
											>
												{" "}
												{link?.title}
											</a>
										</li>
									);
								}
							)
						) : (
							<></>
						)}
						{clientLinks?.length ? (
							clientLinks.map((link, linIndex) => {
								return (
									<li
										key={linIndex}
										className="font-semibold text-white text-right text-xl mb-6 cursor-pointer"
									>
										<a
											target="_blank"
											rel="noreferrer"
											href={link?.link}
										>
											{" "}
											{link?.title}
										</a>
									</li>
								);
							})
						) : (
							<></>
						)}
					</ul>
					<div className="w-100px h-1 bg-gray-400 rounded-full my-6"></div>
					<ul className="flex flex-col justify-end items-end">
						<li
							role="button"
							onClick={() => {
								setLogItem(true);
							}}
							className="font-semibold text-white text-right text-xl mb-6 flex items-center justify-end cursor-pointer w-max"
						>
							<img src={AddBlack} alt="Add Icon" /> Log entry
						</li>
					</ul>
				</div>
			</div>

			<button className="bg-[#6f4bdb] rounded-full shadow fixed right-0 bottom-0 bottom-[30px] right-[30px] z-50 p-1">
				{menuOpen ? (
					<img
						src={CloseIcon}
						alt="Close"
						onClick={() => {
							setMenuOpen(false);
						}}
					/>
				) : (
					<img
						src={AddIcon}
						alt="Open"
						onClick={() => {
							setFirstLoad(false);
							setMenuOpen(true);
						}}
					/>
				)}
			</button>
		</>
	);
};
export default FloatingMenu;
