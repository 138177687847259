import { useEffect, useState } from "react";
import Header from "../../Components/Header";
import ProtectedRoute from "../../utils/ProtectedRoute";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../Components/Loader";
import CameraIcon from "../../Assets/camera.svg";
import HistoryIcon from "../../Assets/history-color.svg";
import ExerciseHistoryDrawer from "../../Components/ExerciseHitoryDrawer";
import VideoModal from "../../Components/VideoModal";
import SessionCompleted from "../../Assets/session-completed.gif";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import { FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";

const TrainingSessionDetailComponent = ({ role, user }) => {
	const [loader, setLoader] = useState(true);
	const [trainingSession, setTrainingSession] = useState(null);
	const [currentItem, setCurrentItem] = useState(null);
	const [historyItems, setHistoryItems] = useState([]);
	const [completeHistory, setCompleteHistory] = useState([]);
	const [youtubeUrl, setyoutubeUrl] = useState("");
	const [animation, setAnimation] = useState(false);
	const [debouncing, setDebouncing] = useState(false);
	const [debounceTimer, setDebounceTimer] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const params = useParams();
	const navigate = useNavigate();
	useEffect(() => {
		setLoader(true);
		// Call to get training Sessions
		// Check If training Session exist in the local storage
		let _trainingSession;
		if (localStorage.getItem("session_data")) {
			_trainingSession = JSON.parse(localStorage.getItem("session_data"));
			setTrainingSession(_trainingSession);
			setCurrentItem(
				_trainingSession?.session_data.items[
					_trainingSession?.session_data?.activeIndex || 0
				]
			);

			setLoader(false);
		} else {
			axios
				.post(
					process.env.REACT_APP_BACKEND_URL +
						`/api/training-sessions/getTrainingSession/`,
					{
						clientId: params.id,
						id: params.sessionId,
					}
				)
				.then((response) => {
					if (response.data?.session_data.items?.length) {
						if (
							!response.data.session_data.activeIndex &&
							response.data.session_data.activeIndex !== 0 &&
							response.data.text_note
						) {
							_trainingSession = {
								...response.data,
								session_data: {
									...response.data.session_data,
								},
							};
							setTrainingSession(_trainingSession);
						} else {
							let _currentItem =
								response.data?.session_data.items[
									response.data?.session_data?.activeIndex ||
										0
								];
							setCurrentItem(_currentItem);
							_trainingSession = response.data;
							setTrainingSession(_trainingSession);
						}
					}
					localStorage.setItem(
						"session_data",
						JSON.stringify(_trainingSession)
					);
					setLoader(false);
				})
				.catch((err) => {
					toast("Unable to get session data.", {
						type: "error",
					});
					setLoader(false);
				});
			toast("Training Session Started", {
				type: "success",
			});
		}
		setLoader(true);
		// Call to get history
		axios
			.get(
				process.env.REACT_APP_BACKEND_URL +
					`/api/client-history/${params.id}/getExerciseHistoryForClient`
			)
			.then((res) => {
				setHistoryItems(res.data);
				setLoader(false);
			})
			.catch((err) => {
				toast("Unable to fetch history", {
					type: "error",
				});
				setLoader(false);
			});

		// eslint-disable-next-line
	}, []);

	const handleDebouncedUpdate = (_sessionData) => {
		setDebouncing(true);
		const { session_data, status } = _sessionData;
		const requestData = {
			type: "exerciseUpdate",
			id: trainingSession.id,
			session_data: session_data,
			status,
		};
		if (requestData.status !== "completed") {
			let _trainingSession = {
				...trainingSession,
				session_data: session_data,
			};
			localStorage.setItem(
				"session_data",
				JSON.stringify(_trainingSession)
			);
			setTrainingSession(_trainingSession);
			setCurrentItem(session_data.items[session_data?.activeIndex || 0]);
		}
		axios
			.post(
				process.env.REACT_APP_BACKEND_URL +
					"/api/training-sessions/updateSessionDataForClient/",
				requestData
			)
			.then((response) => {
				if (requestData.status === "completed") {
					localStorage.removeItem("session_data");
					setAnimation(true);
					setTimeout(() => {
						navigate(`/member/${params.id}`);
					}, 3000);
				} else {
					setIsSubmitting(false);
					setTimeout(() => {
						setDebouncing(false);
					}, 1000);
				}
			})
			.catch((err) => {
				toast("Unable to update session.", {
					type: "error",
				});
			})
			.finally(() => {
				setLoader(false);
			});
	};

	const changeHandler = (val, index, mainIndex, type) => {
		clearTimeout(debounceTimer);
		let _sessionData = { ...trainingSession };
		if (type === "final") {
			_sessionData.session_data.items[mainIndex].exercises[index].result =
				val;
		} else if (type === "review") {
			_sessionData.session_data.review = val;
		} else {
			let _activeIndex = _sessionData.session_data.activeIndex || 0;
			if (currentItem.exercises.length > 1) {
				_sessionData.session_data.items[_activeIndex].exercises[
					index
				].result = val;
			} else {
				_sessionData.session_data.items[
					_activeIndex
				].exercises[0].result = val;
			}
		}
		setDebounceTimer(
			setTimeout(() => {
				handleDebouncedUpdate(_sessionData);
			}, 1200)
		);
	};

	const updateSession = (type, isSlider, activeDirection) => {
		if (type === "complete") {
			setIsSubmitting(true);
		}
		setDebouncing(true);
		let _sessionData = { ...trainingSession };
		let _currentScreen = _sessionData.session_data.activeIndex || 0;

		if (
			(_currentScreen === "notes" &&
				!_sessionData.session_data.startTime) ||
			(_currentScreen === 0 && !_sessionData.session_data.startTime)
		) {
			_sessionData.session_data.startTime = new Date();
		}
		switch (type) {
			case "notes":
				_sessionData.session_data.activeIndex = 0;
				break;
			case "back":
				if (isSlider) {
					_sessionData.session_data.activeIndex = activeDirection;
				} else {
					if (_currentScreen === "final-step") {
						_sessionData.session_data.activeIndex =
							_sessionData.session_data.items.length - 1;
					} else if (_currentScreen !== 0) {
						_sessionData.session_data.activeIndex =
							_currentScreen - 1;
					}
				}
				break;
			case "next":
				if (isSlider) {
					_sessionData.session_data.activeIndex = activeDirection;
				} else {
					if (
						_currentScreen ===
						_sessionData.session_data.items.length - 1
					) {
						_sessionData.session_data.activeIndex = "final-step";
					} else {
						_sessionData.session_data.activeIndex =
							_currentScreen + 1;
					}
				}
				break;
			case "review":
				_sessionData.session_data.activeIndex = "review";
				break;
			case "complete":
				_sessionData.status = "completed";
				_sessionData.session_data.completeTime = new Date();
				break;
			default:
				break;
		}
		setDebounceTimer(
			setTimeout(() => {
				handleDebouncedUpdate(_sessionData);
			}, 1200)
		);
	};

	const returnHistory = (itemId, type) => {
		let _history = [...historyItems];
		_history.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

		if (type === "single") {
			let _currentItem = _history.find(
				(item) => item?.exercise_database?.id === itemId
			);
			if (_currentItem) {
				return _currentItem;
			} else {
				return null;
			}
		} else {
			let _historyItem = _history.filter(
				(item) => item?.exercise_database?.id === itemId
			);
			setCompleteHistory(_historyItem);
		}
	};

	let activeIndex =
		(trainingSession && trainingSession?.session_data?.activeIndex) || 0;
	return (
		<div className="min-h-screen bg-no-repeat bg-cover bg-[#111111] pb-12 training-detail">
			<Header user={user} role={role} />
			<div className="md:hidden">
				<Link to="/">
					<p className="text-white flex items-center font-xs p-2">
						<FaHome /> <span className="pl-2">Home</span>
					</p>
				</Link>
			</div>
			{debouncing ? (
				<div className="loading-bar" />
			) : (
				<div className="h-[2px] bg-[#111111]" />
			)}

			{/* Then all the steps */}
			{!animation &&
			!loader &&
			trainingSession &&
			activeIndex !== "final-step" &&
			activeIndex !== "review" &&
			activeIndex !== "notes" ? (
				<Swiper
					onSlideChange={(direction) => {
						if (
							direction.previousSnapIndex ||
							direction.previousSnapIndex === 0
						) {
							if (
								direction.activeIndex < direction.previousIndex
							) {
								updateSession(
									"back",
									true,
									direction.activeIndex
								);
							} else {
								updateSession(
									"next",
									true,
									direction.activeIndex
								);
							}
						}
					}}
					autoHeight={true}
					slidesPerView={1}
					spaceBetween={30}
					keyboard={{
						enabled: true,
					}}
					pagination={{
						clickable: true,
					}}
					initialSlide={activeIndex}
					navigation={true}
					modules={[Pagination, Navigation]}
					className="mySwiper"
				>
					{trainingSession?.session_data?.items?.map(
						(currentItem, currrenIndex) => {
							return (
								<SwiperSlide key={currrenIndex}>
									<div className="container mx-auto lg:mt-12 w-11/12 sm:w-full pt-4 lg:pt-0 pb-12">
										{currentItem?.exercises.length > 1 ? (
											<div className="md:w-9/12 p-[2px] mt-12 rounded bg-[#1e1e1e] mx-auto gradient-bg">
												<div className="w-full bg-black p-4 md:p-8 rounded cursor-pointer">
													<h1 className="capitalize text-center text-white font-semibold text-xl md:text-3xl mb-8 border-b w-max mx-auto">
														{currentItem.name}
													</h1>
													{currentItem.exercises.map(
														(
															exerciseItem,
															exerciseIndex
														) => {
															return (
																<div
																	key={
																		exerciseIndex
																	}
																	className={`${
																		exerciseIndex !==
																			currentItem
																				.exercises
																				.length -
																				1 &&
																		"mb-8"
																	}`}
																>
																	<div className="flex items-center lg:justify-center">
																		<h1 className="lg:text-center text-white text-2xl font-Kenyan tracking-wider">
																			{exerciseItem
																				?.exercise
																				?.name ||
																				exerciseItem?.exercise}
																		</h1>{" "}
																		{exerciseItem
																			?.exercise
																			?.youtube_url && (
																			<button
																				onClick={() => {
																					setyoutubeUrl(
																						exerciseItem
																							?.exercise
																							?.youtube_url
																					);
																				}}
																			>
																				<img
																					src={
																						CameraIcon
																					}
																					alt="Video Link"
																					className="ml-2"
																				/>
																			</button>
																		)}
																	</div>
																	<div className="pt-4 flex lg:items-center justify-center flex-col">
																		<pre className="text-lg text-white lg:text-center md:w-10/12 text-wrap">
																			{
																				exerciseItem?.training_note
																			}
																		</pre>
																		{exerciseItem
																			?.exercise
																			?.id &&
																			exerciseItem
																				?.exercise
																				?.text_note && (
																				<div className="py-4 my-4 border-t border-b border-gray-500">
																					<h6 className="text-gray-500 font-semibold text-base">
																						Exercise
																						notes
																					</h6>
																					<p className="text-gray-500 text-xs pt-1">
																						{
																							exerciseItem
																								?.exercise
																								?.text_note
																						}
																					</p>
																				</div>
																			)}

																		{exerciseItem
																			?.exercise
																			?.id &&
																			returnHistory(
																				exerciseItem
																					?.exercise
																					?.id,
																				"single"
																			) && (
																				<div className="flex items-center mt-4">
																					<img
																						alt="Client Icon"
																						src={
																							HistoryIcon
																						}
																						className="w-4 h-4"
																					/>
																					<p className="text-white text-xs ml-2">
																						<button
																							className="underline"
																							onClick={() => {
																								returnHistory(
																									exerciseItem
																										?.exercise
																										?.id
																								);
																							}}
																						>
																							{" "}
																							Last:
																						</button>{" "}
																						&nbsp;
																						{
																							returnHistory(
																								exerciseItem
																									?.exercise
																									?.id,
																								"single"
																							)
																								.result
																						}
																					</p>
																				</div>
																			)}
																		<input
																			type="text"
																			value={
																				exerciseItem?.result ||
																				""
																			}
																			onChange={(
																				e
																			) =>
																				changeHandler(
																					e
																						.target
																						.value,
																					exerciseIndex
																				)
																			}
																			placeholder="Result..."
																			className="bg-black border border-white my-4 w-11/12 md:w-6/12 rounded p-2 text-white focus:outline-none"
																			maxLength={
																				120
																			}
																		/>
																	</div>
																</div>
															);
														}
													)}
												</div>
												<div className="flex justify-center flex-col items-center">
													<div className="flex">
														{activeIndex ===
															trainingSession
																?.session_data
																?.items
																?.length -
																1 && (
															<button
																onClick={() => {
																	updateSession(
																		"next"
																	);
																}}
																className="text-white mt-1 gradient-bg p-[2px] rounded mx-4"
															>
																<div className="px-4 py-2 bg-black rounded">
																	Complete
																</div>
															</button>
														)}
													</div>
												</div>
											</div>
										) : (
											<div className="md:w-9/12 p-[2px] mt-12 rounded bg-[#1e1e1e] mx-auto gradient-bg">
												<div className="w-full bg-black p-4 md:p-8 rounded cursor-pointer">
													<div className="flex items-center lg:justify-center">
														<h1 className="lg:text-center text-white text-2xl font-Kenyan tracking-wider ">
															{currentItem
																?.exercises[0]
																?.exercise
																?.name ||
																currentItem
																	?.exercises[0]
																	?.exercise}
														</h1>

														{currentItem
															?.exercises[0]
															?.exercise
															?.youtube_url && (
															<button
																onClick={() => {
																	setyoutubeUrl(
																		currentItem
																			?.exercises[0]
																			?.exercise
																			?.youtube_url
																	);
																}}
															>
																<img
																	src={
																		CameraIcon
																	}
																	alt="Video Link"
																	className="ml-2"
																/>
															</button>
														)}
													</div>
													<div className="pt-4 flex lg:items-center justify-center flex-col">
														<pre className="text-lg text-white lg:text-center md:w-10/12 text-wrap">
															{
																currentItem
																	?.exercises[0]
																	?.training_note
															}
														</pre>
														{currentItem
															?.exercises[0]
															?.exercise.id &&
															currentItem
																?.exercises[0]
																?.exercise
																?.text_note && (
																<div className="py-4 my-4 border-t border-b border-gray-500">
																	<h6 className="text-gray-500 font-semibold text-base">
																		Exercise
																		notes
																	</h6>
																	<p className="text-gray-500 text-xs pt-1">
																		{
																			currentItem
																				?.exercises[0]
																				?.exercise
																				?.text_note
																		}
																	</p>
																</div>
															)}
														{currentItem
															?.exercises[0]
															?.exercise.id &&
															returnHistory(
																currentItem
																	?.exercises[0]
																	?.exercise
																	.id,
																"single"
															) && (
																<div className="flex items-center mt-4">
																	<img
																		alt="Client Icon"
																		src={
																			HistoryIcon
																		}
																		className="w-4 h-4"
																	/>
																	<p className="text-white text-xs ml-2">
																		<button
																			className="underline"
																			onClick={() => {
																				returnHistory(
																					currentItem
																						?.exercises[0]
																						?.exercise
																						.id
																				);
																			}}
																		>
																			{" "}
																			Last:
																		</button>{" "}
																		&nbsp;
																		{
																			returnHistory(
																				currentItem
																					?.exercises[0]
																					?.exercise
																					.id,
																				"single"
																			)
																				.result
																		}
																	</p>
																</div>
															)}
														<input
															type="text"
															value={
																currentItem
																	?.exercises[0]
																	?.result ||
																""
															}
															onChange={(e) =>
																changeHandler(
																	e.target
																		.value
																)
															}
															placeholder="Result..."
															className="bg-black border border-white my-4 w-11/12 md:w-6/12 rounded p-2 text-white focus:outline-none"
															maxLength={120}
														/>
													</div>
												</div>
												<div className="flex justify-center">
													{activeIndex ===
														trainingSession
															?.session_data
															?.items?.length -
															1 && (
														<button
															onClick={() => {
																updateSession(
																	"next"
																);
															}}
															className="text-white mt-1 gradient-bg p-[2px] rounded mx-4"
														>
															<div className="px-4 py-2 bg-black rounded">
																Complete
															</div>
														</button>
													)}
												</div>
											</div>
										)}
									</div>
								</SwiperSlide>
							);
						}
					)}
				</Swiper>
			) : (
				<div />
			)}
			{/* Then final step */}

			{!animation &&
			!loader &&
			trainingSession &&
			activeIndex &&
			activeIndex === "final-step" ? (
				<div className="container mx-auto lg:mt-12 w-11/12 sm:w-full pt-4 lg:pt-0">
					<div className="md:w-9/12 p-[2px] mt-12 rounded bg-[#1e1e1e] mx-auto gradient-bg">
						<div className="w-full bg-black p-4 md:p-8 rounded cursor-pointer">
							<h1 className="capitalize text-center text-white font-semibold text-xl md:text-3xl mb-8 border-b w-max mx-auto">
								Review
							</h1>
							{trainingSession?.session_data?.items.map(
								(item, index) => {
									return (
										<div
											className="mt-4 mb-8 flex justify-center lg:items-center flex-col"
											key={index}
										>
											{item.exercises.length > 1 ? (
												<h1 className="text-center text-white text-xl border-b-2 w-max mx-auto font-Kenyan tracking-wider">
													{item.name}
												</h1>
											) : (
												<h1 className="text-white mt-4">
													{item?.exercises[0]
														?.exercise?.name ||
														item?.exercises[0]
															?.exercise}
												</h1>
											)}
											{item.exercises.length > 1 ? (
												<>
													{item.exercises.map(
														(
															exercise,
															exerciseIndex
														) => {
															return (
																<div
																	key={
																		exerciseIndex
																	}
																	className="w-full flex flex-col md:items-center"
																>
																	<p className="text-white mt-4">
																		{" "}
																		{exercise
																			?.exercise
																			?.name ||
																			exercise?.exercise}
																	</p>
																	<textarea
																		rows={3}
																		type="text"
																		value={
																			exercise?.result ||
																			""
																		}
																		onChange={(
																			e
																		) =>
																			changeHandler(
																				e
																					.target
																					.value,
																				exerciseIndex,
																				index,
																				"final"
																			)
																		}
																		placeholder="Result..."
																		className="bg-black border border-white mt-3 w-11/12 md:w-6/12 rounded p-2 text-white focus:outline-none"
																		maxLength={
																			120
																		}
																	/>
																</div>
															);
														}
													)}
												</>
											) : (
												<textarea
													rows={3}
													type="text"
													value={
														item.exercises[0]
															?.result || ""
													}
													onChange={(e) =>
														changeHandler(
															e.target.value,
															0,
															index,
															"final"
														)
													}
													placeholder="Result..."
													className="bg-black border border-white mt-3 w-11/12 md:w-6/12 rounded p-2 text-white focus:outline-none"
													maxLength={120}
												/>
											)}
										</div>
									);
								}
							)}
							<div className="flex justify-center">
								<button
									onClick={() => {
										updateSession("back");
									}}
									className="text-white mt-4 gradient-bg p-[2px] rounded mx-4"
								>
									<div className="px-4 py-2 bg-black">
										Prev
									</div>
								</button>
								<button
									onClick={() => {
										updateSession("review");
									}}
									className="text-white mt-4 gradient-bg p-[2px] rounded mx-4"
								>
									<div className="px-4 py-2 bg-black">
										Next
									</div>
								</button>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div />
			)}
			{/* Then review step */}
			{!animation &&
			!loader &&
			trainingSession &&
			activeIndex &&
			activeIndex === "review" ? (
				<div className="container mx-auto lg:mt-12 w-11/12 sm:w-full pt-4 lg:pt-0">
					<div className="md:w-9/12 p-[2px] mt-12 rounded bg-[#1e1e1e] mx-auto gradient-bg">
						<div className="w-full bg-black p-4 md:p-8 rounded cursor-pointer flex flex-col items-center">
							<h1 className="capitalize text-center text-white font-semibold text-xl md:text-3xl mb-8 border-b w-max mx-auto">
								Notes
							</h1>
							<textarea
								value={
									trainingSession?.session_data?.review || ""
								}
								onChange={(e) =>
									changeHandler(
										e.target.value,
										null,
										null,
										"review"
									)
								}
								placeholder="Jot down any comments regarding the session as a whole."
								className="bg-black border border-white w-11/12 md:w-6/12 rounded p-2 text-white focus:outline-none mx-auto"
								rows={4}
							/>
							<div className="flex justify-center">
								<button
									disabled={isSubmitting || debouncing}
									onClick={() => {
										updateSession("complete");
									}}
									className="text-white mt-4 gradient-bg p-[2px] rounded mx-4 relative min-w-[88px]"
								>
									{debouncing || isSubmitting ? (
										<div className="px-4 py-2 bg-black">
											<span className="animate-ping inline-flex h-3 w-3 rounded-full bg-[#ff4655]"></span>
										</div>
									) : (
										<div className="px-4 py-2 bg-black">
											Submit
										</div>
									)}
								</button>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div />
			)}
			{animation && (
				<div className="flex items-center justify-center min-h-screen">
					<div>
						<img
							src={SessionCompleted}
							alt="success"
							className="w-50"
						/>
					</div>
				</div>
			)}

			{youtubeUrl && (
				<VideoModal
					currentItem={youtubeUrl}
					setCurrentItem={setyoutubeUrl}
				/>
			)}

			{completeHistory?.length ? (
				<ExerciseHistoryDrawer
					open={completeHistory}
					setOpen={setCompleteHistory}
				/>
			) : (
				<div />
			)}

			<Loader loading={loader} />
		</div>
	);
};

const TrainingSessionDetail = () => {
	return (
		<ProtectedRoute>
			<TrainingSessionDetailComponent />
		</ProtectedRoute>
	);
};
export default TrainingSessionDetail;
