import Table from "../Table";
import DeleteIcon from "../../Assets/delete.svg";
import EditIcon from "../../Assets/edit.svg";
import AddIcon from "../../Assets/addIcon.svg";
import Weight from "../../Assets/weight.svg";
import WeightModal from "../WeightModal";
import IncreaseArrow from "../../Assets/increase.svg";
import DecreaseArrow from "../../Assets/decrease.svg";
import EqualSvg from "../../Assets/equal.svg";
import { formattedDate } from "../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
const WeightTable = ({
	tableData,
	setFetchLoader,
	currentItem,
	setCurrentItem,
	setTableData,
}) => {
	const params = useParams();

	const deleteEntry = async (id) => {
		if (window.confirm(`Are you sure to delete`)) {
			setFetchLoader(true);
			axios
				.post(
					process.env.REACT_APP_BACKEND_URL +
						`/api/weight/delete/${id}`,
					{
						clientId: params.id,
					}
				)
				.then((res) => {
					let _tableData = [...tableData];
					_tableData = _tableData.filter(
						(item) => item.id !== res.data.id
					);
					setTableData(_tableData);
					setFetchLoader(false);
					toast("Record deleted.", {
						type: "success",
					});
				})
				.catch((err) => {
					let _message = err.response.data.error.message;
					if (_message === "noBelonging") {
						toast("Record does not belong to you.", {
							type: "error",
						});
					} else {
						toast("Something went wrong while updating record.", {
							type: "error",
						});
					}
					setFetchLoader(false);
				});
		}
	};
	return (
		<div className="w-full px-8 relative pt-12">
			<div className="flex items-center flex-col absolute top-0 -mt-12">
				<div className="h-12 w-12 rounded bg-[#d3b065] shadow flex items-center justify-center">
					<img alt="Weight Icon" src={Weight} className="w-8 h-8" />
				</div>
				<p className="text-white text-lg font-bold mt-1">Weight</p>
			</div>
			<button
				onClick={() => {
					setCurrentItem(true);
				}}
				className="float-right"
				type="button"
			>
				<img alt="Add" src={AddIcon} className="w-8" />
			</button>

			<Table
				headerColor="#1e1e1e"
				pageSize={5}
				columns={[
					{
						id: "weight",
						header: () => "Weight",
						accessorFn: (row) => `${row.weight}`,
						width: 90,
						cell: (info) => {
							let prevValue =
								info.row.index !== tableData.length - 1 &&
								parseFloat(info.row.original.weight);
							let nextValue =
								info.row.index !== tableData.length - 1 &&
								parseFloat(
									tableData[info.row.index + 1].weight
								);
							let difference =
								prevValue &&
								nextValue &&
								(prevValue - nextValue).toFixed(1);
							return (
								<div className="w-[100px] relative">
									<div className="items-center absolute right-[-30px] -mt-2">
										{tableData.length > 1 &&
											info.row.index !==
												tableData.length - 1 && (
												<div>
													{prevValue > nextValue ? (
														<div className="flex items-end">
															<img
																alt="Increase"
																src={
																	IncreaseArrow
																}
																className="w-10"
															/>
															<span className="text-white text-xs min-w-[28px]">
																+{difference}
															</span>
														</div>
													) : prevValue ===
													  nextValue ? (
														<div className="flex items-end">
															<img
																alt="Equal"
																src={EqualSvg}
																className="w-8"
															/>
															<span className="text-white text-xs opacity-0 min-w-[28px]">
																+{difference}
															</span>
														</div>
													) : (
														<div className="flex items-end">
															<img
																alt="Decrease"
																src={
																	DecreaseArrow
																}
																className="w-10"
															/>
															<span className="text-white text-xs min-w-[28px]">
																{difference}
															</span>
														</div>
													)}
												</div>
											)}
									</div>
									<span className="whitespace-nowrap py-4 text-sm text-white text-right">
										{info.row.original.weight} LBS
									</span>
								</div>
							);
						},
					},
					{
						id: "date",
						header: () => "Date",
						width: 100,
						accessorFn: (row) => `${formattedDate(row.date, true)}`,
						cell: (info) => {
							return (
								<span className="whitespace-nowrap py-4 text-sm text-white text-right">
									{formattedDate(
										info.row.original.date,
										true
									)}
								</span>
							);
						},
					},
					{
						id: "actions",
						header: () => "Action",
						width: "50px",
						cell: (info) => {
							return (
								<div className="w-[100px]">
									<div className="flex">
										<div className="ml-4 cursor-pointer">
											<img
												src={EditIcon}
												alt="Edit Icon"
												onClick={() => {
													setCurrentItem({
														...info.row.original,
													});
												}}
											/>
										</div>
										<div
											className="ml-4 cursor-pointer"
											onClick={() => {
												deleteEntry(
													info.row.original.id
												);
											}}
										>
											<img
												src={DeleteIcon}
												alt="Delete Icon"
											/>
										</div>
									</div>
								</div>
							);
						},
					},
				]}
				data={tableData}
			/>
			{currentItem && (
				<WeightModal
					currentItem={currentItem}
					setCurrentItem={setCurrentItem}
					tableData={tableData}
					setTableData={setTableData}
				/>
			)}
		</div>
	);
};

export default WeightTable;
