import { useEffect, useState } from "react";
import Header from "../../Components/Header";
import ProtectedRoute from "../../utils/ProtectedRoute";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../Components/Loader";
import CameraIcon from "../../Assets/camera.svg";
import HistoryIcon from "../../Assets/history-color.svg";
import ExerciseHistoryDrawer from "../../Components/ExerciseHitoryDrawer";
import VideoModal from "../../Components/VideoModal";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";

const TrainingSessionPreviewComponent = ({ role, user }) => {
	const [loader, setLoader] = useState(true);
	const [trainingSession, setTrainingSession] = useState(null);
	const [historyItems, setHistoryItems] = useState([]);
	const [completeHistory, setCompleteHistory] = useState([]);
	const [youtubeUrl, setyoutubeUrl] = useState("");
	const params = useParams();
	const router = useLocation();
	const navigate = useNavigate();
	useEffect(() => {
		setLoader(true);
		let _trainingSession;
		axios
			.post(
				process.env.REACT_APP_BACKEND_URL +
					`/api/training-sessions/getTrainingSession/`,
				{
					clientId: params.id,
					id: params.sessionId,
				}
			)
			.then((response) => {
				if (response.data?.session_data.items?.length) {
					if (
						!response.data.session_data.activeIndex &&
						response.data.session_data.activeIndex !== 0 &&
						response.data.text_note
					) {
						_trainingSession = {
							...response.data,
							session_data: {
								...response.data.session_data,
							},
						};
						setTrainingSession(_trainingSession);
					} else {
						_trainingSession = response.data;
						setTrainingSession(_trainingSession);
					}
				}

				setLoader(false);
			})
			.catch((err) => {
				toast("Unable to get session data.", {
					type: "error",
				});
				setLoader(false);
			});

		setLoader(true);
		// Call to get history
		axios
			.get(
				process.env.REACT_APP_BACKEND_URL +
					`/api/client-history/${params.id}/getExerciseHistoryForClient`
			)
			.then((res) => {
				setHistoryItems(res.data);
				setLoader(false);
			})
			.catch((err) => {
				toast("Unable to fetch history", {
					type: "error",
				});
				setLoader(false);
			});
		// eslint-disable-next-line
	}, []);

	const handleDebouncedUpdate = (_sessionData) => {
		const { session_data, status } = _sessionData;
		const requestData = {
			type: "exerciseUpdate",
			id: trainingSession.id,
			session_data: session_data,
			status,
		};
		if (requestData.status !== "completed") {
			let _trainingSession = {
				...trainingSession,
				session_data: session_data,
			};
			setTrainingSession(_trainingSession);
		}
	};
	const updateSession = (type, isSlider, activeDirection) => {
		let _sessionData = { ...trainingSession };
		let _currentScreen = _sessionData.session_data.activeIndex || 0;

		if (
			(_currentScreen === "notes" &&
				!_sessionData.session_data.startTime) ||
			(_currentScreen === 0 && !_sessionData.session_data.startTime)
		) {
			_sessionData.session_data.startTime = new Date();
		}
		switch (type) {
			case "notes":
				_sessionData.session_data.activeIndex = 0;
				break;
			case "back":
				if (isSlider) {
					_sessionData.session_data.activeIndex = activeDirection;
				} else {
					if (_currentScreen === "final-step") {
						_sessionData.session_data.activeIndex =
							_sessionData.session_data.items.length - 1;
					} else if (_currentScreen !== 0) {
						_sessionData.session_data.activeIndex =
							_currentScreen - 1;
					}
				}
				break;
			case "next":
				if (isSlider) {
					_sessionData.session_data.activeIndex = activeDirection;
				} else {
					if (
						_currentScreen ===
						_sessionData.session_data.items.length - 1
					) {
						_sessionData.session_data.activeIndex = "final-step";
					} else {
						_sessionData.session_data.activeIndex =
							_currentScreen + 1;
					}
				}
				break;
			case "review":
				_sessionData.session_data.activeIndex = "review";
				break;
			case "complete":
				_sessionData.status = "completed";
				_sessionData.session_data.completeTime = new Date();
				break;
			default:
				break;
		}
		handleDebouncedUpdate(_sessionData);
	};

	const returnHistory = (itemId, type) => {
		let _history = [...historyItems];
		_history.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
		if (type === "single") {
			let _currentItem = _history.find(
				(item) => item?.exercise_database?.id === itemId
			);
			if (_currentItem) {
				return _currentItem;
			} else {
				return null;
			}
		} else {
			let _historyItem = _history.filter(
				(item) => item?.exercise_database?.id === itemId
			);
			setCompleteHistory(_historyItem);
		}
	};
	const openTrainingSession = () => {
		localStorage.removeItem("session_data");
		navigate(`/member/${params.id}/sessions/${trainingSession.id}`);
	};
	const viewAnother = () => {
		navigate(
			`/member/${params.id}/sessions?name=${
				router?.search?.split("name=")[1]
			}`
		);
	};

	let activeIndex =
		(trainingSession && trainingSession?.session_data?.activeIndex) || 0;
	return (
		<div className="min-h-screen bg-no-repeat bg-cover bg-[#111111] pb-12 training-detail">
			<Header user={user} role={role} />

			{/* Then all the steps */}
			{!loader && trainingSession && activeIndex !== "notes" ? (
				<Swiper
					onSlideChange={(direction) => {
						if (
							direction.previousSnapIndex ||
							direction.previousSnapIndex === 0
						) {
							if (
								direction.activeIndex < direction.previousIndex
							) {
								updateSession(
									"back",
									true,
									direction.activeIndex
								);
							} else {
								updateSession(
									"next",
									true,
									direction.activeIndex
								);
							}
						}
					}}
					autoHeight={true}
					slidesPerView={1}
					spaceBetween={30}
					keyboard={{
						enabled: true,
					}}
					pagination={{
						clickable: true,
					}}
					initialSlide={activeIndex}
					navigation={true}
					modules={[Pagination, Navigation]}
					className="mySwiper"
				>
					{trainingSession?.session_data?.items?.map(
						(currentItem, currrenIndex) => {
							return (
								<SwiperSlide key={currrenIndex}>
									<div className="container mx-auto lg:mt-12 w-11/12 sm:w-full pt-4 lg:pt-0 pb-12">
										{currentItem?.exercises.length > 1 ? (
											<div className="md:w-9/12 p-[2px] mt-12 rounded bg-[#1e1e1e] mx-auto gradient-bg">
												<div className="w-full bg-black p-4 md:p-8 rounded cursor-pointer">
													<h1 className="capitalize text-center text-white font-semibold text-xl md:text-3xl mb-8 border-b w-max mx-auto">
														{currentItem.name}
													</h1>
													{currentItem.exercises.map(
														(
															exerciseItem,
															exerciseIndex
														) => {
															return (
																<div
																	key={
																		exerciseIndex
																	}
																	className={`${
																		exerciseIndex !==
																			currentItem
																				.exercises
																				.length -
																				1 &&
																		"mb-8"
																	}`}
																>
																	<div className="flex items-center lg:justify-center">
																		<h1 className="lg:text-center text-white text-2xl font-Kenyan tracking-wider">
																			{exerciseItem
																				?.exercise
																				?.name ||
																				exerciseItem?.exercise}
																		</h1>{" "}
																		{exerciseItem
																			?.exercise
																			?.youtube_url && (
																			<button
																				onClick={() => {
																					setyoutubeUrl(
																						exerciseItem
																							?.exercise
																							?.youtube_url
																					);
																				}}
																			>
																				<img
																					src={
																						CameraIcon
																					}
																					alt="Video Link"
																					className="ml-2"
																				/>
																			</button>
																		)}
																	</div>
																	<div className="pt-4 flex lg:items-center justify-center flex-col">
																		<pre className="text-lg text-white lg:text-center md:w-10/12 text-wrap">
																			{
																				exerciseItem?.training_note
																			}
																		</pre>
																		{exerciseItem
																			?.exercise
																			?.id &&
																			exerciseItem
																				?.exercise
																				?.text_note && (
																				<div className="py-4 my-4 border-t border-b border-gray-500">
																					<h6 className="text-gray-500 font-semibold text-base">
																						Exercise
																						notes
																					</h6>
																					<p className="text-gray-500 text-xs pt-1">
																						{
																							exerciseItem
																								?.exercise
																								?.text_note
																						}
																					</p>
																				</div>
																			)}

																		{exerciseItem
																			?.exercise
																			?.id &&
																			returnHistory(
																				exerciseItem
																					?.exercise
																					?.id,
																				"single"
																			) && (
																				<div className="flex items-center mt-4">
																					<img
																						alt="Client Icon"
																						src={
																							HistoryIcon
																						}
																						className="w-4 h-4"
																					/>
																					<p className="text-white text-xs ml-2">
																						<button
																							className="underline"
																							onClick={() => {
																								returnHistory(
																									exerciseItem
																										?.exercise
																										?.id
																								);
																							}}
																						>
																							{" "}
																							Last:
																						</button>{" "}
																						&nbsp;
																						{
																							returnHistory(
																								exerciseItem
																									?.exercise
																									?.id,
																								"single"
																							)
																								.result
																						}
																					</p>
																				</div>
																			)}
																	</div>
																</div>
															);
														}
													)}
												</div>
												<div className="flex justify-center flex-col items-center">
													<div className="flex">
														<button
															onClick={
																viewAnother
															}
															className="text-white mt-1 gradient-bg p-[2px] rounded mx-4"
														>
															<div className="px-4 py-2 bg-black rounded">
																View Another
															</div>
														</button>
														<button
															onClick={
																openTrainingSession
															}
															className="text-white mt-1 gradient-bg p-[2px] rounded mx-4"
														>
															<div className="px-4 py-2 bg-black rounded">
																Start Session
															</div>
														</button>
													</div>
												</div>
											</div>
										) : (
											<div className="md:w-9/12 p-[2px] mt-12 rounded bg-[#1e1e1e] mx-auto gradient-bg">
												<div className="w-full bg-black p-4 md:p-8 rounded cursor-pointer">
													<div className="flex items-center lg:justify-center">
														<h1 className="lg:text-center text-white text-2xl font-Kenyan tracking-wider ">
															{currentItem
																?.exercises[0]
																?.exercise
																?.name ||
																currentItem
																	?.exercises[0]
																	?.exercise}
														</h1>

														{currentItem
															?.exercises[0]
															?.exercise
															?.youtube_url && (
															<button
																onClick={() => {
																	setyoutubeUrl(
																		currentItem
																			?.exercises[0]
																			?.exercise
																			?.youtube_url
																	);
																}}
															>
																<img
																	src={
																		CameraIcon
																	}
																	alt="Video Link"
																	className="ml-2"
																/>
															</button>
														)}
													</div>
													<div className="pt-4 flex lg:items-center justify-center flex-col">
														<pre className="text-lg text-white lg:text-center md:w-10/12 text-wrap">
															{
																currentItem
																	?.exercises[0]
																	?.training_note
															}
														</pre>
														{currentItem
															?.exercises[0]
															?.exercise.id &&
															currentItem
																?.exercises[0]
																?.exercise
																?.text_note && (
																<div className="py-4 my-4 border-t border-b border-gray-500">
																	<h6 className="text-gray-500 font-semibold text-base">
																		Exercise
																		notes
																	</h6>
																	<p className="text-gray-500 text-xs pt-1">
																		{
																			currentItem
																				?.exercises[0]
																				?.exercise
																				?.text_note
																		}
																	</p>
																</div>
															)}
														{currentItem
															?.exercises[0]
															?.exercise.id &&
															returnHistory(
																currentItem
																	?.exercises[0]
																	?.exercise
																	.id,
																"single"
															) && (
																<div className="flex items-center mt-4">
																	<img
																		alt="Client Icon"
																		src={
																			HistoryIcon
																		}
																		className="w-4 h-4"
																	/>
																	<p className="text-white text-xs ml-2">
																		<button
																			className="underline"
																			onClick={() => {
																				returnHistory(
																					currentItem
																						?.exercises[0]
																						?.exercise
																						.id
																				);
																			}}
																		>
																			{" "}
																			Last:
																		</button>{" "}
																		&nbsp;
																		{
																			returnHistory(
																				currentItem
																					?.exercises[0]
																					?.exercise
																					.id,
																				"single"
																			)
																				.result
																		}
																	</p>
																</div>
															)}
													</div>
												</div>
												<div className="flex justify-center">
													<button
														onClick={viewAnother}
														className="text-white mt-1 gradient-bg p-[2px] rounded mx-4"
													>
														<div className="px-4 py-2 bg-black rounded">
															View Another
														</div>
													</button>
													<button
														onClick={
															openTrainingSession
														}
														className="text-white mt-1 gradient-bg p-[2px] rounded mx-4"
													>
														<div className="px-4 py-2 bg-black rounded">
															Start Session
														</div>
													</button>
												</div>
											</div>
										)}
									</div>
								</SwiperSlide>
							);
						}
					)}
				</Swiper>
			) : (
				<div />
			)}

			{youtubeUrl && (
				<VideoModal
					currentItem={youtubeUrl}
					setCurrentItem={setyoutubeUrl}
				/>
			)}

			{completeHistory?.length ? (
				<ExerciseHistoryDrawer
					open={completeHistory}
					setOpen={setCompleteHistory}
				/>
			) : (
				<div />
			)}

			<Loader loading={loader} />
		</div>
	);
};

const TrainingSessionPreview = () => {
	return (
		<ProtectedRoute>
			<TrainingSessionPreviewComponent />
		</ProtectedRoute>
	);
};
export default TrainingSessionPreview;
