import { createSlice } from "@reduxjs/toolkit";

const coachNotifications = createSlice({
  name: "coachNotification",
  initialState: [],
  reducers: {
    updateCoachNofitications: (state, action) => {
      return (state = action.payload);
    },
  },
});

export const { updateCoachNofitications } = coachNotifications.actions;
export default coachNotifications.reducer;
