import Close from "../../Assets/close.svg";
import { useEffect, useState, useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import FullEditor from "ckeditor5-build-full";
import { toast } from "react-toastify";
import axios from "axios";
import Loader from "../Loader";
const ReviewDrawer = ({ open, setOpen, clientId }) => {
	const [openDrawer, setOpenDrawer] = useState(false);
	const [notes, setNotes] = useState("");
	const [loading, setLoading] = useState(false);
	const wrapperRef = useRef(null);

	useEffect(() => {
		if (open) {
			setTimeout(() => {
				setOpenDrawer(true);
				setLoading(true);
				axios
					.get(
						process.env.REACT_APP_BACKEND_URL +
							"/api/client/getClientNotes/" +
							clientId
					)
					.then((response) => {
						setNotes(response.data.notes);
						setLoading(false);
					});
			}, 200);
		}
	}, [open]);

	const closeDrawer = () => {
		setOpenDrawer(false);
		setTimeout(() => {
			setOpen(false);
		}, 200);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				wrapperRef.current &&
				!wrapperRef.current.contains(event.target) &&
				openDrawer
			) {
				closeDrawer();
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [wrapperRef, openDrawer]);

	const updateNotes = (e) => {
		e.preventDefault();
		setLoading(true);
		axios
			.put(
				process.env.REACT_APP_BACKEND_URL + `/api/clients/${clientId}`,
				{
					notes,
				}
			)
			.then((response) => {
				toast("Client Updated.", {
					type: "success",
				});
				setOpen(false);
			})
			.catch((err) => {
				toast("Unable to update client.", {
					type: "error",
				});
				setLoading(false);
			});
	};

	return (
		<div className={`fixed inset-0 z-20 bg-black bg-opacity-50`}>
			<div
				ref={wrapperRef}
				className={`w-full bg-[#1e1e1e] h-screen overflow-y-scroll right-0 absolute shadow px-4 py-12 ease-in-out transition ${
					openDrawer ? "" : "translate-x-full"
				} `}
			>
				<button
					onClick={() => {
						closeDrawer();
					}}
				>
					<img
						src={Close}
						alt="Close"
						className="w-8 absolute right-[10px] top-[20px]"
					/>
				</button>

				<h1 className="text-center text-white font-Medium text-2xl mb-4">
					Client Notes
				</h1>
				<form onSubmit={updateNotes} className="w-11/12 mx-auto">
					<CKEditor
						className="h-full"
						editor={FullEditor}
						data={notes || ""}
						onChange={(event, editor) => {
							setNotes(editor.getData());
						}}
					/>
					<div className="w-full flex justify-center mt-4">
						<button
							type="submit"
							className="inline-block px-7 py-3 bg-button text-white font-medium text-sm leading-snug rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out mx-auto"
							data-mdb-ripple="true"
							data-mdb-ripple-color="light"
						>
							Submit
						</button>
					</div>
				</form>
			</div>
			<Loader loading={loading} />
		</div>
	);
};
export default ReviewDrawer;
